import { useCallback, useEffect, useState } from 'react';
import { ArtistFormContainer, ComponentTitle, UniqueCodeBox } from '../exports/band.registration.styles'
import { Button, Checkbox, Divider, Typography } from '@mui/material';
import { useStateContext } from '../../context/StateContextAPI';
import TermsOfUseRokk from '../exports/terms.register.exports';
import { SuccessIcon } from '../../assets';

const ArtistRegisterStep5 = ({ activeStep, setActiveStep }: { activeStep: number, setActiveStep: (step: number) => void }) => {

    return (
        <ArtistFormContainer>

            <div className="greetings-message">
                <div>
                    <img src={SuccessIcon} alt="success-btn" />
                </div>
                <div className="head-text artist-page-head-text">
                    <div>
                        <span className="head-txt-off-white">THANK YOU</span>
                    </div>
                </div>
                <div style={{ color: 'var(--light-gray)', fontSize: '14px' }}>
                    Thank you for registering at ROKK! A link with your login details and your personal affiliate link will be sent to you by email shortly before the official launch of ROKK.
                </div>

                <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', margin: '20px 0' }} />


                <div className="btn-container">

                    <Button
                        variant="contained"
                        style={{
                            background: '#ff830320',
                            color: '#ff8303',
                            borderRadius: '30px',
                            height: '50px',
                            fontSize: '15px',
                            fontWeight: 600,
                            flex: 1
                        }}
                        onClick={() => setActiveStep(0)}
                    >
                        GO TO LOGIN
                    </Button>
                </div>
            </div>

        </ArtistFormContainer>
    )
}

export default ArtistRegisterStep5