import React, { useState } from 'react'
import { ArtistFormContainer, ArtistImage, ComponentTitle } from '../exports/band.registration.styles'
import { useStateContext } from '../../context/StateContextAPI';
import { Button, IconButton, CircularProgress, Typography } from '@mui/material';
import { MemberRokkArtist } from '../../assets';
import { EastSharp, Info, InfoOutlined } from '@mui/icons-material';

const ArtistRegisterStep3 = ({ activeStep, setActiveStep }: { activeStep: number, setActiveStep: (step: number) => void }) => {
    // State Context
    const { bandEmail, setBandEmail, memberArtistData, bandPassword, setBandPassword, isArtistFound, setIsArtistFound, artistInstance, setArtistInstance } = useStateContext();
    // Local State Management
    const [bandEmailTemp, setBandEmailTemp] = useState<string>("");
    const [isEmailParsed, setIsEmailParsed] = useState<boolean>(false);
    const [isArtistAuthenticated, setIsArtistAuthenticated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [notValidPassword, setNotValidPassword] = useState<boolean | undefined>(undefined);

    //  Handlers: Functions to handle the events:

    // handle password change
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setBandPassword(event.target.value);
    }
    // handle email change
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setBandEmailTemp((prev: string) => {
            return event.target.value;
        });
    }
    // handle email auth, checks for valid email string
    const handleEmailAuth = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        // Email validation regex
        const emailValidationRegex = /\S+@\S+\.\S+/;
        if (emailValidationRegex.test(bandEmailTemp)) {
            setInvalidEmail(false);
            setBandEmail(bandEmailTemp);
            // Valid Email Parsing.
            setIsEmailParsed(true);
        } else {
            setInvalidEmail(true);
        }
    }

    // to validate password string using the pattern
    const validatePassword = (password: string) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    }

    // handle password auth
    const handlePasswordAuth = async (e: any) => {
        setLoading(true);
        setArtistInstance(undefined)
        if (validatePassword(bandPassword)) {
            const options = {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded' // Adjusted this line
                },
                body: `requestID=${encodeURIComponent(isArtistFound.id)}&pass=${encodeURIComponent(bandPassword)}` // Formatted the body for x-www-form-urlencoded
            }
            const finalAuth = await fetch("http://localhost:5009/auth", options);
            if ((finalAuth.status !== 401)) {
                const res = await finalAuth.json();
                // console.log(res)
                setArtistInstance(res[0])
                setLoading(false)
                setIsArtistAuthenticated(true)
                setNotValidPassword(false)
                setIsArtistFound(undefined)
            } else {
                setLoading(false);
                setArtistInstance(undefined);
                setIsArtistAuthenticated(false);
                setNotValidPassword(true);
            }
        } else {
            setLoading(false);
            setNotValidPassword(true)
        }
    }

    const handlePrevStep = () => {
        setIsArtistFound(undefined);
        setArtistInstance(undefined);

        // setBandEmail("");
        setTimeout(() => {
            setActiveStep(activeStep - 1)
        }, 0)
    }

    const handleNextStep = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (isArtistAuthenticated || (validatePassword(bandPassword) && validatePassword(confirmPassword) && (bandPassword === confirmPassword))) {
            // if (bandPassword === confirmPassword) setArtistInstance(undefined)
            setTimeout(() => {
                setActiveStep(activeStep + 1);
            }, 0)
        } else if (artistInstance) {
            setActiveStep(activeStep + 1);
        } else {
            alert('Please enter a valid password.\nPassword should contain:\n1. Capital Letters\n2. Small Letters\n3. A numeric value\n4. Special Character [@$!%*?&]\n5. Should be greater than 7 digits');
        }
    }

    // function to decide, New/Existing password form
    const NewPasswordSection = () => {

        if (isArtistFound && isArtistFound.isFound === 0) {
            return (
                <>
                    <label htmlFor="NewPassword" className="label_items">New Password*</label>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <input type="password" id="NewPassword" style={{ flex: 1 }} className="inp inp-artist-name" placeholder='New Password' onChange={(e) => handlePasswordChange(e)} required />
                    </div>
                    <label htmlFor="ConfirmPassword" className="label_items">Confirm Password*</label>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <input type="password" id="ConfirmPassword" style={{ flex: 1 }} className="inp inp-artist-name" placeholder='Confirm Password' onChange={(e) => setConfirmPassword(e.target.value)} required />
                    </div>
                </>
            )
        } else if (isArtistFound && isArtistFound.isFound === 1) {
            return (
                <>
                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Welcome Back <span style={{ color: 'var(--primary)' }}>{memberArtistData.party_name}!</span></Typography>
                    <label htmlFor="password" className="label_items">Password*</label>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <input type="password" id="password" style={{ flex: 1 }} className="inp inp-artist-name" placeholder='Password' onChange={(e) => handlePasswordChange(e)} disabled={loading} required />
                        <IconButton aria-label="forward"
                            sx={{
                                color: '#ff8303',
                                width: "50px",
                                background: '#ff830310',

                                '&:hover': {
                                    background: '#ff830330'
                                }
                            }}

                            onClick={(e) => handlePasswordAuth(e)}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={20} /> : <EastSharp />}
                        </IconButton>
                    </div>
                    <Typography sx={{ color: 'var(--primary)', textAlign: 'right', marginTop: '10px' }}>
                        Forgot Password?
                    </Typography>
                </>
            )
        }
    }


    // UseEffects:

    // Call the Email Auth API
    React.useEffect(() => {
        const CallAuthAPI = async () => {
            // Start loading animation
            setLoading(true);
            // API call for Auth of Artist/Party 
            const options = {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded' // Adjusted this line
                },
                body: `email=${encodeURIComponent(bandEmail)}` // Formatted the body for x-www-form-urlencoded
            }
            const auth = await fetch(`http://localhost:5009/auth/findUser`, options);
            if (auth.status !== 204) {
                const res = await auth.json();
                setIsArtistFound(res[0])
            }
            else {
                setIsArtistFound(() => (
                    {
                        id: -1,
                        isFound: 0,
                        activated: -1
                    }
                ))
            }
            setLoading(false)
        }
        if (bandEmail.length > 3) {
            CallAuthAPI();
        }
    }, [isEmailParsed])

    // If the window gets refreshed
    React.useEffect(() => {
        if (memberArtistData && memberArtistData.party_id === -1) {
            setActiveStep(0);
        }
    }, [])

    return (
        <ArtistFormContainer>
            <ComponentTitle>
                LOGIN / REGISTER
            </ComponentTitle>

            <ArtistImage src={memberArtistData.artist_link} onError={(e: any) => (e.target as HTMLImageElement).src = MemberRokkArtist} />

            {!isEmailParsed &&
                <>
                    <label htmlFor="band_email" className="label_items">Enter Email*</label>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <input type="email" id="band_email" style={{ flex: 1 }} className="inp inp-artist-name" placeholder='Enter your email*' onChange={(e) => handleEmailChange(e)} disabled={loading} required />
                        <IconButton aria-label="forward"
                            sx={{
                                color: '#ff8303',
                                width: "50px",
                                background: '#ff830310',

                                '&:hover': {
                                    background: '#ff830330'
                                }
                            }}

                            onClick={(e) => handleEmailAuth(e)}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={20} /> : <EastSharp />}
                        </IconButton>
                    </div>
                </>
            }

            {invalidEmail && <Typography sx={{ color: "red", display: 'flex', alignItems: 'center' }}><InfoOutlined /> Invalid Email</Typography>}
            {NewPasswordSection()}
            {notValidPassword && <Typography sx={{ color: "red", display: 'flex', alignItems: 'center' }}><InfoOutlined /> Wrong Password</Typography>}

            {isArtistAuthenticated ?
                <Typography sx={{ background: '#ff830310', padding: '10px', borderRadius: '5px', color: '#ff8303' }}>
                    You are authenticated as {bandEmail}
                </Typography>
                : null
            }

            <div className="btn-container">
                <Button
                    style={{
                        color: 'var(--light-gray)',
                        borderRadius: '30px',
                        height: '50px',
                        fontSize: '15px',
                        fontWeight: 600,
                        marginTop: '20px',
                        border: '2px solid var(--light-gray)'
                    }}
                    onClick={handlePrevStep}
                >
                    BACK
                </Button>
                {(isArtistAuthenticated || (isArtistFound && isArtistFound.isFound === 0)) &&
                    <Button
                        variant="contained"
                        type="submit"
                        style={{
                            background: '#ff8303',
                            color: '#000',
                            borderRadius: '30px',
                            fontSize: '15px',
                            fontWeight: 600,
                            marginTop: '20px',
                            flex: 1
                        }}
                        onClick={(e) => handleNextStep(e)}
                    >
                        Continue
                    </Button>
                }

            </div>
        </ArtistFormContainer>
    )
}

export default ArtistRegisterStep3