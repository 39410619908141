import React, { useState } from 'react'
import { ArtistFormContainer, ComponentTitle, Image } from '../exports/band.registration.styles'
import { MemberArtistData, useStateContext } from '../../context/StateContextAPI';
import _ from 'lodash';
import { Badge, Box, Button, Divider, Typography } from '@mui/material';
import { BandArtistRokk, MemberRokkArtist } from '../../assets';
import { Close, KeyboardArrowRight } from '@mui/icons-material';
import { OthersSelector } from '../exports/artist-registration-exports';

const ArtistRegisterStep2 = ({ activeStep, setActiveStep }: { activeStep: number, setActiveStep: (step: number) => void }) => {

    const { artistData, memberArtistData, setMemberArtistData } = useStateContext();
    const [memberArtistDataFromAPI, setMemberArtistDataFromAPI] = useState<MemberArtistData[]>()

    // If the Member Artist couldn't be found..
    const [partyName, setPartyName] = useState<string>("")
    const [partyPosition, setPartyPosition] = useState<string>("")

    // Handle Important Events
    const handleNextStep = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if ((artistData && memberArtistData) || (partyName.length >= 3 && partyPosition.length >= 3)) {
            setActiveStep(activeStep + 1)
        }
    }

    const resetArtist = () => {
        setMemberArtistData(
            {
                party_id: -1,
                party_name: "",
                instrument: "",
                artist_link: ""
            }
        );
    }

    const handlePartySelect = (artist: MemberArtistData) => {
        setMemberArtistData(artist);
    }

    // If the Member Artist is set by the User onClick, then we get the Artist Selected Box to show
    const getPartyDetails = React.useCallback(() => {
        if (memberArtistData && memberArtistData.party_id !== -1) {
            return (

                <Badge badgeContent={<Close sx={{ fontSize: 'small' }} />}
                    color='primary'
                    sx={{
                        width: '100%',
                        '& .MuiBadge-standard': {
                            background: '#ff8303',
                            padding: '2px 0'
                        }
                    }}
                    onClick={resetArtist}
                >
                    <Box
                        width={'100%'}
                        style={{
                            background: 'rgba(255, 255, 255, 0.1)',
                            borderRadius: '10px',
                            padding: '10px',
                            display: 'flex',
                            color: 'var(--white)',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flex: 1
                        }}
                    >
                        <div className="artist-data-main-section">
                            <Image src={memberArtistData?.artist_link} onError={(e: any) => (e.target as HTMLImageElement).src = MemberRokkArtist} />
                            <div>
                                <Typography sx={{
                                    fontWeight: 500
                                }}>
                                    {memberArtistData?.party_name}
                                </Typography>
                                <Typography sx={{
                                    color: 'var(--offWhite)'
                                }}>
                                    {memberArtistData?.instrument}
                                </Typography>
                            </div>

                        </div>

                        <KeyboardArrowRight sx={{ color: 'var(--light-gray)' }} />

                    </Box>
                </Badge>
            )
        } else {
            return (null)
        }
    }, [handlePartySelect, setMemberArtistData, resetArtist])

    // Render all the Party Members' data pulled by using artist_id param(s).
    const ArtistSuggestions = () => {
        return (
            <>
                {
                    memberArtistDataFromAPI && memberArtistDataFromAPI.length > 0 ?
                        memberArtistDataFromAPI.map((artist: MemberArtistData, _idx: number) => {
                            return (
                                <Box
                                    key={_idx}
                                    style={{
                                        background: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handlePartySelect(artist)}
                                >
                                    <div className="artist-data-main-section">
                                        <Image src={artist.artist_link} onError={(e: any) => (e.target as HTMLImageElement).src = MemberRokkArtist} />
                                        <div>
                                            <Typography sx={{
                                                fontWeight: 500
                                            }}>
                                                {artist.party_name}
                                            </Typography>
                                            <Typography sx={{
                                                color: 'var(--offWhite)'
                                            }}>
                                                {artist?.instrument}
                                            </Typography>
                                        </div>

                                    </div>

                                    <KeyboardArrowRight sx={{ color: 'var(--light-gray)' }} />

                                </Box>
                            )
                        })
                        :
                        "No record(s) found"
                }
            </>
        )
    }

    // On load or After a NewArtist is Selected by the User, Call the API with new artist_id to pull all the member artist associated to that band(artistData.artist_id).
    React.useEffect(() => {
        const fetchMemberArtist = async () => {
            const options = {
                method: 'GET',
                headers: {
                    accept: 'application/json'
                }
            }
            try {
                const artistID = artistData.artist_id;
                // console.log(artistID)
                const data = await fetch(`http://localhost:5009/artist/member?artistID=${artistID}`, options);
                const res = await data.json();
                if (res?.length > 0) {
                    setMemberArtistDataFromAPI(() => res);
                } else {
                    setMemberArtistDataFromAPI(undefined)
                }
            } catch (err) {
                console.log('%cNo data found', 'background: #ffffff; padding: 2px 5px; border-radius: 2px; color: black; font-weight: bold;')
            }
        }
        fetchMemberArtist();
    }, [artistData])

    return (
        // Return
        <ArtistFormContainer>
            <ComponentTitle>
                BAND DETAILS
            </ComponentTitle>
            <Box
                style={{
                    background: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: '10px',
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}
            >
                <div className="artist-data-main-section">
                    <Image src={artistData?.photo_link} onError={(e: any) => (e.target as HTMLImageElement).src = BandArtistRokk} />
                    <div>
                        <Typography sx={{
                            fontWeight: 500
                        }}>
                            {artistData?.artist_name}
                        </Typography>
                        <Typography sx={{
                            color: 'var(--offWhite)',
                            '@media screen and (max-width: 600px)': {
                                fontSize: '13px'
                            }
                        }}>
                            {`${artistData?.artist_genres?.slice(0,)}, ${artistData?.country_short_name === 'XX' ? 'International' : artistData?.country_short_name}, ${artistData?.formed_in}`}

                        </Typography>
                    </div>

                </div>

            </Box>

            <ComponentTitle style={{ marginTop: '20px' }}>
                SELECT YOURSELF
            </ComponentTitle>
            {memberArtistData && memberArtistData.party_id !== -1 &&
                <>
                    <Box
                        sx={{
                            background: 'rgba(255, 255, 255, 0.1)',
                            padding: '10px',
                            borderRadius: '10px'
                        }}
                    >
                        <Typography sx={{ fontSize: '12px', fontWeight: 600, marginBottom: '10px', color: '#ff8303' }}>Selected Member</Typography>
                        {getPartyDetails()}

                    </Box>
                </>
            }

            <ArtistSuggestions />

            <Divider style={{ backgroundColor: 'var(--light-gray)', margin: '20px 0' }} />
            {(memberArtistData && memberArtistData?.party_id === -1)
                ?
                <OthersSelector partyObj={{ setPartyName, setPartyPosition }} />
                :
                ""
            }

            <div className="btn-container">
                <Button
                    style={{
                        color: 'var(--light-gray)',
                        borderRadius: '30px',
                        height: '50px',
                        fontSize: '15px',
                        fontWeight: 600,
                        marginTop: '20px',
                        border: '2px solid var(--light-gray)'
                    }}
                    onClick={() => setActiveStep(activeStep - 1)}
                >
                    BACK
                </Button>
                {((memberArtistData && memberArtistData.party_id !== -1) || (partyName.length >= 3 && partyPosition.length >= 3)) &&
                    <Button
                        variant="contained"
                        type="submit"
                        style={{
                            background: '#ff8303',
                            color: '#000',
                            borderRadius: '30px',
                            fontSize: '15px',
                            fontWeight: 600,
                            marginTop: '20px',
                            flex: 1
                        }}
                        onClick={(e) => handleNextStep(e)}
                    >
                        CONTINUE
                    </Button>
                }

            </div>
        </ArtistFormContainer>
    )
}

export default ArtistRegisterStep2
