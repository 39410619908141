import { Badge, Box, Button, Checkbox, Divider, Skeleton, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import "../pages/registration/register.css"
import { BandArtistRokk, MemberRokkArtist, SuccessIcon } from '../assets';
import { ArtistData, MemberArtistData, useStateContext } from '../context/StateContextAPI';
import styled from '@emotion/styled';
import { Close, KeyboardArrowRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { OthersSelector } from './exports/artist-registration-exports';
import TermsOfUseRokk from './exports/terms.register.exports';

const Image = styled.img`
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 5px;
`

// Props Interface fpr 
interface Props {
    stepperCount: number
    setStepCount: (stepCount: number) => void;
}

const ArtistForm: React.FC<Props> = ({ stepperCount, setStepCount }) => {

    // StateContext
    const { artistData, setArtistData, memberArtistData, setMemberArtistData } = useStateContext();

    // Use React Roouter Hook for Stepper...
    const { id } = useParams<{ id: any }>();
    const navigate = useNavigate();
    let stepCount = id | stepperCount;

    // Choose what to do when typing..
    const [isTyping, setIsTyping] = React.useState(false);

    // Handle NameChange State
    const [artistName, setArtistName] = React.useState("");
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setArtistName(value);
    };

    // Handle the Data Change on the state..

    const [handleDataChanges, setHandleDataChanges] = React.useState({
        type: "",
        value: {}
    });

    React.useEffect(() => {
        if (artistName.length < 1) {
            setHandleDataChanges(() => ({ type: "resetArtistDataFromAPI", value: "" }))
        }
    }, [artistName])

    React.useEffect(() => {

        if (handleDataChanges.type === "resetMemberArtistData") {
            setMemberArtistData(() => (
                {
                    party_id: -1,
                    party_name: "",
                    instrument: "",
                    artist_link: ""
                }
            ))
        } else if (handleDataChanges.type === "handleMemberArtistDataChange") {
            setMemberArtistData(() => (handleDataChanges.value))
        } else if (handleDataChanges.type === "handleArtistDataChange") {
            setArtistData(() => (handleDataChanges.value))
            setMemberArtistData(() => (
                {
                    party_id: -1,
                    party_name: "",
                    instrument: "",
                    artist_link: ""
                }
            ))
            setHandleDataChanges(() => ({ type: "resetMemberArtistData", value: "" }))
        } else if (handleDataChanges.type === "resetArtistData") {
            setArtistData(() => (
                {
                    artist_id: -1,
                    artist_name: "",
                    country_short_name: "",
                    formed_in: "",
                    photo_link: "",
                    artist_genres: ""
                }
            ))
        } else if (handleDataChanges.type === "resetArtistDataFromAPI") {
            setArtistsFromAPI(() => ([
                {
                    artist_id: -1,
                    artist_name: "",
                    country_short_name: "",
                    formed_in: "",
                    photo_link: "",
                    artist_genres: ""
                }
            ]))
        }
        else if (handleDataChanges.type === "resetMemberArtistDataFromAPI") {
            setMemberArtistsFromAPI(() => ([
                {
                    party_id: -1,
                    party_name: "",
                    instrument: "",
                    artist_link: "",
                    artist_id: -1,
                    artist_name: ""
                }
            ]))
        }
    }, [handleDataChanges])


    // Fetch the Artist Data on value change in Artist/Band Name Input Field..
    // Dynamic Artist Data API call:
    const [artistsFromAPI, setArtistsFromAPI] = React.useState<ArtistData[]>([
        {
            artist_id: -1,
            artist_name: "",
            country_short_name: "",
            formed_in: "",
            photo_link: "",
            artist_genres: ""
        }
    ])
    const fetchArtistData = React.useCallback(async () => {
        if (artistName.length < 1) return;
        const options = {
            method: 'GET',
            headers: {
                accept: 'application/json'
            }
        }
        try {

            const data = await fetch(`http://localhost:5009/artist?searchParam=${artistName}&limit=5`, options);
            const res = await data.json();
            if (res?.length > 0) {
                setIsTyping(false)
                setHandleDataChanges(() => ({ type: "resetArtistData", value: "" }))
                setArtistsFromAPI(() => res);
            } else {
                setArtistsFromAPI(() => [
                    {
                        artist_id: -1,
                        artist_name: "",
                        country_short_name: "",
                        formed_in: "",
                        photo_link: "",
                        artist_genres: ""
                    }
                ])
            }
        } catch (err) {
            setArtistsFromAPI(() => [
                {
                    artist_id: -1,
                    artist_name: "",
                    country_short_name: "",
                    formed_in: "",
                    photo_link: "",
                    artist_genres: ""
                }
            ])
            console.log('%cNo data found', 'background: #ffffff; padding: 2px 5px; border-radius: 2px; color: black; font-weight: bold;')
        }

    }, [artistName, isTyping]);

    // Fetch Artist React useEffect React Hook
    React.useEffect(() => {
        fetchArtistData();
    }, [fetchArtistData])


    // Fetch the Member Artist Data once the artistData is set...
    const [memberArtistsFromAPI, setMemberArtistsFromAPI] = React.useState<MemberArtistData[]>([
        {
            party_id: -1,
            party_name: "",
            instrument: "",
            artist_link: "",
            artist_id: -1,
            artist_name: ""
        }
    ])

    const fetchMemberArtist = React.useCallback(async () => {
        const options = {
            method: 'GET',
            headers: {
                accept: 'application/json'
            }
        }
        try {
            const artistDatafromSessionStorage: string | null = sessionStorage.getItem("RokkArtistData");
            const artistID: string | number = JSON.parse(artistDatafromSessionStorage ?? "{}").artist_id;
            // console.log(artistID)
            const data = await fetch(`http://localhost:5009/artist/member?artistID=${artistID}`, options);
            const res = await data.json();
            if (res?.length > 0) {
                setMemberArtistsFromAPI(() => res);
            }
        } catch (err) {
            setHandleDataChanges(() => ({ type: "resetMemberArtistDataFromAPI", value: "" }))
            console.log(err)
            console.log('%cNo data found', 'background: #ffffff; padding: 2px 5px; border-radius: 2px; color: black; font-weight: bold;')
        }
    }, [])


    // React useEffect Hooks to Trigger the SessionStorage data change.
    React.useEffect(() => {
        // Update the Session Storage.
        sessionStorage.setItem("RokkArtistData", JSON.stringify(artistData));
    }, [artistData])


    // Functions to render the UI for Dynamic View Changes..

    // Fetch the Artist's UI Element
    const getArtistData = () => {
        if (artistData?.artist_id && artistData.artist_id !== -1) {
            return (
                <Badge badgeContent={<Close sx={{ fontSize: 'small' }} />}
                    color='primary'
                    sx={{
                        '& .MuiBadge-standard': {
                            background: '#ff8303',
                            padding: '2px 0'
                        }
                    }}
                    onClick={() => setHandleDataChanges(() => ({ type: "resetArtistData", value: "" }))}
                >
                    <Box
                        width={'100%'}
                        style={{
                            background: 'rgba(255, 255, 255, 0.1)',
                            borderRadius: '10px',
                            padding: '10px',
                            display: 'flex',
                            color: 'var(--white)',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div className="artist-data-main-section">
                            <Image src={artistData.photo_link} onError={(e: any) => (e.target as HTMLImageElement).src = BandArtistRokk} />
                            <div>
                                <Typography sx={{
                                    fontWeight: 500
                                }}>
                                    {artistData.artist_name}
                                </Typography>
                                <Typography sx={{
                                    color: 'var(--offWhite)',
                                    '@media screen and (max-width: 600px)': {
                                        fontSize: '13px'
                                    }
                                }}>
                                    {`${artistData?.artist_genres}, ${artistData?.country_short_name === 'XX' ? 'International' : artistData?.country_short_name}, ${artistData?.formed_in}`}
                                </Typography>
                            </div>

                        </div>

                        <KeyboardArrowRight sx={{ color: 'var(--light-gray)' }} />

                    </Box>
                </Badge>

            )
        } else if (isTyping) {
            return (
                <>
                    <Skeleton
                        variant="rounded"
                        height={70}
                        sx={{
                            background: 'rgba(255, 255, 255, 0.1)'
                        }}
                    />
                    <Skeleton
                        variant="rounded"
                        height={70}
                        sx={{
                            background: 'rgba(255, 255, 255, 0.1)'
                        }}
                    />
                    <Skeleton
                        variant="rounded"
                        height={70}
                        sx={{
                            background: 'rgba(255, 255, 255, 0.1)'
                        }}
                    />
                    <Skeleton
                        variant="rounded"
                        height={70}
                        sx={{
                            background: 'rgba(255, 255, 255, 0.1)'
                        }}
                    />
                </>
            )
        } else if (artistsFromAPI[0]?.artist_id !== -1) {
            return (
                artistsFromAPI.map((artist: ArtistData, _idx: number) => {
                    return (
                        <Box key={_idx}
                            style={{
                                background: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: '10px',
                                padding: '10px',
                                // height: '50px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                            onClick={() => setHandleDataChanges(() => ({ type: "handleArtistDataChange", value: artist }))}

                        >
                            <div className="artist-data-main-section">
                                <Image src={artist.photo_link} onError={(e: any) => (e.target as HTMLImageElement).src = BandArtistRokk} />
                                <div>
                                    <Typography sx={{
                                        fontWeight: 500
                                    }}>
                                        {artist.artist_name}
                                    </Typography>
                                    <Typography sx={{
                                        color: 'var(--offWhite)',
                                        '@media screen and (max-width: 600px)': {
                                            fontSize: '13px'
                                        }
                                    }}>
                                        {`${artist?.artist_genres?.slice(0,)}, ${artist?.country_short_name === 'XX' ? 'International' : artist?.country_short_name}, ${artist?.formed_in}`}

                                    </Typography>
                                </div>

                            </div>

                            <KeyboardArrowRight sx={{ color: 'var(--light-gray)' }} />

                        </Box>
                    )
                })
            )
        } else {
            return ""
        }

    }

    // Fetch the Member Artists' UI Elements
    const getMemberArtistData = () => {
        if (memberArtistData?.party_id !== -1) {
            return (
                <Badge badgeContent={<Close sx={{ fontSize: 'small' }} />}
                    color='primary'
                    sx={{
                        width: '100%',
                        marginTop: '10px',
                        boxSizing: 'border-box',
                        '& .MuiBadge-standard': {
                            background: '#ff8303',
                            padding: '2px 0'
                        }
                    }}
                    onClick={() => setHandleDataChanges((prev: any) => ({ ...prev, type: "resetMemberArtistData" }))}
                >
                    <Box
                        width={'100%'}
                        style={{
                            background: 'rgba(255, 255, 255, 0.1)',
                            borderRadius: '10px',
                            padding: '10px',
                            height: '50px',
                            display: 'flex',
                            color: 'var(--white)',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div className="artist-data-main-section">
                            <Image src={memberArtistData?.artist_link} onError={(e: any) => (e.target as HTMLImageElement).src = MemberRokkArtist} />
                            <div>
                                <Typography sx={{
                                    fontWeight: 500,
                                    fontSize: 'large'
                                }}>
                                    {memberArtistData?.party_name}
                                </Typography>
                                <Typography sx={{
                                    color: 'var(--offWhite)'
                                }}>
                                    {memberArtistData?.instrument}
                                </Typography>
                            </div>

                        </div>

                        <KeyboardArrowRight sx={{ color: 'var(--light-gray)' }} />

                    </Box>
                </Badge>
            )
        }
        if (memberArtistsFromAPI[0]?.party_id === -1) {
            return (
                <>
                    <Skeleton
                        variant="rounded"
                        height={70}
                        sx={{
                            background: 'rgba(255, 255, 255, 0.1)',
                            marginTop: '10px'
                        }}
                    />
                    <Skeleton
                        variant="rounded"
                        height={70}
                        sx={{
                            background: 'rgba(255, 255, 255, 0.1)',
                            marginTop: '10px'
                        }}
                    />
                    <Skeleton
                        variant="rounded"
                        height={70}
                        sx={{
                            background: 'rgba(255, 255, 255, 0.1)',
                            marginTop: '10px'
                        }}
                    />
                    <Skeleton
                        variant="rounded"
                        height={70}
                        sx={{
                            background: 'rgba(255, 255, 255, 0.1)',
                            marginTop: '10px'
                        }}
                    />
                </>
            )
        } else {
            return (
                memberArtistsFromAPI.map((party: any, _idx: number) => {
                    return (
                        <Box key={_idx}
                            style={{
                                background: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: '10px',
                                padding: '10px',
                                height: '50px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'pointer',
                                marginTop: '10px'
                            }}
                            onClick={() => setHandleDataChanges(() => ({ type: "handleMemberArtistDataChange", value: party }))}
                        >
                            <div className="artist-data-main-section">
                                <Image src={party.artist_link} onError={(e: any) => (e.target as HTMLImageElement).src = MemberRokkArtist} />
                                <div>
                                    <Typography sx={{
                                        fontWeight: 500,
                                        fontSize: 'large'
                                    }}>
                                        {party.party_name}
                                    </Typography>
                                    <Typography sx={{
                                        color: 'var(--offWhite)'
                                    }}>
                                        ({party.instrument})
                                    </Typography>
                                </div>

                            </div>

                            <KeyboardArrowRight sx={{ color: 'var(--light-gray)' }} />

                        </Box>
                    )
                })
            )
        }
    };


    // React useEffect Hook to set istyping Function for Animation Effect on Data Loading
    React.useEffect(() => {
        if (artistName.length > 0) setIsTyping(true);
        else setIsTyping(false);
    }, [artistName])


    const [partyName, setPartyName] = React.useState("")
    const [partyPosition, setPartyPosition] = React.useState("")

    // Stepper Handling Events and Functions..
    const handleChange = (e: any) => {
        setArtistName(() => e.target.value);
    }

    const handleNextStep = async () => {
        await fetchMemberArtist();

        setStepCount(stepCount + 1)
        navigate(`../register-new-artist/${stepCount + 1}`)
    };

    const handlePreviousStep = () => {
        setStepCount(stepCount - 1)
        navigate(`../register-new-artist/${stepCount - 1}`)
    };


    // Render the Stepper Contents..
    const renderFormContent = () => {
        if (stepCount === 0) {
            return (
                <div className="form-content artist_name_field">
                    <label htmlFor="artist_name" className="label_items">Enter Artist Name*</label>
                    <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='Artist Name*' onChange={(e) => handleNameChange(e)} required />

                    {getArtistData()}

                    <div className="btn-container">
                        <Button
                            variant="contained"
                            style={{
                                background: '#ff8303',
                                color: '#000',
                                borderRadius: '30px',
                                height: '50px',
                                fontSize: '15px',
                                fontWeight: 600,
                                marginTop: '20px',
                                flex: 1
                            }}
                            onClick={() => handleNextStep()}
                            disabled={artistData.artist_id === -1}
                        >
                            CONTINUE
                        </Button>
                    </div>
                </div>
            );
        } else if (stepCount === 1) {
            return (
                <div className="form-content">
                    <Box
                        style={{
                            background: 'rgba(255, 255, 255, 0.1)',
                            borderRadius: '10px',
                            padding: '10px',
                            display: 'flex',
                            color: 'var(--white)',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div className="artist-data-main-section">
                            <Image src={artistData.photo_link} onError={(e: any) => (e.target as HTMLImageElement).src = BandArtistRokk} />
                            <div>
                                <Typography sx={{
                                    fontWeight: 500
                                }}>
                                    {artistData.artist_name}
                                </Typography>
                                <Typography sx={{
                                    color: 'var(--offWhite)',
                                    '@media screen and (max-width: 600px)': {
                                        fontSize: '13px'
                                    }
                                }}>
                                    {`${artistData?.artist_genres}, ${artistData?.country_short_name === 'XX' ? 'International' : artistData?.country_short_name}, ${artistData?.formed_in}`}

                                </Typography>
                            </div>

                        </div>

                        <KeyboardArrowRight sx={{ color: 'var(--light-gray)' }} />

                    </Box>
                    <Divider style={{ backgroundColor: 'var(--light-gray)', margin: '20px 0' }} />

                    <span className="head-text" style={{ fontSize: '24px' }}>SELECT BAND MEMBER YOU ARE</span>

                    {/* Input Field */}
                    <div>
                        {getMemberArtistData()}
                        <Divider style={{ backgroundColor: 'var(--light-gray)', margin: '20px 0' }} />
                        {memberArtistData.party_id === -1
                            ?
                            <OthersSelector partyObj={{ setPartyName, setPartyPosition }} />
                            :
                            ""
                        }

                        <div className="btn-container">
                            <Button
                                style={{
                                    color: 'var(--light-gray)',
                                    borderRadius: '30px',
                                    height: '50px',
                                    fontSize: '15px',
                                    fontWeight: 600,
                                    marginTop: '20px',
                                    border: '2px solid var(--light-gray)'
                                }}
                                onClick={() => handlePreviousStep()}
                            >
                                BACK
                            </Button>
                            {memberArtistData.party_id !== -1 || (partyName.length >= 3 && partyPosition.length >= 3)
                                ?
                                <Button
                                    variant="contained"
                                    style={{
                                        background: '#ff8303',
                                        color: '#000',
                                        borderRadius: '30px',
                                        height: '50px',
                                        fontSize: '15px',
                                        fontWeight: 600,
                                        marginTop: '20px',
                                        flex: 1
                                    }}
                                    onClick={() => handleNextStep()}
                                >
                                    CONTINUE
                                </Button>
                                :
                                ""
                            }

                        </div>


                    </div>

                    {/* Add form fields for genres */}
                </div >
            );
        } else if (stepCount === 2) {
            return (
                <div className="form-content">
                    <div className="inp-field-containers">
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">Full Name</label>
                            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='Peter Moog' onChange={(e) => handleChange(e)} required />
                        </div>
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">Instrument*</label>
                            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='Guitar' onChange={(e) => handleChange(e)} required />
                        </div>
                    </div>

                    <div className="inp-field-containers">
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">Type of Artist*</label>
                            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='Band' onChange={(e) => handleChange(e)} required />
                        </div>
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">Country of Origin</label>
                            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='Germany' onChange={(e) => handleChange(e)} required />
                        </div>
                    </div>

                    <div className="inp-field-containers">
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">Website</label>
                            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='www.example.com' onChange={(e) => handleChange(e)} required />
                        </div>
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">Label</label>
                            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='Universal Music' onChange={(e) => handleChange(e)} required />
                        </div>
                    </div>

                    <div className="inp-field-containers">
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">Email Band official contact *</label>
                            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='contact@yourmail.com' onChange={(e) => handleChange(e)} required />
                        </div>
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">Management Email</label>
                            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='management@mail.com' onChange={(e) => handleChange(e)} required />
                        </div>
                    </div>

                    <div className="inp-field-containers">
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">Facebook</label>
                            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='www.facebook.com/your-domain' onChange={(e) => handleChange(e)} required />
                        </div>
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">Instagram</label>
                            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='www.instagram.com/your-domain' onChange={(e) => handleChange(e)} required />
                        </div>
                    </div>

                    <div className="inp-field-containers">
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">Preferred ROKK address</label>
                            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='Preferred-Address' onChange={(e) => handleChange(e)} required />
                        </div>
                        <div className="inp-wrapper" style={{ color: '#ff8303', flex: 0 }}>
                            <span style={{ marginBottom: '12.5px' }}>.rokk.app</span>
                        </div>
                    </div>

                    <div className="btn-container" style={{ marginTop: '20px' }}>
                        <Button
                            style={{
                                color: 'var(--light-gray)',
                                borderRadius: '30px',
                                height: '50px',
                                fontSize: '15px',
                                fontWeight: 600,
                                marginTop: '20px',
                                border: '2px solid var(--light-gray)'
                            }}
                            onClick={() => handlePreviousStep()}
                        >
                            BACK
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                background: '#ff8303',
                                color: '#000',
                                borderRadius: '30px',
                                height: '50px',
                                fontSize: '15px',
                                fontWeight: 600,
                                marginTop: '20px',
                                flex: 1
                            }}
                            onClick={() => handleNextStep()}
                        >
                            CONTINUE
                        </Button>
                    </div>


                    {/* Add form fields for social media links */}
                </div>
            );
        } else if (stepCount === 3) {
            return (
                <div className="form-content">
                    <TermsOfUseRokk />
                    <div className="users-agreement-read">
                        <Checkbox sx={{ color: '#ff8303' }} color="default" />
                        <span>I have read and accept the terms of service</span>
                    </div>

                    <div className="users-agreement-read">
                        <Checkbox sx={{ color: '#ff8303' }} color="default" />
                        <span>I have the right to act on behalf of the above-mentioned artist in business matters.</span>
                    </div>



                    <Divider style={{ backgroundColor: 'var(--light-gray)', margin: '20px 0' }} />


                    <div className="btn-container">
                        <Button
                            style={{
                                color: 'var(--light-gray)',
                                borderRadius: '30px',
                                height: '50px',
                                fontSize: '15px',
                                fontWeight: 600,
                                border: '2px solid var(--light-gray)'
                            }}
                            onClick={() => handlePreviousStep()}
                        >
                            BACK
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                background: '#ff8303',
                                color: '#000',
                                borderRadius: '30px',
                                height: '50px',
                                fontSize: '15px',
                                fontWeight: 600,
                                flex: 1
                            }}
                            onClick={() => handleNextStep()}
                        >
                            CONTINUE
                        </Button>
                    </div>
                </div>
            );
        } else if (stepCount === 4) {
            return (
                <div className="form-content">
                    <div className="greetings-message">
                        <div>
                            <img src={SuccessIcon} alt="success-btn" />
                        </div>
                        <div className="head-text artist-page-head-text">
                            <div>
                                <span className="head-txt-off-white">THANK YOU</span>
                            </div>
                        </div>
                        <div style={{ color: 'var(--light-gray)', fontSize: '14px' }}>
                            Thank you for registering at ROKK! A link with your login details and your personal affiliate link will be sent to you by email shortly before the official launch of ROKK.
                        </div>

                        <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', margin: '20px 0' }} />


                        <div className="btn-container">

                            <Button
                                variant="contained"
                                style={{
                                    background: '#ff830320',
                                    color: '#ff8303',
                                    borderRadius: '30px',
                                    height: '50px',
                                    fontSize: '15px',
                                    fontWeight: 600,
                                    flex: 1
                                }}
                                onClick={() => handleNextStep()}
                            >
                                GO TO LOGIN
                            </Button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    return <div>{renderFormContent()}</div>;
};

export default ArtistForm;
