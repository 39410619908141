import { useCallback, useEffect, useState } from 'react';
import { ArtistFormContainer, ComponentTitle, UniqueCodeBox } from '../exports/band.registration.styles'
import { Box, Button, Checkbox, Typography } from '@mui/material';
import { useStateContext } from '../../context/StateContextAPI';
import TermsOfUseRokk from '../exports/terms.register.exports';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useWindows } from '../../hooks/customHooks';
import { error_toast } from '../../utils/constants';

const ArtistRegisterStep4 = ({ activeStep, setActiveStep }: { activeStep: number, setActiveStep: (step: number) => void }) => {

    const { artistData, memberArtistData, artistInstance, registrationType, bandEmail, bandPassword } = useStateContext();
    const { windowWidth } = useWindows();

    const [termsChecked, setTermsChecked] = useState(false);
    const [rightToActChecked, setRightToActChecked] = useState(false);


    const [links, setLinks] = useState([])

    const [activationCode, setActivationCode] = useState("");
    const generateCode = useCallback((): string => {
        const code = ((Math.random() * 1000000).toFixed(0));
        return code.length < 6 ? generateCode() : code;
    }, []);

    useEffect(() => {
        setActivationCode(generateCode());
    }, [generateCode]);

    useEffect(() => {
        const fetchArtistLinks = async () => {
            const options = {
                method: 'GET',
                headers: {
                    accept: 'application/json'
                }
            }
            const artistID = artistData.artist_id;
            try {
                const data = await fetch(`http://localhost:5009/artist/links?artistID=${artistID}`, options)
                const res = await data.json();
                if (res?.length > 0) {
                    let transformedData = res.map((item: any) => {
                        return JSON.parse(item.link);
                    });
                    setLinks(() => transformedData)
                } else {
                    // Do nothing...
                }
            } catch (err) {
                console.log(err)
            }
        }

        fetchArtistLinks();
    }, [])

    const handleNextStep = async (e: any) => {
        if (termsChecked && rightToActChecked) {
            toast.loading("Please Wait!", { toastId: 1 })
            // setTimeout(() => {
            //     toast.done(1)
            // }, 2000)
            if (registrationType.length > 0 && artistData?.artist_id !== -1 && memberArtistData?.party_id !== -1 && activationCode.length > 5 && bandEmail.trim().length > 5 && bandPassword.trim().length > 7) {

                // Use URLSearchParams to format the body
                const params = new URLSearchParams();
                params.append('type', registrationType);
                params.append('artist_id', artistData.artist_id);
                params.append('artist_name', artistData.artist_name);
                params.append('party_id', memberArtistData.party_id);
                params.append('party_name', memberArtistData.party_name);
                params.append('email', bandEmail);
                params.append('password', bandPassword);
                params.append('passcode', activationCode);
                try {
                    const options = {
                        method: 'POST',
                        headers: {
                            'accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded' // Adjusted this line
                        },
                        body: params.toString() // Formatted the body for x-www-form-urlencoded
                    }
                    const data = await fetch("http://localhost:5009/register", options);
                    const res = await data.json();
                    if (res.success) {
                        toast.done(1);
                        setActiveStep(activeStep + 1)
                    } else {
                        toast.done(1);
                        toast.error("Something went wrong!", error_toast)
                    }
                } catch (error) {
                    console.log(error)
                }
            } else {
                console.log("Reached Else")
                toast.done(1)
                toast.error("Missing Parameters")
            }
        }
        // setActiveStep(activeStep + 1);
    }

    return (
        <ArtistFormContainer>
            <ToastContainer
                position={windowWidth > 700 ? "top-right" : 'bottom-center'}
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <ComponentTitle>
                ACCOUNT ACTIVATION
            </ComponentTitle>

            <UniqueCodeBox>
                {artistInstance ? artistInstance.passcode : activationCode}
            </UniqueCodeBox>

            <UniqueCodeBox
                style={{ display: 'block', padding: '20px' }}
            >
                <Typography sx={{ fontWeight: 600 }}>
                    Please send us the <span style={{ color: 'var(--primary)' }}>activation code</span> on one of these ways:
                </Typography>
                <Typography sx={{ fontSize: '15px' }}>
                    {links.map((item: any, _idx) => {
                        return (
                            <>
                                <Typography>
                                    {item.Facebook ? item.Facebook : null}
                                </Typography>
                                <Typography>
                                    {item.Homepage ? item.Homepage : null}
                                </Typography>
                                <Typography>
                                    {item.Instagram ? item.Instagram : null}
                                </Typography>
                            </>
                        )
                    })}
                    <Typography sx={{ color: 'var(--primary)', margin: '10px 0' }}>
                        OR
                    </Typography>
                    <Typography>
                        An email from your band domain
                    </Typography>
                </Typography>
            </UniqueCodeBox>

            <ComponentTitle style={{ marginTop: '20px' }}>
                TERMS OF SERVICE
            </ComponentTitle>

            <TermsOfUseRokk />

            <div className="users-agreement-read" style={{ marginTop: '20px' }}>
                <Checkbox sx={{ color: '#ff8303' }} color="default"
                    checked={termsChecked}
                    onChange={(e) => setTermsChecked(e.target.checked)}
                />
                <span>I have read and accept the terms of service</span>
            </div>

            <div className="users-agreement-read">
                <Checkbox sx={{ color: '#ff8303' }} color="default"
                    checked={rightToActChecked}
                    onChange={(e) => setRightToActChecked(e.target.checked)}
                />
                <span>I have the right to act on behalf of the above-mentioned artist in business matters.</span>
            </div>


            <div className="btn-container">
                <Button
                    style={{
                        color: 'var(--light-gray)',
                        borderRadius: '30px',
                        height: '50px',
                        fontSize: '15px',
                        fontWeight: 600,
                        marginTop: '20px',
                        border: '2px solid var(--light-gray)'
                    }}
                    onClick={() => setActiveStep(activeStep - 1)}
                >
                    BACK
                </Button>
                {(!artistInstance) &&
                    <Button
                        variant="contained"
                        type="submit"
                        style={{
                            background: '#ff8303',
                            color: '#000',
                            borderRadius: '30px',
                            fontSize: '15px',
                            fontWeight: 600,
                            marginTop: '20px',
                            flex: 1
                        }}
                        onClick={(e) => handleNextStep(e)}
                    >
                        Submit
                    </Button>
                }
            </div>
        </ArtistFormContainer>
    )
}

export default ArtistRegisterStep4