import React, { createContext, ReactNode, useContext, useState, useEffect } from 'react';
import { artist_data, artist_instance } from '../utils/constants';

const StateContext = createContext({} as any);

export type ArtistData = {
    artist_id: number | string,
    artist_name: string,
    country_short_name: string,
    formed_in: string,
    photo_link: string | undefined,
    artist_genres: string
};

export type MemberArtistData = {
    party_id: number | string,
    party_name: string,
    instrument: string,
    artist_link: string,
    artist_id: number | string,
    artist_name: string
};

export type ArtistInstance = {
    type: string,
    artist_id: number | string,
    artist_name: string,
    party_id: number | string,
    party_name: string,
    email: string,
    passcode: number,
    activated: number | boolean
}

export type IfUserRegistered = {
    isFound: number | undefined,
    id: number | string,
    activated: number | boolean
}


export const StateContextProvider = ({ children }: { children: ReactNode }) => {

    const [activePage, setActivePage] = useState<string | null>("home");
    const [plans, setPlans] = React.useState(() => ['month']);

    // Handle the pricing info using country context.
    const [countryCode, setCountryCode] = useState('DE');

    useEffect(() => {
        const getUserLocation = async () => {
            try {
                await fetch('https://ipapi.co/json/')
                    .then(response => response.json())
                    .then(response => {
                        setCountryCode(() => response.country)
                    })
            }
            catch (err) {
                // console.log(err)
                return 'DE'
            }
        }
        getUserLocation();
    }, [])

    const [prices, setPrices] = useState({
        currency: 'EURO',
        star_monthly: '10,99',
        star_annual: '9,16',
        star_student_monthly: '5,49',
        star_student_annual: '4,58',
        hifi_monthly: '19,99',
        hifi_annual: '16,65',
        hifi_student_monthly: '9,99',
        hifi_student_annual: '8,32',
        family_monthly: '16,99',
        family_annual: '14,16'
    })

    // Artist Registration Form Variables.
    const artistDatafromSessionStorage: string | null = sessionStorage.getItem("RokkArtistData");
    let artistDataFromSS: ArtistData;

    try {
        artistDataFromSS = JSON.parse(artistDatafromSessionStorage || "{}");
    } catch (error) {
        console.error("Error parsing artist data from sessionStorage:", error);
        // artistDataFromSS = ;
    }

    const [artistData, setArtistData] = useState<ArtistData>(artist_data);

    const [memberArtistData, setMemberArtistData] = useState<MemberArtistData>(
        {
            party_id: -1,
            party_name: "",
            instrument: "",
            artist_link: "",
            artist_id: -1,
            artist_name: ''
        }
    )

    const [bandEmail, setBandEmail] = useState<string>("")
    const [bandPassword, setBandPassword] = useState<string>("")
    const [preferredBandEmail, setPreferredBandEmail] = useState<string>("");


    // Party Registration
    const [artistID, setArtistID] = useState<number>(NaN);

    // Define the registration type:
    const [registrationType, setRegistrationType] = useState<string>("Artist");

    // Authentication Part
    const [isArtistFound, setIsArtistFound] = useState<IfUserRegistered | undefined>(undefined)
    const [artistInstance, setArtistInstance] = useState<ArtistInstance | undefined>(
        undefined
    )


    return (
        <StateContext.Provider value={
            {
                activePage,
                setActivePage,
                plans,
                setPlans,
                countryCode,
                setCountryCode,
                prices,
                setPrices,
                artistData,
                setArtistData,
                memberArtistData,
                setMemberArtistData,
                bandEmail,
                setBandEmail,
                bandPassword,
                setBandPassword,
                preferredBandEmail,
                setPreferredBandEmail,
                artistID,
                setArtistID,
                artistInstance,
                setArtistInstance,
                isArtistFound,
                setIsArtistFound,
                registrationType,
                setRegistrationType
            }
        }>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);