import { Typography } from '@mui/material'
import React from 'react'

const TermsOfUseRokk = () => {
    return (
        <div className="agreement-details">
            <Typography fontSize={20} color={'#ff8303'} fontWeight={'bold'}>Affiliate Agreement</Typography>
            <br />
            concerning the cooperation in order to acquire users
            for the ROKK music streaming service
            <br /><br />
            <Typography color={'#ff8303'} fontWeight={'bold'}>between</Typography>
            <br />
            FAIRMUSIC AG<br />
            In der Dumpf 12<br />
            D-66130 Saarbrücken<br />
            <Typography color={'#6E6E6E'}>– hereinafter also referred to as “FAIRMUSIC” or the “COMPANY” –</Typography>
            <br />
            <Typography color={'#ff8303'} fontWeight={'bold'}>and</Typography>
            <br />
            ARTIST/Manager<br />
            <Typography color={'#6E6E6E'}>– hereinafter also referred to as “ARTIST” –</Typography>
            readonly:readonly
            <br /><br />
            <Typography color={'#ff8303'} fontWeight={'bold'}>– COMPANY and ARTIST combined hereinafter also referred to as the “Parties” –</Typography>

            <br />
            <Typography fontSize={20} color={'#ff8303'} fontWeight={'bold'}>Preamble:</Typography>

            <br />
            FAIRMUSIC operates a music streaming service aimed at improving ARTIST’s remuneration. This goal is to be reached via a higher payout per stream in combination with direct payments to ARTIST. FAIRMUSIC wishes to achieve direct payments to ARTIST by involving ARTIST in the marketing and promotion of the www.rokk.app fair streaming service, with the mutually beneficial aim of acquiring as many users as possible for the streaming service. In return, FAIRMUSIC will pay ARTIST a share of monthly sales generated via such acquired customers during an agreed term.
            <br /><br />
            This being said, the Parties agree on the following:
            <br /><br />
            <Typography color={'#ff8303'} fontWeight={'bold'}>§ 1 Subject matter:</Typography>
            <br />
            (1) ARTIST will act as a cooperation partner of the COMPANY, marketing an Affiliate Link to be provided to ARTIST by the COMPANY.
            <br /><br />

            <Typography color={'#ff8303'} fontWeight={'bold'}>§ 2 ARTIST’s obligations:</Typography>

            <br />
            (1) ARTIST will, taking into account the Parties’ mutual interests, promote the Affiliate Link to the best of their ability. On the ARTIST side, this could be, for example, sharing the affiliate link regularly on social media, mentioning it in interviews or providing certain materials for promo purposes. Such materials could be video messages, guitar pages or drum skins or even certain contingents of free tickets for concerts that COMPANY can raffle off among users.
            <br /><br />
            (2) According to the prevailing understanding, the Parties agree that such representation is carried out on an avocational basis. ARTIST will ensure that such vocational activities do not conflict with their other interests (in the case of ARTISTS based in Germany, in particular regarding any existing entitlement to benefits from the German Artists Social Security Fund aka Künstlersozialkasse)
            <br /><br />

            <Typography color={'#ff8303'} fontWeight={'bold'}>§ 3 COMPANY’s obligations:</Typography>

            <br />
            (1) The COMPANY will make their best efforts to support ARTIST in carrying out their activities, always providing ARTIST with the required details and information.
            <br /><br />
            (2) The COMPANY will provide login-protected reports containing details on users acquired by ARTIST and registered as users by the COMPANY. The COMPANY will provide settlement information at least semi-annually. Payouts are made automatically once a payable amount of EUR 50.00 (in words: fifty Euros) or more has been reached. Extra payouts can be agreed on individually.
            <br /><br />
            (3) ARTIST will acquire users via the Affiliate Link. If such new users register with and make actual payments for music streams to the COMPANY, ARTIST will be entitled to receive commission payments by the COMPANY. The Parties agree that the decision whether a subscription qualifies in the meaning of below § 4 of this Agreement lies exclusively with the User and the COMPANY.
            <br /><br />
            (4) The COMPANY undertakes to activate (and keep active over the entire contractual term to the best of their abilities) the Affiliate Link for the ARTIST. This being said, ARTIST has no claims towards the COMPANY regarding uninterrupted access to an active Affiliate Link.
            <br /><br />
            <Typography color={'#ff8303'} fontWeight={'bold'}>§ 4 Commissionable transactions:</Typography>

            <br />
            (1) ARTIST is entitled to receive commission payments for every new User brokered to the COMPANY via the Affiliate Link as soon as such new User closes a chargeable transaction with the COMPANY concerning the use of the streaming service. The COMPANY will not make advance payments.
            <br /><br />

            <Typography color={'#ff8303'} fontWeight={'bold'}>§ 5 Commission:</Typography>

            <br />
            (1) In the first two (2) years of the term of this Agreement, the commission due to ARTIST for all commissionable transactions in the meaning of above § 4 amounts to 10% of the net revenue generated by the User for the COMPANY via the streaming service. In the 3rd year, the commission amounts to 5% of the net revenue generated for the COMPANY via the streaming service. Commission payments are subject to statutory VAT, which will be owed as well.
            <br /><br />
            (2) Commission payments will be calculated based on full revenues excluding VAT according to the respective applicable price list, which will be attached to this Agreement as Appendix 5.2.
            <br /><br />
            (3) The commission amounts and calculation methods specified above can only be modified via written contractual agreements.
            <br /><br />

            <Typography color={'#ff8303'} fontWeight={'bold'}>
                § 6 Loss of entitlement to commission:</Typography>

            <br />
            (1) The entitlement to commission payments ends if – for any possible reason – the subscription is not valid anymore. It also ends if the User terminates their subscription or does not pay due subscription fees.
            <br /><br />

            <Typography color={'#ff8303'} fontWeight={'bold'}>
                § 7 Commission settlement:</Typography>

            <br />
            (1) The COMPANY will provide ARTIST with information on due commission payments in the login area within thirty (30) business days after the realization of revenues.
            <br /><br />
            (2) Provision settlements will be created automatically at the end of an accounting month if a minimum amount of EUR 50.00 is due. VAT on payable commission payments will be stated separately.
            <br /><br />

            <Typography color={'#ff8303'} fontWeight={'bold'}>§ 8 ARTIST’s costs:</Typography>

            <br />
            ARTIST is not entitled to the reimbursement of costs related to the acquisition of users.
            <br /><br />

            <Typography color={'#ff8303'} fontWeight={'bold'}>
                § 9 Contractual term:</Typography>

            <br />
            This Agreement enters into effect with its signature or with its conclusion via the COMPANY’s website. Its initial term is one (1) year. The Agreement is automatically renewed for another year unless terminated three (3) months in advance to the end of a term. Both Parties’ right to extraordinary termination in accordance with the relevant statutory provisions remains unaffected. In addition, the COMPANY has the right to terminate this Agreement for substantial reasons.
            <br /><br />

            <Typography color={'#ff8303'} fontWeight={'bold'}>
                § 10 Confidentiality:</Typography>

            <br />
            (1) Both Parties will treat all trade secrets, including the content of this Agreement as well as other information of the respective other Party labelled as confidential (hereinafter also referred to as “Confidential Information”) confidential, and both Parties accept the non-disclosure agreement linked during the registration process.
            <br /><br />

            <Typography color={'#ff8303'} fontWeight={'bold'}>
                § 11 Applicable law, court of venue:</Typography>

            <br />
            (1) This Agreement shall be subject to the Laws of the Federal Republic of Germany. UN Sales Law (CISG) shall be excluded.
            <br /><br />
            (2) Any and all disputes arising from or in connection with this Agreement (including such disputes concerning the validity of this Agreement) shall be brought to the courts at the COMPANY’s business domicile.
            <br /><br />

            <Typography color={'#ff8303'} fontWeight={'bold'}>§ 12 Data protection:</Typography>


            <br />
            (1) Both Parties will observe all data protection related laws and regulations applicable to them.
            <br /><br />
            (2) If and to the extent the COMPANY engages in order data processing (Auftragsdatenverarbeitung) concerning personal data of ARTIST’s employees during the performance of this Agreement, the Parties will enter into a corresponding data processing agreement in the meaning of Art. 28 DSGVO (GDPR) prior to the beginning of such data processing activities.
            <br /><br />

            <Typography color={'#ff8303'} fontWeight={'bold'}>
                § 13 Final provisions:
            </Typography>

            <br />
            (1) Any assignment of rights granted under this Agreement is subject to the prior written consent of the respective other Party.
            <br /><br />
            (2) Any modifications or amendments to this agreement must be in writing in order to be effective, except for when statutory provisions require a stricter form. This provisions also covers changes to this very requirement of writing.
            <br /><br />
            (3) If any of the provisions of this Agreement should be or become partially or entirely invalid or unenforceable, then this shall not affect the validity of the remaining provisions. The Parties undertake to replace any invalid or unenforceable provision with a valid and effective one matching as closely as possible the legal and economic purpose of the original provision in a way they would have done from the beginning had they realized the invalidity or unenforceability of the respective provision. The same holds in the event of gaps in this Agreement.

        </div>
    )
}

export default TermsOfUseRokk