import styled from '@emotion/styled'
import { Button, Typography } from '@mui/material';
import { NotFoundImage } from '../assets';
import { useStateContext } from '../context/StateContextAPI';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundContainer = styled.div`
    padding: 0px var(--global-padding);
    text-align: center;
`;

const Image = styled.img`
    width: 60%;
    @media screen and (max-width: 600px){
        width: 100%;
    }
    
`;

const FallbackPage = () => {

    const { setActivePage } = useStateContext();
    const navigate = useNavigate();

    useEffect(() => {
        setActivePage(() => "")
        window.scrollTo(0, 0);
    }, [setActivePage])

    return (
        <NotFoundContainer>


            <Image src={NotFoundImage} />
            <Typography
                sx={{
                    fontSize: '20px',
                    fontWeight: '600',
                    textAlign: 'center',
                    margin: '10px 0',
                    color: 'var(--primary)'
                }}
            >
                OOPS! PAGE NOT FOUND
            </Typography>

            <Typography
                sx={{
                    // fontWeight: '700',
                    textAlign: 'center',
                    color: 'var(--light-gray)'
                }}
            >
                The page you are looking for, could not be found.
            </Typography>
            <Button variant="outlined"
                sx={{
                    margin: '20px 0',
                    borderRadius: '30px',
                    padding: '10px 30px',
                    color: 'rgba(255, 255, 255, 0.6)',
                    borderColor: 'rgba(255, 255, 255, 0.6)',
                    '&:hover': {
                        borderColor: 'var(--primary)',
                        color: 'var(--primary)'
                    }
                }}
                onClick={() => navigate(-1)}
            >
                GO Back
            </Button>
        </NotFoundContainer>
    )
}

export default FallbackPage