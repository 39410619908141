type PlansFAQType = {
    [key: string]: {
      ques: string;
      ans: string;
    }[];
  };

export const plansfaq: PlansFAQType = {
    "hifi": [
        {
            ques: "30 days FREE TRIAL?",
            ans: `
                ROKK is free for 30 days.<br /><br />
                You will NOT be charged before the end of your trial period.<br /><br />
                After your trial period ends, you will be charged 10,99 €/month.<br /><br />
                Your subscription can be cancelled at any time prior to the next billing cycle.
            `
        },
        {
            ques: "All of ROCK & METAL, including every other style of music?",
            ans: `
                ROKK is for fans. Especially Rock & Metal fans!<br /><br />
                But sometimes even the most dedicated Metal fan wants to listen to something else.<br /><br />
                With ROKK you get access to a full music library containing any genre you would ever want, just as you do on any modern streaming platform.<br /><br />
            `
        },
        {
            ques: "Up to 10% Direct Artist Support & more money for bands/artists?",
            ans: `
                The ROKK HIFI plan allows you to directly support a ROKK partner artist of your choice 
                For 2 years!<br /><br />

                The Direct Artist Support is not part of the royalty payments, but one of the differences that we take on our end to put more money in artists pockets. For the first year 10% of your subscription goes to the act you choose (you will need to sign up through a link provided by that particular band).<br /><br />

                After your first year we will pay 5% of your monthly subscription to either the act you originally came to support or you can switch to another ROKK partner band/artist.<br /><br />

                By choosing our higher tier “ROKK HIFI PRO” you can guarantee that up to 10% of your monthly subscription will continually support the act of your choice. See further information regarding this on the “ROKK HIFI PRO” plan.<br /><br /> 

                For more detailed information on how we intend to pay more money per stream to bands & artists(and what constitutes a ROKK Partner Artists) refer to our FAQ page.<br /><br />
            `
        },
        {
            ques: "HIFI SOUND QUALITY?",
            ans: `
                ROKK is for music lovers. Our basic streaming quality already meets the standard of what is considered HiFi. We use FLAC & WAV based on what is available from the artist/distributor which is then encoded using OPUS at 160kbps.
            `
        },
        {
            ques: "ROKKPEDIA - Background information on all artists?",
            ans: `
                ROKK will build your musical expertise. Our goal is to have the largest online encyclopaedia for Rock and Metal artists, iconic albums, line-ups and line-up changes and just about everything else you can imagine. We like to call it our “ROKKPEDIA”.<br /><br />
                Over time, more and more of this information will be at your fingertips from right inside the ROKK app.           
            `
        }
    ],
    "hifipro": [
        {
            ques: "30 days FREE TRIAL?",
            ans: `
                ROKK is free for 30 days.<br /><br />
                You will NOT be charged before the end of your trial period.<br /><br />
                After your trial period ends, you will be charged 10,99 €/month.<br /><br />
                Your subscription can be cancelled at any time prior to the next billing cycle.  
            `
        },
        {
            ques: "All of ROCK & METAL, including every other style of music?",
            ans: `
                ROKK is for fans. Especially Rock & Metal fans!<br /><br />
                But sometimes even the most dedicated Metal fan wants to listen to something else.<br /><br />
                With ROKK you get access to a full music library containing any genre you would ever want, just as you do on any modern streaming platform.<br /><br />
            `
        },
        {
            ques: "80% more Direct Artist Support?",
            ans: `
                The ROKK HIFI PRO plan allows you to directly support a ROKK partner artist of your choice 
                For 2 years with 10% of your monthly subscription, which results in 80% more direct artist support than on the “ROKK HIFI” Plan!<br /><br />
                
                The Direct Artist Support is not part of the royalty payments, but one of the differences that we take on our end to put more money in artists pockets (you will need to sign up through a link provided by that particular band).<br /><br />
                
                After your first year you can choose to reallocate 5% of your monthly subscription to another ROKK partner band/artist.<br /><br />
                
                Unlike with our other tiers the “ROKK HIFI PRO” can guarantee that 10% of your monthly subscription will continually support the act(s) of your choice.<br /><br /> 
                
                For more detailed information on how we intend to pay more money per stream to bands & artists(and what constitutes a ROKK Partner Artists) refer to our FAQ page.
            `
        },
        {
            ques: "HIFI SOUND QUALITY?",
            ans: `
                ROKK is for music lovers. With “ROKK HIFI PRO” we provide Lossless Audio (FLAC 16bit 44.1khz) based on what is available from the artist/distributor.
            `
        },
        {
            ques: "ROKKPEDIA - Background information on all artists?",
            ans: `
                ROKK will build your musical expertise. Our goal is to have the largest online encyclopaedia for Rock and Metal artists, iconic albums, line-ups and line-up changes and just about everything else you can imagine. We like to call it our “ROKKPEDIA”.<br /><br />
                Over time, more and more of this information will be at your fingertips from right inside the ROKK app.           
            `
        }
    ],
    "hififamily": [
        {
            ques: "30 days FREE TRIAL?",
            ans: `
                ROKK is free for 30 days.<br /><br />
                You will NOT be charged before the end of your trial period.<br /><br />
                After your trial period ends, you will be charged 10,99 €/month.<br /><br />
                Your subscription can be cancelled at any time prior to the next billing cycle.
            `
        },
        {
            ques: "All of ROCK & METAL, including every other style of music?",
            ans: `
                ROKK is for fans. Especially Rock & Metal fans!<br /><br />
                But sometimes even the most dedicated Metal fan wants to listen to something else.<br /><br />
                With ROKK you get access to a full music library containing any genre you would ever want, just as you do on any modern streaming platform.<br /><br />
            `
        },
        {
            ques: "What about Direct Artist Support?",
            ans: `
                ROKK caters to artists, but we also love ROKKING families!<br /><br />

                However, with the discount that we offer here there simply is no margin to provide additional artist support. As part of the ROKK community you still contribute significantly more to artists & bands.<br /><br />
                
                For more detailed information on how we intend to pay more money per stream to bands & artists(and what constitutes a ROKK Partner Artists) refer to our FAQ page.
            
            `
        },
        {
            ques: "HIFI SOUND QUALITY?",
            ans: `
                ROKK is for music lovers. Our basic streaming quality already meets the standard of what is considered HiFi. We use FLAC & WAV based on what is available from the artist/distributor which is then encoded using OPUS at 160kbps.
            `
        },
        {
            ques: "ROKKPEDIA - Background information on all artists?",
            ans: `
                ROKK will build your musical expertise. Our goal is to have the largest online encyclopaedia for Rock and Metal artists, iconic albums, line-ups and line-up changes and just about everything else you can imagine. We like to call it our “ROKKPEDIA”.<br /><br />
                Over time, more and more of this information will be at your fingertips from right inside the ROKK app.           
            `
        },
        {
            ques: "4 Accounts For Your Family",
            ans: `
                With a ROKK Hifi Family plan, up to 4 people from your family can use ROKK.           
            `
        }
    ]
}