import React, { useEffect } from 'react'
import { ContactInformationContainer, InfoContainer } from '../components/exports/company-info-styles.export'
import { Typography } from '@mui/material'
import { useStateContext } from '../context/StateContextAPI';

const PrivacyPolicyPage = () => {

    const { setActivePage } = useStateContext();

    useEffect(() => {
        setActivePage(() => "")
        window.scrollTo(0, 0);
    }, [setActivePage])

    return (
        <ContactInformationContainer>
            {/* <div className="rokk-faq-container"> */}
            <div className='rokk-users-faq-sec'>

                <div className="faq-section-head-text mt-10">PRIVACY POLICY</div>
                <InfoContainer>
                    <Typography sx={{
                        color: 'var(--primary)',
                        marginBottom: '10px',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                       1. Section 1 - General information
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        marginBottom: '10px',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        1.1 Terms:
                    </Typography>
                    <Typography>
                        Personal data: This is any information relating to an identified or identifiable natural person (hereinafter: data
                        subject). A person is considered identifiable if they can be identified directly or indirectly, in particular by
                        association with an identifier such as a name or an identification number. Personal data includes, for example,
                        name, contact details, contract data, etc.

                        <br /><br />
                        Processing: any operation or set of operations which is performed on personal data, such as collection,
                        recording, organisation, storage, adaptation, alteration, retrieval, consultation, use, disclosure by transmission,
                        dissemination or otherwise making available, alignment or combination, restriction or erasure of personal data,
                        whether or not by automated means.
                        <br /><br />
                        Restriction of processing:   The   marking   of   stored   personal   data   with   the   aim   of   restricting   its   future
                        processing.
                        <br /><br />
                        Anonymisation: When information is not related to an identified or identifiable natural person or personal
                        data has been anonymised in such a way that the data subject can no longer be identified.
                        <br /><br />
                        Pseudonymisation: The processing of personal data in such a manner that the personal data can no longer
                        be attributed to a specific data subject without the use of additional information, provided that such additional
                        information is kept separately and is subject to technical and organisational measures to ensure that the
                        personal data can no longer be attributed to an identifiable individual.
                        <br /><br />
                        Controller: Any natural or legal person, public authority, agency or other body which, alone or jointly with
                        others, determines the purposes and means of the processing of personal data.
                        <br /><br />
                        Processor: A natural or legal person, public authority, agency or other body which processes personal data
                        on behalf of the controller.
                        <br /><br />
                        Recipient:  A natural or legal person, public authority, agency or other body to whom personal data is
                        disclosed, whether or not it is a third party.
                        <br /><br />
                        Consent: Any voluntary, informed and unambiguous expression of will in the form of a statement or other
                        clear affirmative act for a specific case by which the data subject signifies his or her consent to the processing
                        of his or her personal data. If we are allowed to process your data, we require your consent, which you can
                        find in the appropriate place on our website. There you will also find further information on the processing of
                        your data in the context of consent.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        1.2 Person Responsible 
                    </Typography>
                    <Typography>
                        FAIRMUSIC AG, Saarbrücken Local Court HRB 109272
                        <br /><br />
                        In der Dumpf 12, 66130 Saarbrücken, Germany<br /><br />
                        E-mail: support@rokk.app<br /><br />
                        You can also find more information in the legal notice. You can access this within the app or on our website.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        1.3 Information that is collected by the respective app store when the app is downloaded
                    </Typography>
                    <Typography>
                    When you download the app, certain information is transmitted to the app store you have selected, for
example Google Play or Apple App Store. This includes your user name, your email address, the customer
number of your account, the time of the download, payment information and the Mac address (Media Access
Control). This data is processed exclusively by the respective app store and is beyond our control.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        1.4 Processing of payment data
                    </Typography>
                    <Typography>
                        As with any app where you can book content, we need to collect personal data from you as part of the
                        booking process. This includes in particular the respective device ID, the specified payment data and other
                        data that the payment service provider transmits to us.

                        <br /><br />
                        In the course of contract processing, we will also pass on your personal data to third parties for the fulfilment
                        of our contract for a specific purpose. Particularly sensitive data in the context of billing will only be transmitted
                        on the basis of state-of-the-art encryption.

                        <br /><br />
                        Payment processing is also carried out by Stripe, Inc, 185 Berry Street, Suite 550, 94107 San Francisco, USA.
                        We use the service provider Stripe to process payments for corporate customers as part of the contract.
                        Personal data is also transferred to the USA. The operator of the service is certified under the DPF, so that
                        the usual level of protection of the GDPR applies to the transfer.

                        <br /><br />
                        The companies that receive further data from Stripe or from us are:
                        <br />
                        -the  payment provider you have selected;<br />
                        -email  provider when we contact you by email.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        1.5 (Sub-) service provider for the operation of the app
                    </Typography>
                    <Typography>
                        With regard to our app, we work with a service provider who supports the technical infrastructure (hosting,
                        delivery, etc.) of the app on the basis of order processing contracts within the meaning of Art. 28 GDPR.
                        These service providers are:<br /><br />
                        Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855, Luxembourg, LuxembourgE-mail:
                        privacyshield@amazon.com, Website: http://aws.amazon.com/.
                        <br /><br />
                        Personal data is also transferred to the USA. With regard to the transfer of personal data to the USA, there is
                        an adequacy decision on the EU-US Data Privacy Framework of the EU Commission within the meaning of
                        Art.   45   GDPR   (hereinafter:   DPF   -   https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-
                        be03fcb0fddf_en). The operator of the service is certified under the DPF, so that the usual level of protection
                        of the GDPR applies to the transfer.
                        <br /><br />
                        Amazon AWS is a cloud computing service from Amazon that is used to download our app or individual
                        elements of our app. The AWS cloud enables us to deliver our app and its services.
                        <br /><br />
                        The legal basis for this transfer is Art. 6 para. 1 lit. b GDPR (fulfilment of contract), as we cannot maintain our
                        system without transfers to the AWS structure.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        1.6 Provision of the app or web interface and creation of log files
                    </Typography>
                    <Typography
                        sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'}}
                    >
                        What personal data do we process when providing the app and when creating the log files and for what purpose?
                    </Typography>

                    <Typography>
                        (1) Information about the app and the version used<br /><br />
                        (2) The operating system, the system version and the device ID as well as the manufacturer of the retrieval
                        device<br /><br />
                        (3) The IP address and the MAC address of the access device<br /><br />
                        (4) Date and time of access<br /><br />
                        (5) A unique ID to recognise the user<br /><br />
                        This data is stored in the log files of our system. This data is not stored together with the personal data of a
                        specific user, meaning that individual app users cannot be identified. The data is also anonymised after the
                        deletion period so that it can no longer be traced back to an individual person. The anonymised data allows us
                        to monitor and improve the stability and availability of the app over a longer period of time. The temporary
                        (automated) storage of the data is necessary for the use of the app in order to enable the content of the app to
                        be delivered.<br /><br />
                        This personal data is also stored and processed to maintain the compatibility of the app with the respective
                        runtime environments (Android or iOS versions) for as many visitors as possible and to combat misuse and
                        troubleshooting. For this purpose, it is necessary to log the technical data of the accessing end device in order
                        to be able to react as early as possible to display errors, attacks on our IT systems and/or errors in the
                        functionality of the app.<br /><br />
                        The data is also collected anonymously so that the security of the app and its interactivity can be permanently
                        checked and improved.
                    </Typography>

                    <Typography
                        sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'}}
                    >
                        What is the legal basis for this processing?
                    </Typography>

                    <Typography>
                    Art. 6 para. 1 lit. f GDPR (legitimate interest): Our legitimate interest is to ensure that the purpose described
above is achieved. The app cannot be delivered without the log files created, as the data is required to deliver
the content, among other things.
                    </Typography>
                    
                    <Typography
                        sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'}}
                    >
                        How long will your data be stored?
                    </Typography>

                    <Typography>
                    The data is anonymised when it is no longer needed to ensure that the app works for all users. This happens
no later than 3 months after the app has been used.
                    </Typography>

                    <Typography
                        sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'}}
                    >
                        Your rights
                    </Typography>

                    <Typography>
                    You can find out what rights you are entitled to and how you can assert them in section 2.8 of this privacy
policy. You can prevent us from collecting log files by not using the app.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Usage data
                    </Typography>
                    <Typography>
                        Interaction with the product (including playback data), advertising data.
                    </Typography>

                    <Typography>
                        Collected by us when you use FAIRMUSIC / ROKK STREAMING or interact with advertisements.

                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        1.7 General information on making contact
                    </Typography>
                    <Typography>
                        Please note that the security of your data during transmission to our IT systems cannot be fully guaranteed in
                        the   case   of   unencrypted   communication   by   e-mail.   We   therefore   expressly   recommend   encrypted
                        communication or the postal service for information requiring a high level of confidentiality.
                        <br /><br />
                        There are various risks associated with unencrypted e-mail transmissions, such as
                        <br /><br />
                        • Personal data could be viewed by unauthorised third parties if the e-mail address is entered incorrectly.
                        <br /><br />
                        • You have no control over who on the recipient side has access to the email and how many people this is.
                        <br /><br />
                        • Without   encryption,   there   is   always   the   possibility   that   unauthorised   third   parties   can   access   various
                        intermediate points during data transmission.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        2. Section 2 - Functions of the app
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        2.1. Registration
                    </Typography>

                    <Typography
                        sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'}}
                    >
                        What personal data is collected and what is it used for?
                    </Typography>

                    <Typography>
                        To use our app, you need to create a user account. When registering, simply enter your e-mail address and
                        choose a password. All mandatory fields must be completed. After registering, you will immediately receive an
                        email with a confirmation link. Click on it to activate your user account. In future you will be able to log in to our
                        login server with your e-mail address and password. Registration is necessary so that you can create a user
                        account.<br /><br />
                        Our app can alternatively be used via the use of an Apple or Google account (Apple Sign-In and Google Sign-
                        In). Other social logins may also be possible. When registering using an Apple or Google or social account,
                        your user data of the corresponding account (login name / or stored e-mail address and ID of the user
                        account)   is   processed   and   authentication   is   carried   out   in   accordance   with   the   specifications   of   the
                        corresponding login provider. As a rule, we do not receive any further personal data.
                    </Typography>

                    <Typography
                        sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'}}
                    >
                        What is the legal basis for the processing?
                    </Typography>

                    <Typography>
                        In accordance with Article 6(1)(b) of the General Data Protection Regulation (GDPR), we use your data to
                        provide the services you have requested. This is done in connection with the terms of use, which are available
                        in the app and on our website.
                    </Typography>

                    <Typography
                        sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'}}
                    >
                        Who has access to your registration data?
                    </Typography>

                    <Typography>
                        The personal information you enter during registration will be processed by us in order to provide your user
                        account. However, if you enter data during registration that is to be transmitted to third parties, such as public
                        bodies, we will forward the personal data you provide to the desired body.
                    </Typography>

                    <Typography
                        sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'}}
                    >
                        How long will your data be stored?
                    </Typography>

                    <Typography>
                        The information we receive during registration will be retained for as long as you have a user account with us.
                        Please note, however, that we must retain certain data for up to ten years due to legal obligations, such as tax
                        regulations, even if you have deleted your user account. This data will then be deleted immediately if there are
                        no longer any legal retention periods.
                    </Typography>

                    <Typography
                        sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'}}
                    >
                        Your rights
                    </Typography>

                    <Typography>
                        The rights to which you are entitled and how you can assert them can be found at the end of this privacy
                        policy.
                    </Typography>

                    <Typography
                        sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'}}
                    >
                        Necessity of the information and consequences of not providing the data
                    </Typography>

                    <Typography>
                        You must register so that we can provide you with a user account. If you wish to create an account, you must
                        at   least   complete   the   information   marked   as   mandatory   fields   during   registration   and   consent   to   data
                        processing. A user account allows you to manage your data and check the services you have booked.
                        However, it is also possible to install the app without registering.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        2.2. Login Area - User Account
                    </Typography>
                    <Typography
                        sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'}}
                    >
                        What personal data do we process when you log in to your user account and what is the purpose of
                        this processing?
                    </Typography>

                    <Typography>
                        You can use a separate area in the app called "User account". To ensure that you are authorised to use the
                        account, simply enter your login details (email and password) in the login form. If you wish, we can send you
                        your login details on request or help you reset your password by sending you an email.
                    </Typography>

                    <Typography
                        sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'}}
                    >
                        What is the legal basis for the processing?
                    </Typography>

                    <Typography>
                        In accordance with Article 6(1)(b) of the General Data Protection Regulation (GDPR), it is not possible to log in
                        to the user account without the corresponding login data, as this is necessary for the implementation of
                        (pre-)contractual measures.
                    </Typography>


                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        How long will your data be stored?
                    </Typography>
                    <Typography>
                        The information we receive during registration will be retained for as long as you have a user account with us.
                        Please note, however, that we must retain certain data for up to ten years due to legal obligations, such as tax
                        regulations, even if you have deleted your user account. This data will then be deleted immediately if there are
                        no longer any legal retention periods.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Your rights
                    </Typography>
                    <Typography>
                        The rights to which you are entitled and how you can assert them can be found at the end of this privacy
                        policy.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Necessity of the information and consequences of failure to provide it
                    </Typography>
                    <Typography>
                        Successful registration is required to use the user account. To do this, we need to provide and process your
                        personal data. If you enter your data incorrectly or not at all, you will not be able to use your user account. In
                        your user account, you can view your previous entries and booked services and manage your data. You will
                        not have to re-enter your data for future bookings.
                    </Typography>


                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        2.3. Contact form(s)
                    </Typography>
                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        What personal data is collected and to what extent is it processed?
                    </Typography>

                    <Typography>
                        We will process the data you enter in our contact forms, which you have entered in the input mask of the
                        ontact form, to fulfil the purpose stated below.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Legal basis for the processing of personal data
                    </Typography>

                    <Typography>
                        Art. 6 para. 1 lit. a GDPR (consent by clear affirmative action or behaviour)
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Purpose of data processing
                    </Typography>

                    <Typography>
                        We will only use the data collected via our contact form or via our contact forms to process the specific contact
                        enquiry received via the contact form.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Duration of storage
                    </Typography>

                    <Typography>
                        After your enquiry has been processed, the data collected will be deleted immediately, provided there are no
                        statutory retention periods.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Cancellation and deletion option
                    </Typography>
                    <Typography>
                        The cancellation and deletion options are based on the general regulations on the right of cancellation and
                        deletion under data protection law described below in this data protection declaration.
                    </Typography>


                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Necessity of providing personal data
                    </Typography>
                    <Typography>
                        The use of the contact forms is voluntary and is neither contractually nor legally required. You are not obliged
                        to contact us via the contact form, but can also use the other contact options provided on our website. If you
                        wish to use our contact form, you must complete the fields labelled as mandatory. If you do not fill in the
                        required   information   on   the   contact   form,   you   will   either   not   be   able   to   send   the   enquiry   or   we   will
                        unfortunately not be able to process your enquiry.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        2.4. Push notifications
                    </Typography>
                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        What personal data of yours do we process in the context of push notifications and what is it used
                        for?
                    </Typography>
                    <Typography>
                        Depending on whether you use an Apple or Android device, you will receive an identification number (ID) that
                        we store. When the app sends you a notification, the message is automatically sent to your device. Sending
                        such notifications helps us to communicate better with you and display activities in the app. To send these
                        notifications, we use the Google Firebase Cloud Messaging service.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        What is the legal basis for the processing?
                    </Typography>
                    <Typography>
                        Article 6(1)(a) of the General Data Protection Regulation (GDPR), consent is given during the installation of
                        the app, within the app or in the settings of the mobile device.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        How long will your data be stored?
                    </Typography>
                    <Typography>
                        After we have sent you a notification, your personal data will be deleted immediately, unless there are legal
                        regulations that require longer storage. We do not store the notifications on our server.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Your rights
                    </Typography>
                    <Typography>
                        You have the option of deactivating the receipt of notifications at any time. You can do this by either switching
                        off all notifications in the app settings or revoking the app's permission to do so in the operating system
                        functions provided for this purpose.
                        <br /><br />
                        The rights to which you are entitled and how you can assert them can be found at the end of this privacy
                        policy.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Necessity of the information and consequences of failure to provide it
                    </Typography>
                    <Typography>
                        It is neither legally nor contractually necessary to provide us with your personal data for the sending of
                        notifications. However, the use of your identification number (ID) is necessary if you wish to receive push
                        notifications. If you switch off this function, you will no longer receive notifications from the app.
                    </Typography>


                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        2.5. Error Reporting
                    </Typography>
                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        What personal data is processed as part of error reporting and what is it used for?
                    </Typography>
                    <Typography>
                        The data we collect during error reporting (such as device model, operating system version, app version and
                        time of the error) is used to ensure that the app works and to improve it. The detection and handling of errors
                        in the app are necessary to keep the app permanently available and to be able to react quickly to security
                        problems. We receive the error reports via the corresponding operating systems.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        What is the legal basis for processing for the purpose of error reporting?
                    </Typography>
                    <Typography>
                        Art. 6 para. 1 lit. f GDPR (legitimate interest): Our legitimate interest is to ensure the fulfilment of the purpose
                        described above.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        How long will your data be stored?
                    </Typography>
                    <Typography>
                        After we have sent you a notification, your personal data will be deleted immediately, unless there are legal
                        regulations that require longer storage. We do not store the notifications on our server.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Your rights
                    </Typography>
                    <Typography>
                        The rights to which you are entitled and how you can assert them can be found at the end of this privacy
                        policy.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Necessity of the information and consequences of failure to provide it
                    </Typography>
                    <Typography>
                        It is neither legally nor contractually required to provide us with your personal data. However, failure to provide
                        it could result in the app not working properly for a longer period of time, as we may not be able to fix bugs in
                        the app.
                    </Typography>



                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        2.6. Statistical analysis of visits to this app and web services
                    </Typography>
                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Google Maps
                    </Typography>

                    <Typography>
                        We use the Google Maps service of Google Ireland Limited, Gordon House, Barrow Street, 4 Dublin,
                        Ireland, e-mail: support-deutschland@google.com, website: http://www.google.com on our website or in
                        our app. The processing also takes place in a third country for which there is no adequacy decision by
                        the Commission. Therefore, the level of protection customary for the GDPR cannot be guaranteed for the
                        transfer, as it cannot be ruled out that authorities in the third country, for example, may access the data
                        collected.<br /><br />
                        The legal basis for the transfer of personal data is your consent in accordance with Art. 6 para. 1 lit. a
                        GDPR or Art. 9 para. 2 lit. a GDPR, which you have given on our website or in the app.
                        <br /><br />
                        We use Google Maps in our app to show you map information and locations.
                        <br /><br />

                        If the service is activated, our website establishes a connection to the servers of Google Ireland Limited and transmits the required data. As part of order processing, personal data may also be transferred to the servers of Google LLC, 1600 Amphitheatre Parkway, 94043 Mountain View, Uni-ted States. The processing then also takes place in a third country for which there is no adequacy decision by the Commission. Therefore, the usual level of protection for the GDPR cannot be gua-ranteed when the data is transferred, as it cannot be ruled out that authorities in the third country, for example, may access the data collected. When using the Google service on our website, Google may transmit and process information from other Google services in order to provide background services for the display and data processing of the services provided by Google. For this purpose, data may also be transferred to the Google services Google Apis, Doubleclick, Google Cloud, Google Ads and Google Fonts in accordance with the Google Privacy Policy.
                        <br /><br />You can withdraw your consent at any time. You can find more information on revoking your consent either in the consent itself or at the end of this privacy policy.
                        <br /><br />Further information on the handling of the transferred data can be found in the provider's privacy po-licy at https://policies.google.com/privacy.
                        <br /><br />The provider also offers an opt-out option at https://support.google.com/My-Ad-Center-Help/answer/12155451?hl=de.

                    </Typography>


                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        2.7. Google Analytics
                    </Typography>
                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Scope of the processing of personal data
                    </Typography>

                    <Typography>
                        In the app, we use the web tracking service of Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland (hereinafter: Google Analytics). Google Analytics makes it possible to ana-lyse the use of the app and user behaviour (so-called tracking). 
                        <br /><br />We carry out this analysis on the basis of the Google Analytics tracking service in order to constantly optimise our offering and make it more readily available. When the app is used, data such as the IP address and user activities in particular are transmitted to servers of Google Ireland Limited. We carry out this analysis on the basis of Google's tracking service in order to constantly optimise the app and make it more readily available. We also require web tracking for security reasons. Web tracking allows us to track whether third parties are attacking the app. The informati-on from the web tracker enables us to take effective countermeasures and protect the personal da-ta we process from these cyberattacks. By activating IP anonymisation within the Google Analytics tracking code of the app, the IP address is anonymised by Google Analytics before transmission. The app only enables anonymised collection of IP addresses (so-called IP masking).
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Legal basis for the processing of personal data
                    </Typography>

                    <Typography>
                        The legal basis for data processing is your consent when installing the app regarding the use of web tracking (consent through clear confirmatory action or behaviour) in accordance with Art. 6 para. 1 lit. a GDPR.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Purpose of data processing
                    </Typography>

                    <Typography>
                        Google will use this information on our behalf to analyse your use of the app, to compile reports on activities and to provide us with further services associated with the use of the app and the Inter-net. We also require web tracking for security reasons. Web tracking allows us to track whether third parties are attacking the app. The information from the web tracker enables us to take effecti-ve countermeasures and protect your personal data processed by us from cyberattacks.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Duration of storage
                    </Typography>

                    <Typography>
                        Google will store the data relevant for the provision of web tracking for as long as it is necessary to fulfil the booked web service. Data collection and storage are anonymised. If there is any personal reference, the data will be deleted immediately, provided that it is not subject to any statutory re-tention obligations. In any case, the deletion takes place after expiry of the retention obligation.
                    </Typography>

                    <Typography sx={{
                        color: '#ffffff',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Objection and cancellation options
                    </Typography>

                    <Typography>
                        The rights to which you are entitled and how you can assert them can be found at the end of this statement. You can find Google's security and data protection principles at https://policies.google.com/privacy?hl=de.    
                    </Typography>

                </InfoContainer>



                <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        2.8. Access to functions of your end device
                </Typography>
                    In order to use all the functions of our app, the app requires access to certain functions of your end device on mobile devices:
                <Typography sx={{
                    color: '#ffffff',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    Biometric authentication (e.g. facial recognition / fingerprint):
                </Typography>

                <Typography>
                    If the system uses biometric authentication, the corresponding device sensors are used to make in-dividual functions of the APP available using an alternative authentication method.
                </Typography>

                <Typography sx={{
                    color: '#ffffff',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    Memory
                </Typography>

                <Typography>
                    This access is required so that the app can cache data offline on the end device. By using the me-mory, for example, the songs you have selected can also be listened to offline.    
                </Typography>

                <Typography sx={{
                    color: '#ffffff',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    Access to all networks
                </Typography>

                <Typography>
                    By accessing the networks, we can use an existing Internet connection (WLAN or mobile phone) to transfer data.    
                </Typography>

                <Typography sx={{
                    color: '#ffffff',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    Receive Internet data
                </Typography>

                <Typography>
                    An assigned ID is used here, which is assigned to the user device by the corresponding provider of the push service. It is used to send push messages to your end device, e.g. to display notifications.    
                </Typography>

                <Typography sx={{
                    color: '#ffffff',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    Location (localisation service)
                </Typography>

                <Typography>
                    By enabling the location function, the system's own services such as WLAN, GSM triangulation, GPS, Bluetooth and any other available sensors are used to determine the user's location. This is required to enable interaction with the map to be displayed in the neighbourhood
                </Typography>

                <Typography sx={{
                    color: '#ffffff',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    Notifications
                </Typography>

                <Typography>
                    By default, this function is responsible for displaying push messages and notifications on your home screen and lock screen. You can activate this the first time you start the app, and if you no longer wish to use it in future, you can adjust this at any time via the system settings.
                </Typography>



                <Typography sx={{
                    color: '#ffffff',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    What personal data do we process and what is it used for? 
                </Typography>

                <Typography>
                    We process all personal data collected from you in the app and aggregation data that we receive from your interactions with the app in order to provide you with the functions of the app.    
                </Typography>

                <Typography sx={{
                    color: '#ffffff',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    Legal basis for the processing of personal data
                </Typography>

                <Typography>
                    The use of the functions of storage, network access and the use of Internet data is based on Art. 6 para. 1 lit. b GDPR (fulfilment of contract). The functions are necessary so that you can provide the corres-ponding functions of the app in conjunction with the terms of use, which are available in the app and in our web interface.
                </Typography>

                <Typography sx={{
                    color: '#ffffff',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    How long will your data be stored?
                </Typography>

                <Typography>
                    Access to the respective device functions is required while using the app. If data is also collected, for example when exporting or downloading files to the storage space of your end device, these files are al-ways stored until you delete the files manually.
                </Typography>

                <Typography sx={{
                    color: '#ffffff',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    Possibility of objection and removal
                </Typography>

                <Typography>
                    You can revoke your consent at any time or object to the use of the authorisations by withdrawing the corresponding authorisations from the app in the app settings of your operating system. You can switch access to the permissions of your end device on or off in the "Settings" under "Apps" on most end de-vices. Please note that switching off access rights may have a negative impact on the functionality of the app. You can find out which rights you are entitled to and how to assert them at the end of this pri-vacy policy.
                </Typography>


                {/* section 3 */}

                <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        3. Section 3 - Your rights
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        3.1. Right to information
                    </Typography>

                    <Typography>
                        You can ask us to confirm whether we are processing your personal data. If this is the case, you have the right to obtain access to the information referred to in Article 15(1) and (2) of the General Data Pro-tection Regulation (GDPR). We will also be happy to provide you with a copy of the data, provided that this does not adversely affect the rights and freedoms of other persons (see Article 15(4) GDPR).
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        3.2. Right of rectification
                    </Typography>

                    <Typography>
                        In accordance with Article 16 of the General Data Protection Regulation (GDPR), you can request the correction of incorrectly stored personal data at any time. This applies, for example, to details such as your address or name. You also have the right to request an addition to your data stored by us. We will make the necessary changes immediately. As a registered customer, you also have the option of upda-ting certain data such as address, billing address and registration data directly in your user profile.
                    </Typography>


                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        3.3. Right to cancellation
                    </Typography>

                    <Typography>
                        In accordance with Art. 17 (1) GDPR, you have the right to demand that we erase the personal data we have collected about you if
                        <br /><br  />
                        •	The data is no longer required.<br />
                        •	The legal basis for the processing has lapsed due to the withdrawal of your consent.<br />
                        •	you have objected to the processing and there are no overriding legitimate grounds for the pro-cessing.<br />
                        •	your data is processed unlawfully.<br />
                        •	A legal obligation requires the deletion.<br />
                        •	A survey in accordance with Article 8 (1) GDPR has taken place.<br />

                        <br />
                        The right to erasure does not exist in accordance with Article 17(3) GDPR if:<br /><br />
                        •	Processing is necessary for exercising the right of freedom of expression and information.<br />
                        •	Your data was collected due to a legal obligation.<br />
                        •	Processing is necessary for reasons of public interest.<br />
                        •	The data is required for the establishment, exercise or defence of legal claims.


                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        3.4. Right to restriction of processing
                    </Typography>

                    <Typography>
                        In accordance with Article 18(1) of the General Data Protection Regulation (GDPR), you have the right to request the restriction of the processing of your personal data in certain situations. This applies, for example, if:<br /><br />
                        •	you dispute the accuracy of your personal data.<br />
                        •	The processing is unlawful and you do not consent to erasure.<br />
                        •	The data are no longer required for the purpose of processing, but are necessary for the estab-lishment, exercise or defence of legal claims.<br />
                        •	An objection to the processing has been lodged in accordance with Article 21(1) of the GDPR and it is still unclear which interests prevail.

                    </Typography>


                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        3.5. Right of cancellation
                    </Typography>
                    <Typography>
                        If you have expressly given us your consent to process your personal data in accordance with Article 6(1)(a) of the General Data Protection Regulation (GDPR), you can withdraw this consent at any time. Please note that the revocation does not affect the lawfulness of the processing that took place before the revocation on the basis of your consent.
                    </Typography>


                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        3.6. Right to object
                    </Typography>
                    <Typography>
                        In accordance with Article 21 of the General Data Protection Regulation (GDPR), you have the right to object at any time to the storage and processing of your data that we have collected on the basis of our legitimate interest in accordance with Article 6(1)(f) of the General Data Protec-tion Regulation (GDPR). This applies if you as an individual have a special situation.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        3.7. Right to data portability
                    </Typography>
                    <Typography>
                        Upon request, we will provide you or a person designated by you with certain data in a commonly used, machine-readable format in accordance with Article 20(1) of the General Data Protection Regulation (GDPR). This concerns:<br /><br />
                        •	Data that we have collected on the basis of your express consent in accordance with Article 6(1)(a) of the GDPR or Article 9(2)(a) of the GDPR.<br />
                        •	Data that we have received from you in the context of existing contracts on the basis of Article 6 (1) (b) GDPR, insofar as this data has been processed by automated means.<br />
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        3.8. How do you exercise your rights?
                    </Typography>
                    <Typography>
                        You can exercise your rights at any time by contacting us:<br />
                        FAIRMUSIC AG, Saarbrücken Local Court HRB 109272<br />
                        In der Dumpf 12, 66130 Saarbrücken, Germany<br />
                        E-mail: support@rokk.app<br />
                        Management Board: Peter Moog, Alexander Ladenburg 
                    </Typography>


                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        3.9. Right to lodge a complaint with the supervisory authority pursuant to Art. 77 para. 1 GDPR
                    </Typography>
                    <Typography>
                        If you think that we are processing your data unlawfully, you can always take legal action to have the issue resolved in court. You also have other legal options. In addition, you have the right to contact a su-pervisory authority in accordance with Article 77(1) of the General Data Protection Regulation (GDPR). You can choose the supervisory authority in the EU member state in which you live, work or where the alleged infringement took place. The authority to which you submit your complaint will then inform you of the progress and outcome of your enquiry, including the possibility of a legal appeal under Article 78 of the GDPR.
                        <br />For example, you can contact the supervisory authority responsible for us:
                        <br />Independent Data Protection Centre Saarland
                        <br />Fritz-Dobisch-Straße 12, 66111 Saarbrücken
                        <br />https://www.datenschutz.saarland.de/
                        <br />E-mail: poststelle@datenschutz.saarland.de 
                    </Typography>
                </div>



        </ContactInformationContainer >
    )
}

export default PrivacyPolicyPage