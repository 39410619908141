export const fireEffectOnMouseHover = () => {
  const plansContainer = document.querySelectorAll(".plans-card");
  const animatedBorders = document.querySelectorAll(
    ".plans-card .animated-border"
  );

  const starPlanBtn = document.querySelector(".activeCard .plans-button");

  plansContainer.forEach((card, index) => {
    const animatedBorder = card.querySelector(".animated-border");
    const associatedButton = card.querySelector(".plans-button");

    card.addEventListener("mouseover", () => {
      animatedBorders.forEach((border) => {
        border.style.display = "none";
      });
      animatedBorder.style.display = "block";
      starPlanBtn.style.background = "var(--light-gray)";
      associatedButton.style.background = "var(--primary)";
    });

    card.addEventListener("mouseout", () => {
      animatedBorder.style.display = "none";
      associatedButton.style.background = "var(--light-gray)";
      animatedBorders[0].style.display = "block";
      starPlanBtn.style.background = "var(--primary)";
    });
  });
};
