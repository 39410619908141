import React from 'react';

const AnimatedBorderSVG = () => (
    <filter id="animated-border-filter">
        <feTurbulence x="0" y="0" baseFrequency="0.009" numOctaves="5" seed="2">
            <animate attributeName="baseFrequency" dur="30s" values="0.02;0.005;0.02" repeatCount="indefinite" />
        </feTurbulence>
        <feDisplacementMap in="SourceGraphic" scale="30" />
    </filter>
);

export default AnimatedBorderSVG;