import { Box, Button, Grid, TextField } from '@mui/material'


const ContactForm = () => {
    return (
        <Box
            sx={{
                margin: 0,
                padding: '20px 0',
            }}
        >
            <form autoComplete='off'>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Name"
                            name="name"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ffffff20',
                                        borderRadius: 0,
                                        border: '2px solid rgba(255, 255, 255, 0.2)'
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ffffff20',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ff8303',
                                    },
                                    '& input': {
                                        color: '#ffffff',
                                        fontFamily: 'Poppins, sans-serif',
                                    },
                                },
                                '& label': {
                                    color: '#ffffff70',
                                    fontFamily: 'Poppins, sans-serif',
                                    textTransform: 'uppercase',
                                    '&.Mui-focused': {
                                        color: '#ff8303',
                                    },
                                }
                            }}
                            required
                        />


                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Email"
                            name="email"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ffffff20',
                                        borderRadius: 0,
                                        border: '2px solid rgba(255, 255, 255, 0.2)'
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ffffff20',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ff8303',
                                    },
                                    '& input': {
                                        color: '#ffffff',
                                        fontFamily: 'Poppins, sans-serif',
                                    },

                                },
                                '& label': {
                                    color: '#ffffff70',
                                    fontFamily: 'Poppins, sans-serif',
                                    textTransform: 'uppercase',
                                    '&.Mui-focused': {
                                        color: '#ff8303',
                                    },
                                }
                            }}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Subject"
                            name="subject"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ffffff20',
                                        borderRadius: 0,
                                        border: '2px solid rgba(255, 255, 255, 0.2)'
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ffffff20',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ff8303',
                                    },
                                    '& input': {
                                        color: '#ffffff',
                                        fontFamily: 'Poppins, sans-serif',
                                    },

                                },
                                '& label': {
                                    color: '#ffffff70',
                                    fontFamily: 'Poppins, sans-serif',
                                    textTransform: 'uppercase',
                                    '&.Mui-focused': {
                                        color: '#ff8303',
                                    },
                                }
                            }}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={10}
                            variant="outlined"
                            label="Message"
                            name="message"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ffffff20',
                                        borderRadius: 0,
                                        border: '2px solid rgba(255, 255, 255, 0.2)'
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ffffff20',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ff8303',
                                    },
                                    '& input': {
                                        color: '#ffffff',
                                    },

                                },
                                '& label': {
                                    color: '#ffffff70',
                                    fontFamily: 'Poppins, sans-serif',
                                    textTransform: 'uppercase',
                                    '&.Mui-focused': {
                                        color: '#ff8303',
                                    },
                                }
                            }}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            type='submit'
                            sx={{
                                backgroundColor: '#ff8303',
                                borderRadius: 0,
                                padding: '10px 60px',
                                color: '#ffffff',
                                fontFamily: 'Poppins, sans-serif',
                                fontWeight: 600,
                                '&:hover': {
                                    backgroundColor: '#ff8303',
                                },
                                '@media screen and (max-width: 680px)': {
                                    width: '100%'
                                }
                            }}
                        >
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default ContactForm