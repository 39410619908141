import React from "react"
import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Create a styled Accordion component
const StyledAccordion = styled(Accordion)({
    backgroundColor: 'transparent',
    color: 'var(--white)',
    '& .MuiAccordionSummary-root': {
        padding: 0,
    },
    '& .MuiAccordionDetails-root': {
        padding: 0,
    },
});

// Create a styled heading
const StyledHeading = styled.span({
    fontSize: '24px',
    color: 'var(--white)',
    margin: 0,
});

export const OthersSelector = ({ partyObj }: any) => {

    return (
        <StyledAccordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'var(--white)' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <StyledHeading className="head-text">I'M ANOTHER ONE OR NEW</StyledHeading>
            </AccordionSummary>
            <AccordionDetails>
                <div className="form-content">
                    <div className="inp-field-containers">
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">
                                Full Name
                            </label>
                            <input
                                type="search"
                                id="artist_name"
                                className="inp inp-artist-name"
                                placeholder='Full Name'
                                onChange={(e) => partyObj.setPartyName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="inp-wrapper">
                            <label htmlFor="artist_name" className="label_items">
                                Position
                            </label>
                            <input
                                type="search"
                                id="artist_name"
                                className="inp inp-artist-name"
                                placeholder='Position'
                                onChange={(e) => partyObj.setPartyPosition(e.target.value)}

                                required
                            />
                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </StyledAccordion>
    );
}
