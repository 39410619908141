import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logo, MenuIcon, CloseIcon } from "../assets";
import { useStateContext } from "../context/StateContextAPI";
import "./navbar.component.css";
import { Button } from "@mui/material";

const NavbarComponent = () => {
    const navigate = useNavigate();
    const [menuIconActive, setMenuIconActive] = useState(false);
    // const activePage = "home";
    // const activePage = "";
    const { activePage } = useStateContext();

    const handleClick = (url: string) => {
        navigate(`${url}`);
        setMenuIconActive(false);
    };

    const toggleMenu = () => {
        setMenuIconActive(!menuIconActive);
    };

    return (
        <div className="navbar-container">
            <div className="nav-logo">
                <img src={Logo} onClick={() => handleClick("/")} alt="rokk_app_logo" />
            </div>
            <div className="nav-links">
                <li
                    onClick={() => handleClick("/")}
                    id={`${activePage === "home" ? "activeLink" : ""}`}
                >
                    HOME
                </li>

                <li onClick={() => handleClick("/rokk")}
                    id={`${activePage === "rokk" ? "activeLink" : ""}`}>ROKK</li>
                <li onClick={() => handleClick("/pricing")}
                    id={`${activePage === "pricing" ? "activeLink" : ""}`}>PLANS</li>
                <li onClick={() => handleClick("/faq")}
                    id={`${activePage === "faq" ? "activeLink" : ""}`}>FAQ</li>
                <li onClick={() => handleClick("/artist")}
                    id={`${activePage === "artists" ? "activeLink" : ""}`}>FOR ARTISTS</li>
            </div>
            <div className="auth-section-nav-bar">
                {/* <button className="btn secondary-btn" onClick={() => navigate('/login')}>Login</button>
                <button className="btn primary-btn" onClick={() => navigate("../register-new-artist/")}>Create Account</button> */}
                <Button
                    variant="contained"
                    sx={{
                        background: 'var(--primary)',
                        color: '#fff',
                        height: '40px',
                        padding: '5px 20px',
                        fontWeight: 'bold',
                        '&:hover': {
                            background: 'var(--primary)'
                        }
                    }}
                    onClick={() => window.open("https://services.rokk-api.com", "_blank")}
                >
                    CREATE ACCOUNT
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        background: 'var(--primary)',
                        color: '#fff',
                        height: '40px',
                        padding: '5px 20px',
                        fontWeight: 'bold',
                        '&:hover': {
                            background: 'var(--primary)'
                        }
                    }}
                    onClick={() => handleClick("/login")}
                >
                    LOGIN
                </Button>
            </div>

            <div className="menu-icon" onClick={toggleMenu}>
                {menuIconActive ? (
                    <img src={CloseIcon} alt="close_icon" />
                ) : (
                    <img src={MenuIcon} alt="menu_icon" />
                )}
            </div>
            {menuIconActive && (
                <div className="sidebar-container">
                    <div className="menu-icon" onClick={toggleMenu}>
                        {menuIconActive ? (
                            <>
                                <img src={CloseIcon} alt="close_icon" />
                                Close</>
                        ) : (
                            <img src={MenuIcon} alt="menu_icon" />
                        )}
                    </div>
                    <div className="sidebar-links">
                        <li
                            onClick={() => handleClick("/")}
                            id={`${activePage === "home" ? "activeLink" : ""}`}
                        >
                            HOME
                        </li>

                        <li onClick={() => handleClick("/rokk")}
                            id={`${activePage === "rokk" ? "activeLink" : ""}`}>ROKK</li>
                        <li onClick={() => handleClick("/pricing")}
                            id={`${activePage === "pricing" ? "activeLink" : ""}`}>PLANS</li>
                        <li onClick={() => handleClick("/faq")}
                            id={`${activePage === "faq" ? "activeLink" : ""}`}>FAQ</li>
                        <li onClick={() => handleClick("/artist")}
                            id={`${activePage === "artists" ? "activeLink" : ""}`}>FOR ARTISTS</li>

                        <Button
                            variant="contained"
                            sx={{
                                background: 'var(--primary)',
                                color: '#fff',
                                height: '40px',
                                padding: '5px 20px',
                                fontWeight: 'bold',
                                '&:hover': {
                                    background: 'var(--primary)'
                                }
                            }}
                            onClick={() => handleClick("/login")}
                        >
                            LOGIN
                        </Button>

                        <Button
                            variant="contained"
                            sx={{
                                background: 'var(--primary)',
                                color: '#fff',
                                height: '40px',
                                padding: '5px 20px',
                                fontWeight: 'bold',
                                '&:hover': {
                                    background: 'var(--primary)'
                                }
                            }}
                            onClick={() => window.open("https://services.rokk-api.com", "_blank")}
                        >
                            CREATE ACCOUNT
                        </Button>
                    </div>

                </div>
            )}
        </div>
    );
};

export default NavbarComponent;
