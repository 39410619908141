import React from 'react';
import { Stepper, Step, StepLabel as Box, Divider, StepLabel } from '@mui/material';
import { useWindows } from '../../hooks/customHooks';
import { artistRegistrationSteps } from '../../utils/constants';
import { Header } from '../exports/band.registration.styles';

interface Props {
    activeStep: number;
}

const VerticalLinearStepper = ({ activeStep }: { activeStep: number }) => {
    const { windowWidth } = useWindows();

    return (
        <Box sx={{ padding: '30px 0' }}>
            <Stepper
                alternativeLabel={windowWidth < 700}
                activeStep={activeStep}
                orientation={windowWidth < 700 ? 'horizontal' : 'vertical'}
            >
                {artistRegistrationSteps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel
                            sx={{
                                '& .MuiStepLabel-label.Mui-disabled': {
                                    color: 'rgba(255, 255, 255, 0.2)'
                                },
                                '& .MuiStepLabel-label.Mui-active': {
                                    color: '#ff8303'
                                },
                                '& .MuiStepLabel-label.Mui-completed': {
                                    color: '#ff8303'
                                }
                            }}
                        >
                            <span>
                                {step.label}
                            </span>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>

    );
};

const FormStepper: React.FC<Props> = ({ activeStep }) => {
    return (
        <>
            <Header>
                <div className="head-text artist-page-head-text">
                    <span className="head-txt-off-white">BECOME A PART </span>
                    <br />
                    <span className="head-txt-off-white">OF THE ROKK </span>
                    <br />
                    <span className="head-txt-off-white">ARTIST FAMILY!</span>
                </div>

                <div>
                    <span>Sign up and become one of the first <span style={{ color: '#ff8303' }}>official Rokk <br />partner artists!</span> It’s free and only takes a few minutes.</span>
                </div>
                <br />

                <Divider style={{ backgroundColor: 'var(--light-gray)' }} />
                <VerticalLinearStepper activeStep={activeStep} />

            </Header>

        </>
    );
};

export default FormStepper;
