import React from 'react'
import PlansCard from './plans.component'
import { useStateContext } from '../context/StateContextAPI'
import { rokkFamily, rokkGoldPlan, rokkStarPlan } from "../components/exports/plans-exports.component";

import CurrencyData from "./exports/prices-exports.json";
import { fireEffectOnMouseHover } from './exports/plans-function-erxport';
import AnimatedBorderSVG from './AnimatedBorderSVG';

const PlansContainer = () => {

    const { plans, countryCode, prices, setPrices } = useStateContext();

    // const handleChange = (e: any) => {
    //     setCountryCode(() => e.target.value);
    // }

    React.useEffect(() => {
        let newPrices = CurrencyData.filter((item) => item.Code === countryCode);
        if (newPrices.length < 1) {
            newPrices = CurrencyData.filter((item) => item.Code === 'DE')
        }
        const removeCommas = (value: any) => value.replace(/,/g, ''); // Function to remove commas from the string
        setPrices((prevPrices: any) => ({
            ...prevPrices,
            currency: newPrices[0].Currency,
            star_monthly: newPrices[0].star_monthly,
            star_annual: (Number(removeCommas(newPrices[0].star_annual)) / 12).toFixed(2),
            star_student_monthly: newPrices[0].student_monthly,
            star_student_annual: (Number(removeCommas(newPrices[0].student_annual)) / 12).toFixed(2),
            hifi_monthly: newPrices[0].hifi_monthly,
            hifi_annual: (Number(removeCommas(newPrices[0].hifi_annual)) / 12).toFixed(2),
            hifi_student_monthly: (
                Number(removeCommas(newPrices[0].hifi_monthly)) -
                0.5 * Number(removeCommas(newPrices[0].hifi_monthly))
            ).toFixed(2),
            hifi_student_annual: (
                (Number(removeCommas(newPrices[0].hifi_annual)) - 0.5 * Number(removeCommas(newPrices[0].hifi_annual))) /
                12
            ).toFixed(2),
            family_monthly: newPrices[0].family_monthly,
            family_annual: (Number(removeCommas(newPrices[0].family_annual)) / 12).toFixed(2),
        }));

    }, [countryCode, setPrices]);


    const getStarPlansCard = () => {
        if (plans.includes("month") && plans.includes("student")) {
            return (
                <PlansCard cardContents={rokkStarPlan} type="hifi" currency={prices.currency} priceInfo={prices.star_student_monthly} />
            )
        } else if (plans.includes("annual") && plans.includes("student")) {
            return (
                <PlansCard cardContents={rokkStarPlan} type="hifi" currency={prices.currency} priceInfo={prices.star_student_annual} />
            )
        } else if (plans.includes("annual")) {
            return (
                <PlansCard cardContents={rokkStarPlan} type="hifi" currency={prices.currency} priceInfo={prices.star_annual} />
            )
        } else {
            return (
                <PlansCard cardContents={rokkStarPlan} type="hifi" currency={prices.currency} priceInfo={prices.star_monthly} />
            )
        }
    }

    const getGoldPlansCard = () => {
        if (plans.includes("month") && plans.includes("student")) {
            return (
                <PlansCard cardContents={rokkGoldPlan} type="hifipro" currency={prices.currency} priceInfo={prices.hifi_student_monthly} />
            )
        } else if (plans.includes("annual") && plans.includes("student")) {
            return (
                <PlansCard cardContents={rokkGoldPlan} type="hifipro" currency={prices.currency} priceInfo={prices.hifi_student_annual} />
            )
        } else if (plans.includes("annual")) {
            return (
                <PlansCard cardContents={rokkGoldPlan} type="hifipro" currency={prices.currency} priceInfo={prices.hifi_annual} />
            )
        } else {
            return (
                <PlansCard cardContents={rokkGoldPlan} type="hifipro" currency={prices.currency} priceInfo={prices.hifi_monthly} />
            )
        }
    }

    const getFamilyPlansCard = () => {
        if (plans.includes("annual")) {
            return (
                <PlansCard cardContents={rokkFamily} type="hififamily" currency={prices.currency} priceInfo={prices.family_annual} />
            )
        } else {
            return (
                <PlansCard cardContents={rokkFamily} type="hififamily" currency={prices.currency} priceInfo={prices.family_monthly} />
            )
        }
    }


    return (
        <>
            <div className="plans-cards-wrapper gp-20">
                <div className="plans-card" onMouseOver={fireEffectOnMouseHover}>
                    <div className="animated-border" />
                    <svg className="animated-border-svg">
                        <AnimatedBorderSVG />
                    </svg>
                    {getStarPlansCard()}

                </div>

                <div className="plans-card" onMouseOver={fireEffectOnMouseHover}>
                    <div className="animated-border" />
                    <svg className="animated-border-svg">
                        <AnimatedBorderSVG />
                    </svg>
                    {getGoldPlansCard()}
                </div>

                <div className="plans-card" onMouseOver={fireEffectOnMouseHover}>
                    <div className="animated-border" />
                    <svg className="animated-border-svg">
                        <AnimatedBorderSVG />
                    </svg>
                    {getFamilyPlansCard()}
                </div>
            </div>
        </>

    )
}

export default PlansContainer