type newsletterCard = {
    title: string,
    content: string
}

const favouriteBand: newsletterCard = {
    title: "Favourite Bands",
    content: "Your favourite bands in any imaginable genre + top-notch recommendations & playlists"
}
const allStyleMusic: newsletterCard = {
    title: "All Styles of Music",
    content: "Our focus is on Rock & Metal, but you can listen to any other style of music, too"
}
const hiResOfflineMode: newsletterCard = {
    title: "HiFi + Offline mode",
    content: "High quality audio and offline streaming"
}
const importFunction: newsletterCard = {
    title: "Import Function",
    content: "Easily import you favourite playlists from other streaming services"
}

export {
    favouriteBand,
    allStyleMusic,
    hiResOfflineMode,
    importFunction
}