import { useEffect, useState } from 'react'
import { countries } from '../countriesNames'
import styled from '@emotion/styled'
import { Alert, Box, Button, Collapse, FormControl, Stack, TextField } from '@mui/material';

const NewsletterFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 30%;
    font-family: "Poppins", sans-serif;
    color: var(--white)
`;


const NewsletterForm = () => {

    const [open, setOpen] = useState<boolean>(false);
    const [open1, setOpen1] = useState<boolean>(false);

    type countryObject = {
        name: string,
        code: string
    }[]

    const [firstOption, setFirstOption] = useState<String | undefined>("Afghanistan")
    const [location, setLocation] = useState("")

    const [subscriberName, setSubscriberName] = useState<String>("")
    const [subscriberEmail, setSubscriberEmail] = useState<String>("")


    const getUserLocation = async () => {
        try {
            fetch('https://ipapi.co/json/')
                .then(response => response.json())
                .then(response => {
                    setLocation(() => response.country)
                })
        }
        catch (err) {
            // console.log(err)
            return 'AF'
        }
    }

    useEffect(() => {
        const getLocation = async () => {
            await getUserLocation();
        }
        getLocation()
    }, [location])

    useEffect(() => {
        const newCountry = countries.find(country => country.code === location)
        setFirstOption(newCountry?.name);
    }, [location])

    const country: countryObject = countries.map((item) => item)


    // Call the async function to create a newsletter contact in the BREVO Account
    const handleNewsletterSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const [firstName, ...lastNameArr] = subscriberName.split(' ');
        const lastName = lastNameArr.join(' ');

        const data = {
            attributes: {
                FIRSTNAME: firstName,
                LASTNAME: lastName,
                COUNTRY: firstOption,
            },
            updateEnabled: false,
            email: subscriberEmail,
        };

        const url = 'https://api.brevo.com/v3/contacts';
        const apiKey = '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';

        const options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Api-Key': apiKey,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        try {
            const res = await fetch(url, options);
            if (res.ok) {
                // console.log('Contact submitted successfully');
                setOpen(true);
            } else {
                // console.error('Error submitting contact:', res.status);
                setOpen1(true);
            }
        } catch (error) {
            // console.error('Error submitting contact:', error);
            setOpen1(true)
        }
    };



    return (

        <NewsletterFormContainer>

            <FormControl
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                }}
                onSubmit={handleNewsletterSubmit}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    label="NAME"
                    name="name"
                    onChange={(event) => setSubscriberName(event.target.value)}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#ffffff80',
                                fontFamily: 'Poppins, sans-serif',
                            },
                            '&:hover fieldset': {
                                borderColor: '#ffffff80',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#ff8303',
                            },
                            '& input': {
                                color: '#ffffff',
                            },
                        },
                        '& label': {
                            color: '#ffffff80',
                            fontFamily: 'Poppins, sans-serif',
                            '&.Mui-focused': {
                                color: '#ff8303',
                            },
                        },
                        '& .MuiFormHelperText-root': {
                            color: '#ffffff',
                        },
                        '& input': {
                            color: '#ffffff',
                        },
                    }}
                    required
                />

                {/* Country Selector */}

                <TextField
                    id="filled-select-country"
                    select
                    label="COUNTRY"
                    defaultValue={"DE"}
                    SelectProps={{
                        native: true,
                    }}
                    variant="filled"
                    sx={{
                        backgroundColor: 'var(--light-gray)',
                        borderRadius: '5px',
                        color: 'var(--white)',
                        '& .MuiInputLabel-root': {
                            color: '#dfdfdf',
                            fontFamily: 'Poppins, sans-serif',
                        },
                        '& .MuiFilledInput-root': {
                            color: '#ff8303'

                        },
                        minWidth: '300px'
                    }}
                    required
                >


                    {country.map((countryName, index) => (
                        <option key={index} value={countryName.code}>{countryName.name}</option>
                    ))}
                </TextField>


                <TextField
                    fullWidth
                    variant="outlined"
                    label="EMAIL"
                    name="email"
                    type="email"
                    onChange={(event) => setSubscriberEmail(event.target.value)}
                    sx={{
                        background: 'var(--light-gray)',
                        fontFamily: 'Poppins, sans-serif',
                        borderRadius: '5px',
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: '#ffffff80',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#ff8303',
                            },
                            '& input': {
                                color: '#ffffff',
                                fontFamily: 'Poppins, sans-serif',
                            },
                        },
                        '& label': {
                            color: '#ffffff80',
                            '&.Mui-focused': {
                                color: '#ff8303',
                                fontFamily: 'Poppins, sans-serif',
                            },
                        },
                        '& .MuiFormHelperText-root': {
                            color: '#ffffff',
                        },
                        '& input': {
                            color: '#ffffff',
                            fontFamily: 'Poppins, sans-serif',
                        },
                    }}
                    required
                />

                <Button
                    fullWidth
                    type='submit'
                    sx={{
                        color: '#ffffff',
                        fontWeight: 'bold',
                        background: '#ff8303',
                        '&:hover': {
                            background: '#ff8303'
                        },
                        padding: '10px 20px'
                    }}>
                    SUBSCRIBE
                </Button>

            </FormControl>
            <Box>
                <Collapse in={open}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert onClose={() => { setOpen(false) }} severity="success">Subscribed!</Alert>
                    </Stack>
                </Collapse>
            </Box>

            <Box>
                <Collapse in={open1}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert onClose={() => { setOpen1(false) }} severity="error">Something went wrong</Alert>
                    </Stack>
                </Collapse>
            </Box>
        </NewsletterFormContainer>
    )
}

export default NewsletterForm