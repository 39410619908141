import { BandContainer, FormContainer } from '../../components/exports/band.registration.styles'
// import { useSessionStorage } from '../../hooks/useSessionStorage';
import { ArtistRegisterStep3, ArtistRegisterStep4, ArtistRegisterStep5, FormStepperParty, PartyRegisterStep1, PartyRegisterStep2 } from '../../components';
import { useState } from 'react';

const RegisterParty = () => {
    // const [activeStep, setActiveStep] = useSessionStorage<number>('activePartyStep', 0);
    const [activeStep, setActiveStep] = useState<number>(0)

    return (
        <BandContainer>
            <FormStepperParty activeStep={activeStep} />
            {/* The actual form goes here */}
            <FormContainer>
                {activeStep === 0 && <PartyRegisterStep1 activeStep={activeStep} setActiveStep={setActiveStep} />}
                {activeStep === 1 && <PartyRegisterStep2 activeStep={activeStep} setActiveStep={setActiveStep} />}
                {activeStep === 2 && <ArtistRegisterStep3 activeStep={activeStep} setActiveStep={setActiveStep} />}
                {activeStep === 3 && <ArtistRegisterStep4 activeStep={activeStep} setActiveStep={setActiveStep} />}
                {activeStep === 4 && <ArtistRegisterStep5 activeStep={activeStep} setActiveStep={setActiveStep} />}
            </FormContainer>
        </BandContainer>
    );
}

export default RegisterParty;
