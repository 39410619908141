import React from 'react'
import { CompactLogo } from '../assets'
import "./newslettercard.component.css"

const NewsletterCard = ({ obj }: any) => {
    return (
        <div className="newsletter-card">
            <img src={CompactLogo} alt="rokk_logo_compact" />
            <span className="newsletter-card-title">
                {obj.title}
            </span>
            <div className='newsletter-card-content'>
                {obj.content}
            </div>
        </div>
    )
}

export default NewsletterCard