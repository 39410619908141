import styled from "@emotion/styled";

export const ContactInformationContainer = styled.div`
    padding: 20px var(--global-padding);
    color: var(--white);
`

export const InfoContainer = styled.div`
    padding: 20px 0;
    p {
        font-family: 'Poppins', sans-serif;
        a {
            color: #ff8303;
        }
    }
`;