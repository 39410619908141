import React from 'react'
import { ArtistFormContainer, ComponentTitle, Image, SkeletonUI } from '../exports/band.registration.styles'
import { ArtistData, useStateContext } from '../../context/StateContextAPI';
import _ from 'lodash';
import { Badge, Box, Button, Typography } from '@mui/material';
import { Close, KeyboardArrowRight } from '@mui/icons-material';
import { BandArtistRokk } from '../../assets';
import { artist_data } from '../../utils/constants';

const ArtistRegisterStep1 = ({ activeStep, setActiveStep }: { activeStep: number, setActiveStep: (step: number) => void }) => {

    const { artistData, setArtistData, setRegistrationType } = useStateContext();

    React.useEffect(() => {
        setRegistrationType('Band')
    }, [])
    const [artistName, setArtistName] = React.useState("");

    const [isArtistSet, setIsArtistSet] = React.useState(false);

    const [artistDataFromAPI, setArtistDataFromAPI] = React.useState<ArtistData[]>()

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setArtistName((prev: string) => {
            return event.target.value;
        });
    }

    const handleArtistSelect = (artist: ArtistData) => {
        setArtistData(artist);
        // console.log(artistData)
    }

    const resetArtist = () => {
        setArtistData(undefined);
    }

    const ArtistSuggestions = () => {
        return (
            <>
                {
                    artistName.length > 0 && !artistDataFromAPI ?
                        SkeletonUI()
                        :
                        null
                }

                {
                    artistDataFromAPI && artistDataFromAPI.length > 0 ?
                        artistDataFromAPI.map((artist: ArtistData, _idx: number) => {
                            return (
                                <Box
                                    key={_idx}
                                    style={{
                                        background: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleArtistSelect(artist)}
                                >
                                    <div className="artist-data-main-section">
                                        <Image src={artist.photo_link} onError={(e: any) => (e.target as HTMLImageElement).src = BandArtistRokk} />
                                        <div>
                                            <Typography sx={{
                                                fontWeight: 500
                                            }}>
                                                {artist.artist_name}
                                            </Typography>
                                            <Typography sx={{
                                                color: 'var(--offWhite)',
                                                '@media screen and (max-width: 600px)': {
                                                    fontSize: '13px'
                                                }
                                            }}>
                                                {`${artist?.artist_genres?.slice(0,)}, ${artist?.country_short_name === 'XX' ? 'International' : artist?.country_short_name}, ${artist?.formed_in}`}

                                            </Typography>
                                        </div>

                                    </div>

                                    <KeyboardArrowRight sx={{ color: 'var(--light-gray)' }} />

                                </Box>
                            )
                        })
                        :
                        null
                }

                {
                    artistDataFromAPI && artistDataFromAPI.length < 1 ?
                        "No Data Found"
                        :
                        null
                }
            </>
        )
    }

    const ArtistDetails = () => {
        return (
            <Badge badgeContent={<Close sx={{ fontSize: 'small' }} />}
                color='primary'
                sx={{
                    width: '100%',
                    '& .MuiBadge-standard': {
                        background: '#ff8303',
                        padding: '2px 0'
                    }
                }}
                onClick={resetArtist}
            >
                <Box
                    width={'100%'}
                    style={{
                        background: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: '10px',
                        padding: '10px',
                        display: 'flex',
                        color: 'var(--white)',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flex: 1
                    }}
                >
                    <div className="artist-data-main-section">
                        <Image src={artistData?.photo_link} onError={(e: any) => (e.target as HTMLImageElement).src = BandArtistRokk} />
                        <div>
                            <Typography sx={{
                                fontWeight: 500
                            }}>
                                {artistData?.artist_name}
                            </Typography>
                            <Typography sx={{
                                color: 'var(--offWhite)',
                                '@media screen and (max-width: 600px)': {
                                    fontSize: '13px'
                                }
                            }}>
                                {`${artistData?.artist_genres}, ${artistData?.country_short_name === 'XX' ? 'International' : artistData?.country_short_name}, ${artistData?.formed_in}`}
                            </Typography>
                        </div>

                    </div>

                    <KeyboardArrowRight sx={{ color: 'var(--light-gray)' }} />

                </Box>
            </Badge>
        )
    }

    const debouncedFetchArtistData = React.useRef(_.debounce(async (searchName) => {
        if (searchName.length < 1) return;
        const options = {
            method: 'GET',
            headers: {
                accept: 'application/json'
            }
        }
        try {
            await fetch(`http://localhost:5009/artist?searchParam=${searchName}&limit=5`, options)
                .then(response => response.json())
                .then(res => {
                    if (res?.length > 0) {
                        setArtistDataFromAPI(() => res);
                    }
                });
        } catch (err) {
            console.log('%cNo data found', 'background: #ffffff; padding: 2px 5px; border-radius: 2px; color: black; font-weight: bold;')
        }
    }, 300));

    React.useEffect(() => {
        if (artistName.length < 1) {
            setArtistDataFromAPI(undefined);
        }
        debouncedFetchArtistData.current(artistName);
    }, [artistName]);

    React.useEffect(() => {
        setIsArtistSet(!isArtistSet)
    }, [artistData])

    React.useEffect(() => {
        setIsArtistSet(true);
    }, [setArtistData, handleArtistSelect])

    React.useEffect(() => {
        sessionStorage.setItem("RokkArtistData", JSON.stringify(artist_data));
    }, [])

    const handleNextStep = () => {
        setActiveStep(activeStep + 1);
    }

    return (
        <ArtistFormContainer>
            <ComponentTitle>
                BAND NAME
            </ComponentTitle>

            <label htmlFor="artist_name" className="label_items">Select Band and Yourself*</label>
            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='Band Name*' onChange={(e) => handleNameChange(e)} required />
            {isArtistSet && artistData && artistData.artist_id && artistData?.artist_id !== -1 &&
                <>
                    <Box
                        sx={{
                            background: 'rgba(255, 255, 255, 0.1)',
                            padding: '10px',
                            borderRadius: '10px',
                            cursor: 'pointer'
                        }}
                    >
                        <Typography sx={{ fontSize: '12px', fontWeight: 600, marginBottom: '10px', color: '#ff8303' }}>Selected Artist</Typography>
                        <ArtistDetails />
                    </Box>
                </>

            }
            <ArtistSuggestions />
            {isArtistSet && artistData && artistData.artist_id && artistData?.artist_id !== -1 &&
                <>
                    <Button
                        variant="contained"
                        style={{
                            background: '#ff8303',
                            color: '#000',
                            borderRadius: '30px',
                            height: '50px',
                            fontSize: '15px',
                            fontWeight: 600,
                            marginTop: '20px',
                            flex: 1
                        }}
                        onClick={handleNextStep}
                    >
                        CONTINUE
                    </Button>
                </>

            }

        </ArtistFormContainer>
    )
}

export default ArtistRegisterStep1