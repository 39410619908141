import React, { useEffect } from 'react'
import { NewsletterForm, PlansContainer } from '../../components'
import { useStateContext } from '../../context/StateContextAPI'

import "./pricing.page.css"
// import { Button, Container, Typography } from '@mui/material'

const PricingPage = () => {

    // const [activePage, setActivePage] = useStateContext();
    const { setActivePage } = useStateContext();

    useEffect(() => {
        setActivePage(() => "pricing")
        window.scrollTo(0, 0);
    }, [setActivePage]);


    return (
        <div className="pricing-page-container">

            <div className="pricing-section"
                style={{
                    marginTop: '100px'
                }}
            >
                <div className='pricing-section-header' style={{ textAlign: 'center' }}>
                    <span className="head-txt-primary">PLANS & PRICING</span>
                </div>
                <div className="flex flex-dir-col gp-20">
                    <span style={{ textAlign: 'center' }}><span style={{ opacity: 0.5 }}>Save even more on</span> annual plans <span style={{ opacity: 0.5 }}>& combine them with a</span> Student or Family discount</span>
                    {/* <ToggleButtonItems itemCentered={true} /> */}
                </div>

                <PlansContainer />
            </div>


            <div style={{ padding: 'var(--global-padding)' }}>
                <div className="newsletter-container-pricing-page flex aln-items-cnt">
                    <div className="newsletter-section-header flex flex-dir-col">
                        <span className="head-txt-off-white font-sz-small">SUBSCRIBE TO</span>
                        <span className="head-txt-off-white font-sz-small">OUR&nbsp;
                            <span className="head-txt-primary">ROKK</span>
                        </span>
                        <span className="head-txt-primary font-sz-small">NEWSLETTER</span>
                    </div>
                    <NewsletterForm />
                </div>
            </div>
        </div >
    )
}

export default PricingPage