export const steps = [
    {
        label: 'Artist Name'
    },
    {
        label: 'Artist Details'
    },
    {
        label: 'Terms of Service'
    },
    {
        label: `Done`
    }
];

export const partyRegistrationSteps = [
    {
        label: 'Party Name'
    },
    {
        label: 'Party Details'
    },
    {
        label: 'Login / Register'
    },
    {
        label: 'Terms of Service'
    },
    {
        label: 'Done'
    }
];


export const artistRegistrationSteps = [
    {
        label: 'Band Name'
    },
    {
        label: 'Artist Details'
    },
    {
        label: 'Login / Register'
    },
    {
        label: 'Terms of Service'
    },
    {
        label: 'Done'
    }
]

export const artist_data = {
    artist_id: -1,
    artist_name: "",
    country_short_name: "",
    formed_in: "",
    photo_link: "",
    artist_genres: ""
}

export const artist_instance = {
    type: "",
    artist_id: -1,
    artist_name: "",
    party_id: -1,
    party_name: "",
    email: "",
    passcode: -1,
    activated: 0
}

type Toast = {
    autoClose: number;
    hideProgressBar: boolean;
    closeOnClick: boolean;
    pauseOnHover: boolean;
    draggable: boolean;
    progress: undefined;
    style: {
        color: string;
        borderRadius: string;
    };
    closeButton: boolean
}

export const error_toast: Toast = {
    autoClose: 2000,       // Auto close after 5 seconds
    hideProgressBar: true, // Show progress bar
    closeOnClick: true,    // Close on click
    pauseOnHover: true,    // Pause on hover
    draggable: true,       // Allow dragging
    progress: undefined,   // Progress bar value
    style: {               // Custom styles
        color: 'red',
        borderRadius: "5px",  // Rounded corners
    },
    closeButton: false
}