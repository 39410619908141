import React, { useEffect } from 'react'
import { useStateContext } from '../../context/StateContextAPI'
import styled from '@emotion/styled';
import { CompactLogo, Logo, RokkApp } from '../../assets';
import { Container, Typography } from '@mui/material';
import { rokkContent } from '../../components/exports/rokk-content-export.component';

const RokkPageContainer = styled.div`
    padding: 50px var(--global-padding);
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`;

const LogoImage = styled.img`
    width: 150px;
`;

const RokkContentContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px 0;
`;

const AppImage = styled.img`
    width: 100%;
    max-width: 250px;
`;

const Content = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

const RokkCompactLogo = styled.img`
    height: 20px;
    // align-self: flex-start;
`;

const RokkPage = () => {
    const { setActivePage } = useStateContext();

    useEffect(() => {
        setActivePage(() => "rokk");
        window.scrollTo(0, 0);
    }, [setActivePage])


    return (
        <>
            <RokkPageContainer>
                <LogoImage src={Logo} />
                <Typography
                    sx={{
                        color: 'var(--light-gray)',
                        textAlign: 'center'
                    }}
                >
                    Rokk is a music streaming service, made especially for metal and rock fans
                </Typography>

                <RokkContentContainer>
                    <AppImage src={RokkApp} />
                    <Container
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            width: '100%'
                        }}
                        style={{
                            maxWidth: '650px'
                        }}
                    >
                        {rokkContent.map((cont: any, _idx: number) => {
                            return (
                                <Content key={_idx}>
                                    <RokkCompactLogo src={CompactLogo} />
                                    <Typography
                                        sx={{
                                            fontSize: '18px',
                                            // marginTop: '-5px',
                                            color: 'var(--offWhite)'
                                        }}
                                    >{cont}</Typography>
                                </Content>
                            )
                        })}
                        {/* <Container
                        sx={{
                            display: 'flex',
                            gap: '20px'
                        }}
                    >
                        <DownloadImage src={GooglePlayCard} />

                        <DownloadImage src={AppleStoreCard} />
                    </Container> */}
                    </Container>

                </RokkContentContainer>

            </RokkPageContainer>
        </>
    )
}

export default RokkPage