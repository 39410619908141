import { Box, Button, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { AES, enc } from 'crypto-js';
import { Close } from '@mui/icons-material';

type UserDTO = {
    name: string,
    email: string,
    expiryTime: number
}

const CancelSubscriptionForm = () => {
    const [isConsentGiven, setIsConsentGiven] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false);
    const [userData, setUserData] = useState<UserDTO>(
        {
            name: '',
            email: '',
            expiryTime: 0
        }
    )
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userDto = searchParams.get('u_dt');

    const [encryptedString, setEncryptedString] = useState<string>('')

    const navigate = useNavigate();

    useEffect(() => {
        if (encryptedString) {
            try {
                const decryptedData = AES.decrypt(encryptedString, 'ROKK_UDATA_123!#')
                const decryptedString = decryptedData.toString(enc.Utf8)
                const parsedData = JSON.parse(decryptedString);
                setUserData(parsedData)
            } catch (error) {
                console.error('Error parsing decrypted data:', error);
            }
        }
    }, [encryptedString])

    useEffect(() => {
        if (userDto) {
          setEncryptedString(userDto);
        }
      }, [userDto]);

    const consentTrigger = (e: any) => {
        e.preventDefault();
        e.target.value === 'yes' ? setIsConsentGiven(true) : setIsConsentGiven(false)
    }

    useEffect(() => {
        if (userData.expiryTime > 0) {
          const now = Date.now(); // Current time in milliseconds
          const tenMinutesInMs = 10 * 60 * 1000; // 10 minutes in milliseconds
      
          // Adjust current time to CET by adding the CET offset in milliseconds (UTC+1 or UTC+2 for DST)
          const cetOffset = new Date().getTimezoneOffset() * 60000; // Offset in milliseconds
          const cetTimeNow = now + cetOffset;
      
          if (userData.expiryTime - cetTimeNow > tenMinutesInMs) {
            setIsSessionExpired(false);
          } else {
            setIsSessionExpired(true);
          }
        }
      }, [userData]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setIsSubmitted(true)
    }

    const handleClose = () => {
        setIsSubmitted(false);
    }

    const handleSessionExpired = () => {
        navigate("../")
    }
    return (
        <Box
            sx={{
                margin: 0,
            }}
        >
            <FormControl>
                <RadioGroup
                    aria-labelledby="delete-account-consent"
                    defaultValue="no"
                    name="yes-no-button-group"
                >
                    <Box sx={{display: 'flex'}}>
                    <FormControlLabel value="yes" control={<Radio sx={{color: '#ff8303', '&.Mui-checked': {color: '#ff8303'}}} onChange={(e) => consentTrigger(e)} />} label="YES" />
                    <FormControlLabel value="no" control={<Radio sx={{color: '#ff8303', '&.Mui-checked': {color: '#ff8303'}}} onChange={(e) => consentTrigger(e)} />} label="NO" />
                    </Box>
                </RadioGroup>
            </FormControl>
            <form autoComplete='off' onSubmit={(e) => handleSubmit(e)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled
                            fullWidth
                            variant="outlined"
                            label="NAME"
                            name="name"
                            value={userData.name}
                            sx={{
                                color: '#ffffff', // Black text color
                                '& .MuiOutlinedInput-root.Mui-disabled': {
                                  '& fieldset': {
                                    border: '2px solid rgba(255, 255, 255, 0.2)',
                                    borderRadius: '0'
                                  },
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                  WebkitTextFillColor: '#ffffff50', // Ensure text is black
                                  cursor: 'not-allowed', // Default arrow cursor
                                  fontFamily:'Poppins, sans-serif',
                                },
                                '& .MuiInputLabel-root.Mui-disabled': {
                                    color: 'rgba(255, 255, 255, 0.2)', // Label color in disabled state
                                    fontFamily:'Poppins, sans-serif',
                                },
                              }}
                            // required
                        />


                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled
                            fullWidth
                            variant="outlined"
                            label="EMAIL"
                            name="email"
                            value={userData.email}
                            sx={{
                                color: '#ffffff', // Black text color
                                '& .MuiOutlinedInput-root.Mui-disabled': {
                                  '& fieldset': {
                                    border: '2px solid rgba(255, 255, 255, 0.2)',
                                    borderRadius: '0'
                                  },
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                  WebkitTextFillColor: '#ffffff50', // Ensure text is black
                                  cursor: 'not-allowed', // Default arrow cursor
                                  fontFamily:'Poppins, sans-serif',
                                },
                                '& .MuiInputLabel-root.Mui-disabled': {
                                    color: 'rgba(255, 255, 255, 0.2)', // Label color in disabled state
                                    fontFamily:'Poppins, sans-serif',
                                },
                              }}
                            // disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={10}
                            variant="outlined"
                            label="Reason (optional)"
                            name="Reason"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: '#ffffff20',
                                    borderRadius: 0,
                                    border: '2px solid rgba(255, 255, 255, 0.2)',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: '#ffffff20',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#ff8303',
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: '#ffffff',
                                  fontFamily:'Poppins, sans-serif',
                                  '&.Mui-focused': {
                                    color: '#ff8303'
                                  },
                                },
                                '& label': {
                                  color: '#ffffff70',
                                  fontFamily: 'Poppins, sans-serif',
                                  textTransform: 'uppercase',
                                  '&.Mui-focused': {
                                    color: '#ff8303',
                                  },
                                },
                              }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            type='submit'
                            sx={{
                                backgroundColor: '#ff8303',
                                borderRadius: 0,
                                padding: '10px 60px',
                                color: '#ffffff',
                                fontFamily: 'Poppins, sans-serif',
                                fontWeight: 600,
                                '&.Mui-disabled':{
                                    backgroundColor: '#EEEEEE20',
                                    color: '#ffffff20',
                                },
                                '&:hover': {
                                    backgroundColor: '#ff8303',
                                },
                                '@media screen and (max-width: 680px)': {
                                    width: '100%'
                                }
                            }}
                            disabled={!isConsentGiven}
                        >
                            CANCEL SUBSCRIPTION
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <Modal
                open={isSubmitted}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '400px',
                        minWidth: '200px',
                        background: '#111111',
                        padding: '24px'
                    }}
                >
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography id="modal-modal-title" sx={{
                            fontFamily: 'Poppins, sans-serif',
                            fontSize: '16px'
                        }}>
                            ERROR
                        </Typography>
                        <Close fontSize='small' sx={{color: '#ff8303', cursor: 'pointer'}} onClick={ handleClose} />
                    </Box>
                    <Typography id="modal-modal-description"sx={{
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: '12px',
                        marginTop: '12px'
                    }}>
                        We couldn’t cancel your subscription as <span style={{color: '#ff8303'}}>test only</span> accounts don’t have an actual valid subscription.
                    </Typography>
                </Box>
                </Modal>

                <Modal
                    open={isSessionExpired}
                    onClose={handleSessionExpired}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '400px',
                            minWidth: '300px',
                            background: '#111111',
                            padding: '24px'
                        }}
                    >
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Typography id="modal-modal-title" sx={{
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: '16px'
                            }}>
                                SESSION EXPIRED
                            </Typography>
                            <Close fontSize='small' sx={{color: '#ff8303', cursor: 'pointer'}} onClick={ handleSessionExpired } />
                        </Box>
                        <Typography id="modal-modal-description"sx={{
                            fontFamily: 'Poppins, sans-serif',
                            fontSize: '12px',
                            marginTop: '12px'
                        }}>
                            Your current <span style={{color: '#ff8303'}}>session</span> is expired.
                        </Typography>
                    </Box>
                </Modal>
        </Box>
    )
}

export default CancelSubscriptionForm