import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react'
import { useStateContext } from '../context/StateContextAPI';
import styled from '@emotion/styled';

type ToggleBtn = {
    itemCentered: boolean
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#111111',
        color: 'rgba(255, 255, 255, 1)',
        maxWidth: 220,
        fontSize: '12px',
        border: '1px solid #1a1a1a',
    },
}));

const ToggleButtonItems: React.FC<ToggleBtn> = ({ itemCentered }) => {

    const { plans, setPlans } = useStateContext();

    const handlePlans = (
        event: React.MouseEvent<HTMLElement>,
        newPlan: string[],
    ) => {
        let updatedPlans = [...newPlan];

        if (newPlan.includes('month') && plans.includes('annual')) {
            // Remove "month" from plans if "annual" is selected
            updatedPlans = updatedPlans.filter((plan) => plan === 'month');
        } else if (newPlan.includes('annual') && plans.includes('month')) {
            // Remove "annual" from plans if "month" is selected
            updatedPlans = updatedPlans.filter((plan) => plan === 'annual');
        }
        else {

        }

        if (newPlan.includes('student') && plans.includes('family')) {
            // Remove "month" from plans if "annual" is selected
            updatedPlans = updatedPlans.filter((plan) => plan === 'family');
        } else if (newPlan.includes('family') && plans.includes('student')) {
            // Remove "annual" from plans if "month" is selected
            updatedPlans = updatedPlans.filter((plan) => plan === 'family');
        }

        if (updatedPlans.length > 0 && (updatedPlans.includes('month') || updatedPlans.includes('annual')))
            setPlans(updatedPlans);
    };


    return (
        <ToggleButtonGroup
            value={plans}
            onChange={handlePlans}
            aria-label="Plans"
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: itemCentered ? "center" : "",
            }}
        >
            <ToggleButton
                value="month"
                aria-label="month"
                style={{
                    color: plans.includes('month') ? '#000000' : '#ffffff',
                    backgroundColor: plans.includes('month') ? '#ff8303' : 'transparent',
                    border: '0.1px solid #ffffff20',
                    borderRight: 'none',
                    height: '40px'
                }}
            >
                Monthly
            </ToggleButton>
            <ToggleButton
                value="annual"
                aria-label="annual"
                style={{
                    color: plans.includes('annual') ? '#000000' : '#ffffff',
                    backgroundColor: plans.includes('annual') ? '#ff8303' : 'transparent',
                    border: '0.1px solid #ffffff20',
                    borderRight: 'none',
                    height: '40px'
                }}
            >
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit" sx={{
                                color: '#ff8303',
                                fontWeight: 'bold'
                            }}>Annual Plans</Typography>
                            <span style={{ color: '#ff8303' }}>{"-16.6667%"}</span>{' on all plans if you choose annual payment'}
                        </React.Fragment>
                    }
                    followCursor
                >
                    <p>Annual</p>
                </HtmlTooltip>

            </ToggleButton>
            <ToggleButton
                value="student"
                aria-label="student"
                style={{
                    color: plans.includes('student') ? '#000000' : '#ffffff',
                    backgroundColor: plans.includes('student') ? '#ff8303' : 'transparent',
                    border: '0.1px solid #ffffff20',
                    borderRight: 'none',
                    height: '40px'
                }}
            >
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit" sx={{
                                color: '#ff8303',
                                fontWeight: 'bold'
                            }}>Student Plans</Typography>
                            <span style={{ color: '#ff8303' }}>{"-50%"}</span>{' on all plans if you are a student'}
                        </React.Fragment>
                    }
                    followCursor
                >
                    <p>Student</p>
                </HtmlTooltip>
            </ToggleButton>
            <ToggleButton
                value="family"
                aria-label="family"
                style={{
                    color: plans.includes('family') ? '#000000' : '#ffffff',
                    backgroundColor: plans.includes('family') ? '#ff8303' : 'transparent',
                    border: '0.1px solid #ffffff20',
                    height: '40px'
                }}
            >
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit" sx={{
                                color: '#ff8303',
                                fontWeight: 'bold'
                            }}>Family Plans</Typography>
                            {'Only €16,99 for '}<span style={{ color: '#ff8303' }}>{"5 ACCOUNTS"}</span>{' in your family'}
                        </React.Fragment>
                    }
                    followCursor
                >
                    <p>Family</p>
                </HtmlTooltip>
            </ToggleButton>
        </ToggleButtonGroup>

    )
}

export default ToggleButtonItems