import { useEffect } from 'react'
import { ArtistAlbumBG, ArtistPageRegisterAsArtistImg, ArtistPageRegisterAsBandImg, MainBackgroundVector } from '../../assets'
import { NewsletterCard, NewsletterForm } from '../../components'
import { useStateContext } from '../../context/StateContextAPI'

import "./artist.page.css"
import { useNavigate } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { BackgroundImage} from '../faq/faq.page'

const ArtistPageContainer = styled.div`
    width: 100%;
`

const HeaderText = styled.div`
    font-size: 72px;
    flex: 1;
    margin: 0;
    font-family: var(--title-font), sans-serif;
    line-height: 1em;
    letter-spacing: 1px;
    min-width: 300px;
    @media screen and (max-width: 1400px) {
        font-size: 64px;
    }
    @media screen and (max-width: 1200px) {
        font-size: 48px;
    }
    @media screen and (max-width: 880px) {
        font-size: 40px;
    }
    @media screen and (max-width: 680px) {
        font-size: 64px;
    }
`

const ArtistPageHeader = styled.div`
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    padding: 0 var(--global-padding);
    margin: 50px 0;
`

const RegistrationCards = styled.div`
    display: flex;
    flex: 1;
    min-width: 300px;
    aspect-ratio: 2/2.5;
    background: rgba(255, 255, 255, 0.2);
    @media screen and (max-width: 1400px) {
        aspect-ratio: 2/3;
    }
    @media screen and (max-width: 1200px) {
        aspect-ratio: 2/4;
    }
    @media screen and (max-width: 1000px) {
        aspect-ratio: 2/3.5;
    }
    @media screen and (max-width: 680px) {
        aspect-ratio: 2/2.7;
    }
    @media screen and (max-width: 480px) {
        aspect-ratio: 2/3.5;
    }
`

const RegistrationCardsContent = styled.div`
    background: #000;
    background: linear-gradient(180deg, rgba(17, 17, 17, 0.00) 0%, rgba(16, 16, 16, 0.73) 13%, #101010 24.84%);
    height: 50%;
    width: 100%;
    align-self: flex-end;
    box-sizing: border-box;
    padding: 50px;
    @media screen and (max-width: 1000px) {
        padding: 20px;
    }
    @media screen and (max-width: 880px) {
        height: 60%;
    }
`


const ArtistPage = () => {

    const { setActivePage } = useStateContext();

    useEffect(() => {
        setActivePage(() => "artists")
        window.scrollTo(0, 0);
    }, [setActivePage])

    type newsletterCard = {
        title: string,
        content: string
    }

    const favouriteBand: newsletterCard = {
        title: "Fair and transparent royalties",
        content: ""
    }
    const allStyleMusic: newsletterCard = {
        title: "More $ per stream",
        content: ""
    }
    const hiResOfflineMode: newsletterCard = {
        title: "Up to 10% direct artist support",
        content: ""
    }
    const importFunction: newsletterCard = {
        title: "Finally a platform that cares about you and your music",
        content: ""
    }


    return (
        <ArtistPageContainer>
            <BackgroundImage src={MainBackgroundVector} />
            <ArtistPageHeader>
            <RegistrationCards
                style={{
                    background: `url(${ArtistPageRegisterAsBandImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center'
                }}
            >
                  <RegistrationCardsContent>
                    <HeaderText>
                        <div>
                            <span className="head-txt-off-white">REGISTER AS</span>
                        </div>
                        <div>
                            <span className="head-txt-primary">BAND</span>
                        </div>
                    </HeaderText>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: '#ffffff80',
                            fontFamily: 'Poppins, sans-serif',
                            margin: '20px 0',
                            '@media screen and (max-width: 680px)': {
                                fontSize: '14px'
                            }
                        }}
                    >
                    Connect to your profile here if you manage a band or are authorised to represent the official profile. See your statistics, edit your profile and receive direct artist support.
                    </Typography>
                    <Button
                            variant="contained"
                            color="primary"
                            type='submit'
                            sx={{
                                backgroundColor: '#ff8303',
                                borderRadius: 0,
                                padding: '10px 60px',
                                color: '#ffffff',
                                fontFamily: 'Poppins, sans-serif',
                                fontWeight: 600,
                                '&:hover': {
                                    backgroundColor: '#ff8303',
                                },
                                '@media screen and (max-width: 680px)': {
                                    width: '100%'
                                }
                            }}
                            onClick={() => window.location.href="https://rokk.app/register-new-artist/"}
                        >
                            REGISTER AS A BAND
                        </Button>
                    </RegistrationCardsContent>      
            </RegistrationCards>

            <RegistrationCards
                style={{
                    background: `url(${ArtistPageRegisterAsArtistImg})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            >
                  <RegistrationCardsContent>
                    <HeaderText>
                        <div>
                            <span className="head-txt-off-white">REGISTER AS</span>
                        </div>
                        <div>
                            <span className="head-txt-primary">ARTIST</span>
                        </div>
                    </HeaderText>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: '#ffffff80',
                            fontFamily: 'Poppins, sans-serif',
                            margin: '20px 0',
                            '@media screen and (max-width: 680px)': {
                                fontSize: '14px'
                            }
                        }}
                    >
                    Connect to your profile here if you are a solo artist or a member of one or more bands. View your statistics, edit your profile and receive direct artist support.
                    </Typography>
                    <Button
                            variant="contained"
                            color="primary"
                            type='submit'
                            sx={{
                                backgroundColor: '#ff8303',
                                borderRadius: 0,
                                padding: '10px 60px',
                                color: '#ffffff',
                                fontFamily: 'Poppins, sans-serif',
                                fontWeight: 600,
                                '&:hover': {
                                    backgroundColor: '#ff8303',
                                },
                                '@media screen and (max-width: 680px)': {
                                    width: '100%'
                                }
                            }}
                            onClick={() => window.location.href="https://rokk.app/register-new-artist/"}
                        >
                            REGISTER AS AN ARTIST
                        </Button>
                    </RegistrationCardsContent>      
            </RegistrationCards>


            </ArtistPageHeader>
            <div className="artists-benefits-cards-wrapper">
                <div className="artists-benefits-card">FAIR & TRANSPARENT<div>ROYALITIES</div></div>
                <div className="artists-benefits-card">DIRECT FAN TO<div>ARTIST SUPPORT</div></div>
                <div className="artists-benefits-card">YOUR MUSIC. YOUR FANS.<div>YOUR TEAM. ALL IN ONE.</div></div>
            </div>
            <div className="artist-page-main-sec">
                <div className="artist-page-main-sec-img-container">
                    <img src={ArtistAlbumBG} alt="artist_album_main_image" />
                </div>
                <div className="artist-page-content">
                    <div className="head-text artist-page-head-text">
                        <div>
                            <span className="head-txt-off-white">ROCK & METAL&nbsp;</span>
                            <span className="head-txt-primary">ARTISTS</span>
                        </div>
                        <div>
                            <span className="head-txt-off-white">FINALLY HAVE A &nbsp;</span>
                            <span className="head-txt-primary">STREAMING</span>
                        </div>
                        <div>
                            <span className="head-txt-primary">PLATFORM &nbsp;</span>
                            <span className="head-txt-off-white">AND SERVICE</span>
                        </div>
                    </div>

                    <div className="head-desc-main">
                        <span>
                            The first genre specific streaming service that makes higher royalty
                        </span>
                        <span>
                            payments and direct fan to artist support their number one priority.
                        </span>
                    </div>

                    <div className="auth-section">
                        {/* <button className="btn primary-btn" style={{ width: '200px' }} onClick={() => navigate('../register-new-artist')}>Get Started for Free</button> */}
                        <Button
                            variant="contained"
                            sx={{
                                background: 'var(--primary)',
                                color: '#000',
                                height: '50px',
                                padding: '5px 20px',
                                fontWeight: 'bold',
                                '&:hover': {
                                    background: '#ff8303'
                                }

                            }}
                            // onClick={() => navigate('../register-new-artist')}
                            onClick={() => window.location.href = "https://rokk.app/register-new-artist/"}
                        >
                            Get Started for Free
                        </Button>
                    </div>
                </div>

            </div>

            <div className="newsletter-cards-container" style={{ padding: 'var(--global-padding)' }}>
                <NewsletterCard obj={favouriteBand} />
                <NewsletterCard obj={allStyleMusic} />
                <NewsletterCard obj={hiResOfflineMode} />
                <NewsletterCard obj={importFunction} />
            </div>


            <div style={{ padding: 'var(--global-padding)' }}>
                <div className="newsletter-container-pricing-page flex aln-items-cnt">
                    <div className="newsletter-section-header flex flex-dir-col">
                        <span className="head-txt-off-white font-sz-small">SUBSCRIBE TO</span>
                        <span className="head-txt-off-white font-sz-small">OUR&nbsp;
                            <span className="head-txt-primary">ROKK</span>
                        </span>
                        <span className="head-txt-primary font-sz-small">NEWSLETTER</span>
                    </div>
                    <NewsletterForm />
                </div>
            </div>
        </ArtistPageContainer>
    )
}

export default ArtistPage