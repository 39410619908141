
type planTypes = {
    title: string,
    desc: string,
    price: string | number,
    benefits: String[]
}


const freePlan: planTypes = {
    title: "FREE 1 MONTH TRIAL",
    desc: '',
    price: "0,00",
    benefits: [
        "The whole world of Rock & Metal",
        "Over 200 subgenres",
        "Plus every other style of Music from Pop - Jazz",
        "No ads",
        "Great audio quality",
        "Detailed information on all bands & artists",
    ]
}

const rokkStarPlan: planTypes = {
    title: "ROKK HIFI",
    desc: 'The most popular choice',
    price: "10,99",
    benefits: [
        "All of ROCK & METAL",
        "Including every other style of Music",
        "<span style='color: #ff8303'>HiFi Sound Quality</span>",
        "Background information on all artists",
        "Up to 10% <span style='color: #ff8303'>Direct Artist Support</span>",
        "More money for bands and artists"
    ]
}


const rokkGoldPlan: planTypes = {
    title: "ROKK HIFI PRO",
    desc: 'The best choice to support artists!',
    price: "19,99",
    benefits: [
        "All of ROCK & METAL",
        "Including every other style of Music",
        "<span style='color: #ff8303'>HiFi Sound Quality, including FLAC</span>",
        "Background information on all artists",
        "80% more <span style='color: #ff8303'>Direct Artist Support</span>",
        "Double the money for bands and artists"
    ]
}



const rokkFamily: planTypes = {
    title: "ROKK HIFI FAMILY",
    desc: 'The choice for ROKKING families!',
    price: "16,99",
    benefits: [
        "All of ROCK & METAL",
        "Including every other style of Music",
        "<span style='color: #ff8303'>HiFi Sound Quality</span>",
        "Background information on all artists",
        "More money for bands and artists"
    ]
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'var(--black)',
    borderRaius: '5px',
    outline: 'none',
    boxShadow: 24,
    '@media (max-width: 600px)': {
        width: '80%'
    }
};

export {
    freePlan,
    rokkStarPlan,
    rokkGoldPlan,
    rokkFamily,
    style
}