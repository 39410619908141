import React, { useEffect } from 'react'
import { ContactInformationContainer, InfoContainer } from '../components/exports/company-info-styles.export'
import { Typography } from '@mui/material'
import { useStateContext } from '../context/StateContextAPI';

const CompanyInformationPage = () => {

    const { setActivePage } = useStateContext();

    useEffect(() => {
        setActivePage(() => "")
        window.scrollTo(0, 0);
    }, [setActivePage])

    return (
        <ContactInformationContainer>
            {/* <div className="rokk-faq-container"> */}
            <div className='rokk-users-faq-sec'>
                <div className="faq-section-head-text">COMPANY INFORMATION</div>
                <InfoContainer>
                    <Typography>FAIRMUSIC AG</Typography>
                    <Typography>
                        In der Dumpf 12
                    </Typography>
                    <Typography>
                        66130 Saarbrücken
                    </Typography>
                    <Typography>
                        Germany
                    </Typography>
                    <br />
                    <Typography>
                        vertreten durch die Peter Moog (CEO), Alex Landenburg (COO)
                    </Typography>
                    <Typography>

                        Vorsitzender des Aufsichtsrats: Jan Broer
                    </Typography>
                    <br />
                    <Typography>
                        Gerichtsstand: Amtsgericht Saarbrücken, HRB 109272
                        Umsatzsteuer-ID: DE349314586
                    </Typography>
                </InfoContainer>

                <br />

                <div className="faq-section-head-text">DATA PROTECTION</div>
                <InfoContainer>
                    <Typography sx={{
                        color: 'var(--primary)',
                        marginBottom: '10px',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Information on the collection of personal data:
                    </Typography>
                    <Typography>
                        In the following, we provide information on the collection and, if necessary, further processing (storage, etc.) of personal data. Personal data is individual information about personal or factual circumstances with which a reference to a specific or identifiable natural person can be established, such as name, age, marital status, date of birth, postal address, telephone number, e-mail address or account number. Sometimes even the IP address is considered to be a personal data. The responsible party pursuant to Art. 4 (7) of the EU General Data Protection Regulation (DSGVO) is FAIRMUSIC AG (for contact details, see our imprint).
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Collection and further processing of personal data as well as type and purpose of processing:
                    </Typography>
                    <Typography>
                        In the following, we inform you in which cases we collect personal data and for which purposes. In principle, there is no legal or contractual obligation to provide your data; however, within the framework of the contractual relationship (e.g. in the case of an order), it may be necessary to process certain data for the purpose of providing our services or due to legal obligations (e.g. GWG); we will point this out if necessary. Processing of personal data when visiting our website
                        When you use our website for information purposes only, we collect the personal data that your browser transmits to our server. If you wish to view our website, we collect the following data on the basis of legitimate interests to the extent that it is technically absolutely necessary for us to display our website to you and to ensure its stability and security (legal basis is Art. 6 para. 1 sentence 1 lit. f DSGVO): IP address, date and time of the request, time zone difference to Greenwich Mean Time (GMT), content of the request (specific page), access status/HTTP status code, amount of data transferred in each case, website from which the request comes, browser, operating system and its interface, language and version of the browser software. The data is stored in log files and is not merged with other data. The data is usually deleted after one month at the latest.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Cookies
                    </Typography>
                    <Typography>
                        Cookies are stored on your computer when you use our website. Cookies are small files that are stored on your hard drive and assigned to the browser you are using, and which provide the party setting the cookie (in this case, us) with certain information. Cookies are used to make the website as a whole more user-friendly and effective. We use cookies that are technically necessary for the operation of the website. The purpose is to simplify the use of websites for users. Some functions of our website cannot be offered without the use of cookies. For these, it is necessary that the browser is recognised again even after a page change (the legal basis is Art. 6 para. 1 lit. f DSGVO).
                        Cookies are stored on the user's computer and transmitted to us by the user. Therefore, you as a user also have full control over the use of cookies. By changing the settings in your internet browser, you can deactivate or restrict the transmission of cookies. Cookies that have already been stored can be deleted at any time. This can also be done automatically. If cookies are deactivated for our website, it may no longer be possible to use all the functions of the website to their full extent. Session cookies are automatically deleted at the end of your visit. If we use cookies in addition, we will inform you about this at the appropriate place in this data protection declaration.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Contact
                    </Typography>
                    <Typography>
                        In the event of questions of any kind or complaints, we offer you the possibility of contacting us via a form provided on the website. You can also contact us by e-mail or telephone.
                    </Typography>
                    <Typography>
                        If you contact us via the contact form, the following data entered in the input mask will be transmitted to us and stored: Name, e-mail address, your message. Further details can be entered voluntarily.
                    </Typography>
                    <Typography>
                        The processing of the personal data transmitted in the context of contacting us and provided by you in this respect serves to establish contact quickly and thus to process your enquiry quickly. This enables us to provide good customer service (legal basis is Art. 6 para. 1 lit. f DSGVO or your consent, Art. 6 para. 1 lit. a DSGVO). If your contact aims at the conclusion of a contract or the obligations in connection with a contract, the additional legal basis for the processing is Art. 6 para. 1 lit. b DSGVO.
                    </Typography>
                    <Typography>
                        The personal data collected by us for contact enquiries will be automatically deleted once your enquiry has been dealt with, unless legitimate interests require us to store it for a longer period (e.g. because we have announced further enquiries etc.). If your enquiry leads to the conclusion of a contract, your data will be deleted immediately after expiry of the statutory retention periods (10-year retention period based on tax regulations with regard to all data relevant to the tax case or 6-year retention period based on commercial and professional regulations) or after expiry of limitation periods.
                    </Typography>

                </InfoContainer>

                <div className="faq-section-head-text mt-10">OTHER PROCESSING AND DISCLOSURE OF DATA</div>
                <InfoContainer>
                    <Typography sx={{
                        color: 'var(--primary)',
                        marginBottom: '10px',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Beyond the cases mentioned in this data protection declaration, we only process your data if:
                    </Typography>

                    <Typography>
                        you have consented to this in accordance with Art. 6 Para. 1 lit. a DSGVO,
                        this is necessary in accordance with Art. 6 Para. 1 lit. b DSGVO for the processing of contractual relationships with you (e.g. transfer to service providers required for the execution of the contract, in particular the post office, authorities, payment service providers such as PayPal, tax consultants etc.),
                        there is a legal obligation for the processing according to Art. 6 para. 1 p. 1 lit. c DSGVO (in particular tax and commercial law obligations to keep records and to transmit data), as well as
                        the processing is necessary pursuant to Art. 6 (1) sentence 1 lit. f DSGVO to protect the legitimate interests of us or third parties, in particular the assertion, exercise or defence of legal claims (e.g. disclosure to courts, debt collection, lawyers), unless your interests or fundamental rights and freedoms which require the protection of personal data override these.
                    </Typography>
                    <br />
                    <Typography>
                        Recipients of the data may also be service providers on whose services we rely for the operation of our company (e.g. technical service providers for the maintenance of the software and IT landscape) and for the fulfilment of our contractual obligations and who process the data exclusively in accordance with instructions; such service providers act for us within the framework of order processing.
                    </Typography>


                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Deletion of data
                    </Typography>

                    <Typography>
                        Unless otherwise stated in this declaration, personal data will be deleted immediately after the purpose for which it was collected has ceased to exist and provided that no retention periods (e.g. 10-year retention period based on tax law regulations with regard to all data relevant to the tax case or 6-year retention period based on commercial and professional law regulations) or, for example, limitation periods for data that may be useful for legal prosecution conflict with this.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Your rights
                    </Typography>

                    <Typography>
                        With regard to the personal data concerning you, you have the right to information, if necessary correction or deletion, if necessary restriction of processing, objection to processing (see below in more detail) and data portability.
                    </Typography>
                    <Typography>
                        You also have the right to complain to a data protection supervisory authority.
                    </Typography>
                    <Typography>
                        You have the right to revoke a declaration of consent under data protection law at any time with effect for the future.
                    </Typography>
                    <Typography>
                        Right of objection pursuant to Art. 21 DSGVO If your personal data are processed on the basis of legitimate interests pursuant to Art. 6 (1) lit. f DSGVO, you have the right to object to the processing of your personal data with effect for the future pursuant to Art. 21 DSGVO, insofar as there are grounds for doing so that arise from your particular situation or the objection is directed against direct advertising. In the latter case, you have a general right of objection, which will be implemented by us without specifying a particular situation. If you would like to make use of your right of revocation or objection, it is sufficient to send us an e-mail.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Notes on e-mail communication
                    </Typography>

                    <Typography>
                        E-mail communication with us is unencrypted by default. If you write us an unencrypted e-mail or make an enquiry and ask for a reply by regular e-mail, we initially assume that you agree to unencrypted communication. If you wish to use encrypted communication, please let us know. We would like to point out that certain technical requirements on your part must be met in this respect (e.g. implementation of encryption software in your mail programme or use of an appropriate service provider). Upon request, we can also send you certain documents, e.g. as password-protected PDF files or .zip containers; please check your spam folder in this respect, as such attachments are often classified as unsolicited messages.
                    </Typography>

                    <Typography sx={{
                        color: 'var(--primary)',
                        margin: '10px 0',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>
                        Registration for our newsletter
                    </Typography>

                    <Typography>
                        If you have expressly consented in accordance with Art. 6 Para. 1 S. 1 lit. a DSGVO, we will use your e-mail address to send you our newsletter on a regular basis. To receive the newsletter, it is sufficient to provide an e-mail address.
                    </Typography>

                    <Typography>

                        You can unsubscribe at any time, for example via a link at the end of each newsletter. Alternatively, you can also send your unsubscribe request at any time by e-mail to the e-mail address given in our imprint.
                    </Typography>


                </InfoContainer>

            </div>



        </ContactInformationContainer >
    )
}

export default CompanyInformationPage