import React, { useEffect } from 'react'
import { useStateContext } from '../context/StateContextAPI';
import { ContactInformationContainer, InfoContainer } from '../components/exports/company-info-styles.export';
import { Typography } from '@mui/material';

const TermsOfUse = () => {
    const { setActivePage } = useStateContext();

    useEffect(() => {
        setActivePage(() => "")
        window.scrollTo(0, 0);
    }, [setActivePage])

  return (
    <ContactInformationContainer>
        <div className='rokk-users-faq-sec'>
            <div className="faq-section-head-text mt-10">GENERAL TERMS OF USE</div>
            <InfoContainer>
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    1. General information
                </Typography>

                <Typography>
                The following terms of use ("General Terms of Use") apply to contracts that you conclude with us as an end user in relation to the services offered by us. An end consumer in this sense is any natural person who concludes a legal transaction for purposes that cannot be attributed primarily to their commercial or independent professional activity.     
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    a. Introduction and contractual basis
                </Typography>
                
                <Typography>
                    The following Terms of Use govern the access and use of our services, in particular the streaming services relating to music and other media content as well as the access and use of the associated websites, software applications and apps (hereinafter referred to as "Services") and the access and use of music, video content, podcasts and other content and media made available through our Services (hereinafter referred to as "Content"). 
                </Typography>
                <br />
                <Typography>
                    In addition to these General Terms of Use, further additional terms of use apply to the respective subscription tariff you have booked, such as the additional terms of use for the respective tariffs, e.g. the "ROKK HIFI", "ROKK HIFI PRO", ROKK HIFI FAMILY" tariffs and for the "Student Discounts", as well as any additional terms of use for "Add-On's" and our usage guidelines. 
                </Typography>
                <br />
                <Typography>
                    The General Terms of Use, together with the respective tariff-related additional terms of use and our usage guidelines, form the contractual basis between you as the customer and us as the provider. Please read them carefully.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    b. Provider
                </Typography>
                <Typography>
                    provider of the services and benefits:    
                </Typography>
                <br />
                <Typography>
                    FAIRMUSIC AG<br />
                    In der Dumpf 12<br />
                    66130 Saarbrücken<br /><br />
                    Chairman of the Management Board: 
                </Typography>

                <Typography>
                    Sales tax ID: DE349314586<br />
                    Register court: Saarbrücken Local Court<br />
                    Commercial register number: HRB 107766<br />
                    Management Board members: Peter Moog, Alex Landenburg<br />
                    Chairman of the Supervisory Board: Jan Broer
                </Typography>


                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    c. Customer registration, creating a customer account and handling login information
                </Typography>
                <Typography>
                    In order to use our services, it is usually necessary to create a customer account. This is done by providing a user name and password as well as an e-mail address and, if necessary, other data (such as payment information, etc.). By registering a customer account and providing your data, you assure us that the information you provide is truthful and complete. Should your data change in the future, you are obliged to make these changes in your customer account under "Settings" and to check that your details are up-to-date and correct and to adjust them if necessary. 
                </Typography>
                <br />
                <Typography>
                    Your login details (user name and password) are for your personal use only. Please treat this information confidentially. If you suspect that your login information has been stolen or misused by a third party or that a third party has gained unauthorized access to your account, please notify us immediately. We also recommend that you change your password in such a case. The same applies if you lose your login details.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    d. Entry and age requirements
                </Typography>
                <Typography>
                    Our services are only available to private individuals (end users). In addition, it is only permitted to book a tariff that is offered in the country in which you have your general place of residence.
                </Typography>
                <br />
                <Typography>
                    To create a customer account and to book a subscription and other services offered by us, you must be of legal age and not be prevented from concluding legally binding contracts due to legal provisions or official or sovereign measures.
                </Typography>
                <br />
                <Typography>
                    If you are at least 16 years old but not yet of legal age, you can also create a customer account and conclude a contract with us if the above-mentioned requirements are met and you can also provide us with suitable proof of consent from your legal representatives (e.g. parents or guardian).
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    e. Formation of the contract
                </Typography>
                <Typography>
                    The contract between you and us is concluded online via our website. By placing offers or various subscription tariffs on our website, we submit to you a binding offer to conclude a corresponding subscription contract under the conditions specified for the respective subscription tariff and subject to these General Terms of Use and our Terms of Use Guidelines. 
                </Typography>
                <br />
                <Typography>
                    The contract is concluded when you select a specific subscription tariff and order it via our online ordering system by selecting or specifying one of the available payment methods. You will either be taken to an order overview page on our website as part of our ordering system or redirected to the website of a provider of the payment system you have selected. In the event that you are redirected to the respective provider of the payment system, you will enter your data there and the order data will be displayed in an order overview before the final order is placed or you will be redirected back to our store system and the order data will be displayed there as an order overview. 
                </Typography>
                <br />
                <Typography>
                    In any case, you have the option of checking or changing the details in the order overview again and canceling the order before completing the order. Only by clicking on the corresponding order button (button labeled "start subscription” do you make a legally binding order or booking of the selected subscription tariff, whereby the contract is concluded on the basis of the offer and the General Terms of Use, the tariff-related additional terms of use and the terms of use guidelines. 
                </Typography>
                <br />
                <Typography>
                    After placing your order, you will receive an order confirmation order confirmation to the e-mail address provided during the order or payment process, which contains the information required by law and the terms and conditions of use applicable to the booked tariff, including the terms and conditions of use. We do not store the full text of the contract. 
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    2. Changes and adjustments to the contractual basis and right of termination
                </Typography>

                <Typography>
                    a. We reserve the right to amend the contractual basis in the future. The prerequisite is that there are legitimate reasons for this, e.g. if the functionality of our services is changed or expanded or if technical innovations are implemented or if this is necessary to ensure the functionality or security of our services (e.g. averting danger, defending against hacker attacks, combating abuse, etc.) or if legal or statutory obligations or changes to the law have to be implemented.
                </Typography>
                <br />
                <Typography>
                    b. If we make changes to the contractual bases that affect the contractual relationship between you and us, we will inform you in advance in an appropriate manner, e.g. by sending you an e-mail or by displaying a corresponding message within our services, which you can then confirm there or where you can then agree to the changes there. In the course of this, we will inform you about the planned changes and about the consequences for you if you reject or agree to the changes. 
                </Typography>
                <br />
                <Typography>
                    c. In principle, you have the right to reject the changes or terminate the contract in the event of future changes to the contractual basis. If you do not reject the changes within one month, they shall be deemed to have been accepted. Changes to the contractual basis that we make in this way are generally only those that do not have a significant impact on the originally contractually agreed balance of the services provided by us and the consideration or fees you pay for them. If you do not wish to agree to the changes to the contractual basis and/or no longer wish to use our services on the basis of the changed contractual basis, you have the right to terminate the contract concluded with us. You can send us your notice of termination by e-mail or use the termination button provided for this purpose in your user account under "Settings".
                </Typography>
                <br />
                <Typography>
                    d. We will also inform you in advance of any significant changes to the contractual basis and obtain your consent. If you do not agree, the contract will be continued under the existing conditions. It can then be terminated by us subject to the applicable notice periods.
                </Typography>
                <br />
                <Typography>
                    e. In addition, we and you have the statutory right to terminate the contract for good cause, provided that the requirements for this are met. The statutory provisions shall apply. 
                </Typography>
                <br />
                <Typography>
                    f. Our aim is to provide artists with appropriate remuneration. If we determine that the behavior of individual users counteracts this or allows conclusions to be drawn about manipulation or unauthorized use, we reserve the right to terminate the contractual relationship with immediate effect and/or to block the user.
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    3. Tariffs, subscription models and use of our services
                </Typography>

                <Typography>
                    We offer our services in various versions and tariffs. Certain services are offered free of charge (so-called trial subscriptions). Other services are subject to a charge and can only be used for a fee. In the case of fee-based tariffs, payment must always be made in advance in order to be able to use the respective service. We expressly reserve the right to offer further tariffs and subscription models as well as special offers and additional services, e.g. free additional features or paid features such as so-called "in-app purchases", in the future. 
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    a. Free subscriptions (trial subscription)
                </Typography>
                <Typography>
                    To try out our services, we offer users a one-off free trial subscription. The duration is limited to one month. Insofar as additional terms of use apply to the free trial subscription, you will be informed of this when you take out the trial subscription. 
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    b. Paid subscriptions
                </Typography>
                <Typography>
                    We offer various fee-based subscription models, e.g. a special family tariff "ROKK HIFI FAMILY" for up to 4 users (2 adult family members and 2 minor family members) as well as discounts for students and people who can prove that they are in vocational training or studying. We reserve the right to adjust, cancel or add to the tariffs and different tariff models. You will receive the additional terms of use applicable to the specific tariff when you take out one of these tariff subscriptions. 
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    c. Applications, apps, devices, updates and compatibility 
                </Typography>
                
                <Typography>
                    We offer our services both via our dedicated website at the URL: www.rokk-app.com and via an app that can be installed on mobile devices (smartphones). A desktop computer, laptop or tablet is required to use our website, as well as a web browser installed on it and Internet access. Our mobile app is available for the Android and iOS (Apple) operating systems and requires a suitable device with a current version of one of these operating systems and Internet access.     
                </Typography>
                
                <br />
                <Typography>
                    You must ensure that the digital environment (hardware, software, network connections of all kinds, etc.) required by you to access and use our services is available and up to date.
                </Typography>

                <br />
                <Typography>
                    Insofar as third-party applications (e.g. web browsers) or special devices (e.g. smartphones) are required to use our services, separate provisions and terms of use may apply to the use of these third-party applications and devices, which you may have to observe separately.
                </Typography>

                <br />
                <Typography>
                    During the agreed provision period, we will, if necessary, provide functional updates to our services or apps so that the functionality and compatibility of our services and apps are fulfilled as intended during the provision period and suitability for normal use is ensured during the provision period. We cannot guarantee unlimited compatibility of third-party applications and devices or the operating systems installed on the devices and our services. 
                </Typography>

                <br />
                <Typography>
                    You are responsible for the functionality, up-to-dateness and compatibility of the third-party applications and devices you use.     
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    4. Booking, renewing and canceling subscriptions
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    a. Subscription booking 
                </Typography>
                <Typography>
                    You can purchase a subscription directly via our services, e.g. via our website. The respective subscription fee and the scope of the service can be found in the offer. In addition, the relevant tariff-related, additional terms of use apply to the respective subscription.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    b. Renewal of paid subscriptions
                </Typography>
                <Typography>
                    Paid subscriptions run for the contractually agreed period. If the subscription period ends, paid subscriptions are automatically extended indefinitely unless you cancel them in good time.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    c. Cancellation period for paid subscriptions 
                </Typography>
                
                <Typography>
                    Subscriptions that have been concluded for a fixed subscription period can be canceled with 14 days' notice to the end of the subscription period. This does not apply to fee-based subscriptions that have been extended after expiry of the agreed fixed subscription period. These can then be terminated monthly with a notice period of 14 days to the end of the calendar month.     
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    d. Cancellation of fee-based subscriptions
                </Typography>
                
                <Typography>
                    You can cancel your paid subscription by clicking here and confirming the cancellation on the following page. You can also cancel your subscription in your profile under "Settings" by clicking on the "Cancel subscription" button.    
                </Typography>
                <br />                
                <Typography>
                    The timely termination of a paid subscription becomes effective at the end of the subscription period.    
                </Typography>
                <br />                
                <Typography>
                    Cancellation of a paid subscription that has been renewed after expiry of the agreed fixed subscription period will take effect at the end of the calendar month in which you have canceled, provided that you have observed the notice period of 14 days to the end of the calendar month. If you have not complied with the notice period of 14 days to the end of the calendar month, the termination will not take effect until the end of the following calendar month.    
                </Typography>
                <br />                
                <Typography>
                    FAIRMUSIC shall offer its services as is and subject to availability. Statutory rights of termination and any right to terminate the contract if the service is not provided shall remain unaffected by this.    
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    e. Cancellation and notice period for free subscriptions
                </Typography>
                
                <Typography>
                    Free text subscriptions can be canceled at any time during the free trial period. Cancellation will take effect immediately. You can cancel your free subscription by clicking here and confirming the cancellation on the following page. Cancellation is also possible in your profile under "Settings", there under the "Cancel subscription" button.    
                </Typography>
                <br />                
                <Typography>
                    Statutory rights of termination and any right to terminate the contract in the event of non-provision remain unaffected by this.    
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    f. Automatic conversion of free subscriptions into paid subscriptions
                </Typography>
                
                <Typography>
                    Free subscriptions automatically convert to a paid subscription if they are not canceled during the free trial period. If you do not cancel the trial subscription before the end of the trial period, a paid subscription will automatically follow the end of the trial period. paid subscription , to which the cancellation rules for paid subscriptions then apply.    
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    g. Statutory right of termination for good cause
                </Typography>
                
                <Typography>
                    The statutory right to extraordinary termination for good cause remains unaffected by the above provisions and is governed by the statutory provisions.    
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    5. Billing and price changes
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    a. Billing and payment 
                </Typography>
                <Typography>
                    Paid subscriptions are billed monthly in advance by charging the monthly subscription fee, unless otherwise agreed. You gain access to our services for the relevant period by paying the subscription fee due for this period in advance.
                </Typography>
                <br />
                <Typography>
                    Subscription fees can also be agreed in advance for other time periods and periods (e.g. for a whole year) if the respective subscription tariff provides for this. The billing and payment modalities applicable to your subscription tariff can be found in the respective offer and, if applicable, in the additional terms of use for the respective tariff.    
                </Typography>
                <br />
                <Typography>
                    Payment claims arising from the concluded contract are due for payment immediately for the agreed subscription period or, if a monthly payment method has been agreed, are due monthly in advance. In the event of late payment, we are entitled to block access to our services.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    b. Billing for trial subscriptions at the end of the trial month
                </Typography>
                <Typography>
                    If you have registered for a free trial subscription, you can cancel this subscription with immediate effect at any time during the trial subscription period without giving reasons. If you cancel within the trial subscription period, you will not be charged any subscription fees. At the end of the trial subscription period, your trial period ends and with it access to our services and the option to cancel the subscription with immediate effect. 
                </Typography>
                <br />
                <Typography>
                    By registering for a trial subscription, you agree that the free trial subscription will be converted into a paid subscription at the end of the trial subscription period if you do not cancel the trial subscription during the trial subscription period. If you do not cancel the trial subscription before the end of the trial subscription period, a paid subscription will automatically follow the end of the trial subscription period, to which the cancellation rules for paid subscriptions apply. 
                </Typography>
                <br />
                <Typography>
                    If a free trial subscription converts to a paid subscription, we are entitled to invoice the subscription fee underlying the respective subscription rate on a monthly basis in advance. 
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    c. Price adjustments and special right of termination
                </Typography>
                
                <Typography>
                    The subscription fees and other prices may be adjusted by us in an appropriate manner, for example to compensate for reduced or increased overall costs in connection with the provision of our services. We may increase subscription fees or prices to cover ongoing costs. When calculating our total costs, the following costs, for example, are relevant: production and licensing costs for the content we offer, general costs such as energy costs, distribution costs, advertising costs, personnel costs, rental costs, costs for external service providers and financing costs as well as the costs of maintaining and operating the IT infrastructure required for the provision of our services and finally investments, taxes, fees and charges (including levies to collecting societies). In addition, we are also entitled to adjust subscription fees and prices accordingly in the event that the statutory value added tax changes. We will only carry out price adjustments in such a way that a maximum increase of 20% takes place within one year. Price adjustments will be announced by us and you will be notified of price changes in good time. Price changes shall enter into force at the earliest one month after we have informed you and shall then apply from the start of the following billing period. 
                </Typography>
                <br />
                <Typography>
                    In the event of price increases, you are entitled to terminate the contract (special right of termination). The termination requires that you have previously given us the opportunity to reverse the price increase and continue to perform the contract with you at the previous price level and that we have not made 
                </Typography>
                <br />
                <Typography>
                    This does not affect your other contractual or statutory rights of termination or any right to terminate the contract in the event of non-provision 
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    6. Right of withdrawal and exclusion of the right of withdrawal
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    margin: '10px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    If you have not effectively waived your right of withdrawal or the right of withdrawal is not excluded, you have the following statutory right of withdrawal as follows:
                </Typography>

            </InfoContainer>

            <InfoContainer>
                <div className="faq-section-head-text mt-10">CANCELLATION POLICY</div>
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    Right of withdrawal
                </Typography>

                <Typography>
                    You have the right to withdraw from this contract within 14 days without giving any reason (right of withdrawal). The withdrawal period is 14 days from the date of conclusion of the contract. 
                </Typography>
                <br />
                <Typography>
                    To exercise the right to cancel, you must inform us (FAIRMUSIC AG, In der Dumpf 12, 66130 Saarbrücken, Germany, , support@rokk.app) of your decision to cancel this contract by a clear statement (e.g. a letter sent by post or e-mail). You may use the attached model withdrawal form, but this is not obligatory. You can also fill out the sample withdrawal form or another clear declaration electronically on our website and send it to us. 
                </Typography>
                <br />
                <Typography>
                    If you make use of this option, we will send you a confirmation of receipt of such a revocation without delay (e.g. by e-mail).
                </Typography>
                <br />
                <Typography>
                    To meet the withdrawal deadline, it is sufficient for you to send your notification of exercising your right of withdrawal before the withdrawal period has expired.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    Sample withdrawal form
                </Typography>

                <Typography>
                    {"("}If you wish to withdraw from the contract, please fill out this form and send it back to: FAIRMUSIC AG, In der Dumpf 12, 66130 Saarbrücken, Germany, , support@rokk.app{")"}
                </Typography>
                    <i>
                    <br />
                    I/we (*) hereby revoke the contract concluded by me/us (*) for the provision of the following service
                    <br />
                    Ordered on (*) / received on (*):<br />
                    Name of the consumer(s):<br />
                    Address of the consumer(s):<br />
                    Signature of the consumer(s) (only for notification on paper)<br />

                    Date<br />

                    (*) Delete as appropriate
                    <br />
                    </i>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    Expiry of the right of withdrawal
                </Typography>

                <Typography>
                    In the case of free subscriptions, the right of withdrawal expires when we have provided our service in full, i.e. at the end of the free subscription.
                </Typography>
                <br />
                <Typography>
                    In the case of fee-based subscriptions, the right of withdrawal expires when the service has been provided in full if 
                </Typography>
                
                <ul>
                    <li>
                        <Typography>
                            you have expressly consented to us commencing the provision of our services before the expiry of the withdrawal period and have provided us with this consent on a durable medium, and
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            you have confirmed your knowledge that your right of withdrawal expires upon complete fulfillment of the contract by us.
                        </Typography>
                    </li>
                </ul>

                <br />
                <Typography>
                    If the service owed is commenced by us within the revocation period, but not fully provided, the consumer's right of revocation does not expire. Instead, you owe us compensation for the value of the service provided up to the time of revocation if 
                </Typography>
                
                <ul>
                    <li>
                        <Typography>
                        	you have expressly agreed before the start of the provision of the service that we start providing our service before the end of the withdrawal period and 
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	we have informed you that you owe a reasonable amount for the service provided by us if you exercise your right of withdrawal and the service has already begun before you exercise your right of withdrawal.
                        </Typography>
                    </li>
                </ul>

                <br />
                <Typography>
                    You must then pay us an amount that corresponds proportionately to what we have provided up to the time at which you informed us of the exercise of the right of withdrawal compared to the total scope of the contractually agreed services. The amount (compensation for the value of the parts of the service provided up to the time of revocation) shall be calculated pro rata temporis according to the period of use.
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    7. Rights to content and right to access and use the content (right of access)
                </Typography>

                <Typography>
                    a. All rights to the services provided by us and the content provided via these services are owned by us or our contractual partners or licensors. Our services and the use of our services and content provided by us is only permitted during a valid subscription and only to the extent specified in these terms of use and in our usage guidelines. 
                </Typography>
                <br />
                <Typography>
                    b. As a user of our services, you are granted a limited, non-exclusive and revocable right to use our services and the content made available via our services for your personal, non-commercial, non-business and non-commercial use (hereinafter referred to as "access right"). This access right only entitles you to use our services and the content for your own private purposes or the private purposes of the co-users covered by the respective subscription tariff. The right of access exists for the duration of the subscription term, provided that access to our services and/or content is not terminated, revoked or blocked by us for other reasons. 
                </Typography>
                <br />
                <Typography>
                    c. The use of our services and/or the content made available via our services for commercial, business and entrepreneurial or other non-private purposes as well as the public reproduction or public performance of the content available via our services is expressly prohibited. The right of access also expressly does not include the transfer of access data to third parties or the provision of our services and content to third parties, in particular if this is done by circumventing the functions provided for this purpose (e.g. share button). The right of access also expressly does not include the non-private duplication, public distribution, performance, presentation, broadcasting, making available to the public and making available to the public of content or excerpts of content made available via our services, unless these are legally permitted uses. 
                </Typography>
                <br />
                <Typography>
                    d. If our services, software components provided by us or so-called apps or content provided by us are installed or stored on your end devices, the rights to them remain with us or our contractual partners and licensors.
                </Typography>
                <br />
                <Typography>
                    e. When a subscription ends or the contract is terminated, you can no longer use our services and the content made available via our services. In the event of misuse or violations of our user guidelines, for example, we may also temporarily or permanently block your access to our services and content and their use during the term of the contract. 
                </Typography>
                <br />
                <Typography>
                    f. You expressly agree to the aforementioned provisions and warrant that you will use our services and the content available via our services only for your own personal and not for commercial, industrial, business or other purposes and that you will not publicly reproduce or publicly perform the content available via our services and that you will not transfer the content and access to it to any third party. 
                </Typography>
                <br />
                <Typography>
                    g. Otherwise, our usage guidelines apply. You undertake to comply with our usage guidelines and not to use our services and the content provided by us (even in part) in any way that is not permitted under these general terms of use and the usage guidelines.
                </Typography>
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    h. "Explicit content" (inappropriate content): 
                </Typography>
                <Typography>
                    Music and content may be inappropriate in terms of language and lyrics, for example. As a rule, this information is provided to us by those who supply us with the content by means of a so-called "e-tag" ("EXPLICIT") or in a similar way. The labeling of content as inappropriate or "EXPLICIT" is done by the artists, labels and/or distributors who provide us with the music or content. We have no influence on whether certain music or other content is labeled as inappropriate. We are not responsible for the fact that certain content is labeled as inappropriate or "EXPLICIT". 
                </Typography>
                <br />
                <Typography>
                    The respective artists or labels are responsible for the content. We are not responsible if our suppliers do not mark the relevant content as inappropriate or "EXPLICIT". We do not carry out our own checks or filtering in this respect and are not obliged to do so. 
                </Typography>
                <br />
                <Typography>
                    The user is responsible for ensuring that content that is inappropriate, offensive or unsuitable for minors is not made accessible to other underage users. 
                </Typography>
                <br />
                <Typography>
                    We offer a technical option to deactivate content marked as inappropriate or "EXPLICIT". In the case of tariffs that provide for several users (including minors) (e.g. ROKK HIFI FAMILY tariff), the responsible main user can manage the individual accounts of the "family", i.e. the co-users, and deactivate the content marked as inappropriate or "EXPLICIT" there. If inappropriate content is deactivated in this way, the content marked as inappropriate will not be playable for the co-user concerned.
                </Typography>
                <br />
                <Typography>
                    Furthermore, it is possible for end devices from different manufacturers to make settings on the individual user's end device so that content marked as inappropriate is allowed or blocked. If inappropriate content is deactivated or blocked in this way, the content marked as inappropriate cannot be played on the device in question. 
                </Typography>
                
                                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    8. User-generated content and rights in relation to user-generated content
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    a. User-generated content
                </Typography>
                <Typography>
                    Content that you make visible or available to others as part of our services (e.g. image uploads, descriptions, social media links) is referred to below as user-generated content. As part of our services, you may publish content or make it available or perceptible to others in other ways and contribute to our services, e.g. by compiling content in the form of playlists or rating or commenting on content. This also includes content that you "post" in the forums offered in our services and using other options provided by our services. Such "posts" also count as user-generated content. User-generated content also includes everything that you upload to our services and post there or make perceptible or available to others.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    b. Guarantee
                </Typography>
                <Typography>
                    With regard to all user-generated content that you upload, publish, make available, make perceptible, etc. within the scope of our services, you warrant that this content is free of third-party rights and that you are authorized and entitled to use and "post" this content, that it was created solely by you and that no third-party rights are infringed thereby.
                </Typography>
                <br />
                <Typography>
                    Furthermore, you warrant that your user-generated content does not infringe the rights of third parties, does not violate applicable laws and complies with our usage guidelines. In particular, you warrant that your user-generated content does not infringe any copyrights and other intellectual and industrial property rights and personal rights as well as naming rights and trademark rights of third parties. You also warrant that the publication of your user-generated content does not imply any affiliation of this content with us or with any artist, band, label or other company whose content is available on our services.
                </Typography>
                <br />
                <Typography>
                    In this respect, you are obliged to clarify any third-party rights in relation to your user-generated content in advance or, if third-party rights are affected by your user-generated content, to obtain the express written consent of the authorized party before you post the user-generated content on our services. 
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    c. Granting of rights in relation to user-generated content
                </Typography>
                <Typography>
                    With regard to all user-generated content created and posted by you, you grant us the right to publish it within the scope of our services, to make it publicly accessible and to make it publicly visible. In particular, we are therefore entitled to make your user-generated content, such as texts, images, photos, playlists, etc., publicly visible and available worldwide for an unlimited period of time within the scope of our services. For this purpose, you grant us all necessary rights to non-exclusive use with regard to this user-generated content, in particular the right to publish, reproduce, digitally reproduce, digitally distribute, digitally make available to the public, make available to the public, perform and exhibit, edit, mix with other content via all services and media operated by us now and in the future, using all known and future technologies and processes.
                </Typography>
                <br />
                <Typography>
                    This also includes our right to pass on the user-generated content to affiliated companies and other providers that are linked to our services and to allow them to use it to the same extent. This also applies in particular to service providers that we engage to provide our services.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    d. Editorial or other review of user-generated content
                </Typography>
                <Typography>
                    We expressly do not undertake any editorial or general review of user-generated content. We do not monitor or check the content of such content, nor do we edit or sort it editorially, thematically or in any other way, unless we are subject to a legal obligation to do so.
                </Typography>
                <br />
                <Typography>
                    If there are legitimate reasons, we are entitled to delete user-generated content, taking into account our and your interests as well as the interests of affected third parties, or to temporarily or permanently block access to it for other users. 
                    Justified reasons for blocking or removing content may also exist if we are required to delete certain content or to refrain from making it available via our services due to the applicable legal situation or a court or official decision. This may also be due to the fact that user-generated content violates applicable legal provisions or regulations, in particular if it is harmful to minors, glorifies violence, contains pornographic images or similar. Insofar as this is possible and appropriate, we will inform you of this before blocking or removing user-generated content and give you the opportunity to comment. 
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    e. Responsibility in relation to user-generated content
                </Typography>
                <Typography>
                    You are solely responsible for the user-generated content used or posted by you in the context of our services. This also applies in particular to rating or review comments and comments in forums and similar services and statements made there. 
                </Typography>
                <br />
                <Typography>
                    We are not responsible for user-generated content and do not adopt it as our own in any way. If claims are asserted against us by other users of our services or by third parties due to user-generated content created or posted by you, you agree to indemnify us against any associated damages, including any legal defense costs, and to compensate us for any damages, unless you are not responsible for the damage.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    f. Infringement through user-generated content
                </Typography>
                <Typography>
                    We respect intellectual property, industrial property rights and personal rights. If we are notified of an infringement of rights by certain user-generated content, we are entitled to take the necessary measures, in particular to temporarily block or permanently delete the user-generated content in question. As a rule, user-generated content will only be deleted without a prior request for comment from the user concerned if the content in question is obviously unlawful and this appears justified after weighing up the circumstances of the individual case and the interests involved. Otherwise, in the event of a report of allegedly unlawful user-generated content, the content in question will initially be blocked (but not deleted) and the user in question will be informed and asked to respond within a reasonable period of time. As part of the response, the user in question may request that the blocked content be restored and explain the circumstances under which he/she is of the opinion that the content in question does not constitute an infringement or is not illegal. If the user does not respond within this period, we will delete the content in question at our reasonable discretion. If the user in question makes a statement, we reserve the right to restore or delete the content at our discretion. The same applies if user-generated content violates applicable laws and regulations or our usage guidelines. 
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    g. Reporting user-generated content
                </Typography>
                <Typography>
                    If you believe that user-generated content infringes your rights or violates applicable law, or does not comply with our usage guidelines, you can report this to us via our reporting function directly in the ROKK STREAMING app. To do this, tap on the ROKK logo and you will be given the opportunity to contact us directly.
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    9. Availability and restrictions
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    a. Availability and limitation of our services
                </Typography>
                <Typography>
                    We constantly endeavor to ensure that our services are operational and available. However, despite our efforts, temporary disruptions and interruptions to our services may occur, for example due to technical problems, maintenance work, software updates, adaptations to changes in legal provisions or regulatory requirements. If these are plannable interruptions to our services, they generally serve to improve our services, provide you with new functions or optimize the user experience. If such interruptions can be planned (e.g. in the course of updates), we will also endeavor to keep these interruptions as short as possible and to carry them out at times when the disruption to you is as low as possible (e.g. at night).
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    b. Consequences of the unavailability of our services
                </Typography>
                <Typography>
                    Insofar as these disruptions are minor in terms of their duration in relation to the duration of the uninterrupted provision and availability of our services, this does not constitute non-performance, poor performance or breach of duty on our part that entitles you to compensation or to reclaim subscription fees paid. In the event of such a disruption for a not insignificant duration of a payment period in respect of a paid subscription, we will refund to you the subscription fees paid by you in advance for the relevant period. 
                </Typography>
                <br />
                <Typography>
                    In addition, our services may be temporarily unavailable to individual users, as our services are provided via the Internet and mobile data networks and thus via third-party services (e.g. line network operators, data centers, etc.) and, in this respect, disruptions at these third parties that occur outside our area of responsibility and sphere of access may result in our services being unavailable to you. This applies in particular with regard to disruptions at third parties if the service in question, which is the cause of the disruption, is used by you at a third party (such as your Internet access, which you have booked with your Internet access provider). We cannot assume any warranty or liability with regard to such causes that lie with third parties, unless it concerns damage caused by us or our legal representatives or vicarious agents with intent or gross negligence or - regardless of the degree of fault - a breach of essential contractual obligations by us, our legal representatives or vicarious agents or - regardless of the degree of fault - damage to life, body or health caused by us or our legal representatives or vicarious agents, for which we are liable without limitation. 
                </Typography>
                <br />
                <Typography>
                    Disruptions caused by third parties engaged by you, which result in you being unable to use our services, do not entitle you to assert claims for compensation against us or to demand a refund of the subscription fees paid by us in advance. 
                </Typography>
                <br />
                <Typography>
                    In all other respects, the provisions in Section 10 of these Terms of Use apply to liability.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    c. Scope of the content provided via our services 
                </Typography>
                <Typography>
                    We aim to provide our customers with a large repertoire catalog and to constantly expand it. Nevertheless, we are dependent on our contractual partners for the delivery and provision of content. Some content is only available for a limited time due to contractual relationships with third parties, among other things. We have no influence over this.  In this respect, we are dependent on the provision of content from our contractual partners (online distributors, record labels, etc.). They sometimes only have the necessary rights to certain content for a certain period of time so that it can be made available for you to use via our services. If the rights required for the provision of certain content expire with one of the contractual partners, this content is blocked by our contractual partners and can therefore no longer be accessed via our services. The same applies in the event that certain content violates the rights of third parties and third parties assert rights and claims to block or remove the content. In this respect, we are entitled at any time to block or remove certain content for the future and to delete it from our repertoire catalog. You expressly agree that by taking out a subscription you are not entitled to a specific scope of content that can be accessed via our services.
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                   10. Liability and limitation of liability
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    a. Liability for damages
                </Typography>
                <Typography>
                    We shall only be liable for damages caused by us, our legal representatives or vicarious agents with intent or gross negligence and, regardless of the degree of fault, for damages based on a breach of essential contractual obligations (so-called cardinal obligations) by us, our legal representatives or vicarious agents and, regardless of the degree of fault, for damages relating to life, limb or health for which we are liable without limitation. 
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    b. Limitation of liability
                </Typography>
                <Typography>
                    Insofar as we are not liable without limitation in accordance with Section 10. a) of these Terms of Use, in the event of a breach of a material contractual obligation due to simple negligence, our liability for damages or reimbursement of expenses shall be limited to the typically foreseeable damage. Material contractual obligations are obligations whose fulfillment is essential for the proper execution of the contractual relationship between you and us and on whose fulfillment you regularly rely and may also rely.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    c. Disclaimer
                </Typography>
                <Typography>
                    Our liability is excluded beyond the cases specified in sections 10.a) and 10.b) of these Terms of Use. In particular, we are also not liable for damages due to any loss of data or due to the theft or loss of your access data if the damage could have been avoided by you by regularly making backup copies of your data to a reasonable extent in the event of data loss or, in the event of the theft or loss of your access data, by adequately protecting it from loss or theft or access by third parties.
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                   11. Term and termination
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    a. Contract term and ordinary termination
                </Typography>
                <Typography>
                    The contract between you and us is effective until it is terminated either by you or by us. 
                </Typography>
                <br />
                <Typography>
                    You or we can terminate the contract at the end of each calendar month with a notice period of 14 days, unless you have concluded a contract or subscription with a fixed term. If you have taken out a subscription with a fixed term, this can be terminated at the earliest at the end of the fixed term with a notice period of 14 days. 
                </Typography>
                <br />
                <Typography>
                    You can view the remaining term of your subscription in your user account under Settings under the heading Duration of subscription. 
                </Typography>
                <br />
                <Typography>
                    A special cancelation button is available in your user account under Settings.
                </Typography>
                <br />
                <Typography>
                    In all other respects, the provisions on the renewal and automatic renewal of subscriptions pursuant to Section 4 of these General Terms and Conditions of Use shall apply.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    b. Termination for good cause
                </Typography>
                <Typography>
                    If you use our services in an unauthorized or unlawful manner and/or post user-generated content that violates our usage guidelines, violates the rights of third parties in the context of participation in our services or posts content that violates statutory rules or regulations, we are entitled to terminate the contract concluded with you for good cause and/or to block your access to our services if, after weighing up the circumstances of the individual case and taking into account our and your interests, it is unreasonable for us to adhere to the contract. 
                </Typography>
                <br />
                <Typography>
                    We may block your access to our services with immediate effect if this appears necessary, taking into account the circumstances of the individual case and the interests of both parties and weighing up the interests of both parties, and if a particular urgency justifies blocking access: The latter may be the case in particular if there is a risk of repeat offenses or copycat offenses.
                </Typography>
                <br />
                <Typography>
                    In addition, both parties have the statutory right to terminate the contract for good cause, which remains unaffected by the provisions in these Terms of Use.
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                    c. Force majeure
                </Typography>
                <Typography>
                    In cases of hacker attacks, war, terrorism, pandemics, epidemics, riots, fire damage, floods, strikes, epidemics and other cases of force majeure including natural disasters and other unforeseeable, unavoidable and serious events over which we have no influence, including sovereign or official measures in connection with such cases or events (e.g. contact restrictions and curfews), our services may not be available without errors or interruptions. The contracting parties shall then be released from their mutual performance obligations for the duration of the disruption caused by the effects of the event and to the extent of the effect of the disruption, unless one contracting party is responsible for the causal event. The mutual performance obligations shall be suspended for the duration of the disruption and resumed after the end of the disruption. Is it not possible to exclude liability in this case as well, instead of having to offer rectification or extension? In this case, the service provision or contract period is extended by the duration of the disruption. For the duration of the disruption, the contractual partners have no reciprocal performance obligations. If the event lasts longer than 6 months or if it is impossible or unreasonable for us to continue the services after this time for legal or factual reasons, we are entitled to terminate the contract extraordinarily. We shall thereby lose the claim to the remuneration that would have been owed for services not yet rendered.
                </Typography>
                <br />
                <Typography>
                    For these cases and any resulting damage, liability on our part is excluded, unless it is a case of intent or gross negligence or - regardless of the degree of fault - damage to life, limb or health, for which we are fully liable. 
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                   12. Applicable law
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                   a. Applicable law and alternative dispute resolution
                </Typography>
                <Typography>
                    German law shall apply insofar as the protection granted by mandatory provisions of the law of the state of the consumer's habitual residence is not withdrawn (principle of favorability) and insofar as the mandatory law does not prescribe otherwise in the individual case.  
                </Typography>
                <br />
                <Typography>
                    The application of the UN Convention on Contracts for the International Sale of Goods is expressly excluded. 
                </Typography>

                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontWeight: '600'
                }}>
                   b. Alternative dispute resolution 
                </Typography>
                <Typography>
                    The European Union operates a voluntary online dispute resolution platform which can be accessed via the following link: <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>
                </Typography>
                <br />
                <Typography>
                    We have decided not to participate in this alternative dispute resolution. We are also not obliged to do so. 
                </Typography>
                <br />
                <Typography>
                    If you notice a problem, you can contact us at any time at <a href="https://rokk-app.com/faq">https://rokk-app.com/faq</a>.
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                   13. Consequences of a breach of the terms of use, usage guidelines or guarantees and indemnification 
                </Typography>
                <Typography>
                    If you violate the Terms of Use, Usage Guidelines and other contractual bases or post user-generated content that violates our guidelines or Terms of Use or use or misuse our services in a manner not intended or if user-generated content made available by you violates legal provisions or third-party rights and/or the guarantees given by you in this regard, you undertake to indemnify us against all associated damages, including legal defense costs in a reasonable amount, unless you are not responsible for the respective violation or the violation of obligations or any third-party rights, etc.. breach of obligations or any rights of third parties etc. for which you are not responsible. 
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                   14. Customer service and support
                </Typography>
                <Typography>
                    <a href="https://rokk-app.com/faq">https://rokk-app.com/faq</a>
                </Typography>

                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                   15. Usage guidelines 
                </Typography>
                <Typography>
                    We respect the intellectual property rights and creative products and content that we make available to you through our services and want to ensure that everyone enjoys using our services. Accordingly, we also expect you to comply with some basic rules. To this end, we have set out the following usage guidelines that you must observe when using our services. 
                </Typography>
                <br />
                <Typography>
                    Your use of our services and content must comply with the rules set out in this section ("Usage Guidelines"). You must comply with the Acceptable Use Policy in all forms of use of our services and content, regardless of the plan or subscription you have booked and especially if you use our services free of charge. You should also encourage others to comply with the Acceptable Use Policy. 
                </Typography>
                <br />
                <Typography>
                    a. You may only use our services to the extent that corresponds to the subscription plan you have booked. Our subscription plans allow you to make private use of the content available via our services by streaming. You may only use our services and the content available via our services for private, non-commercial and non-commercial or non-business purposes and may not publicly reproduce or publicly perform the content or reproduce, distribute, record, broadcast or transmit it, unless this is expressly permitted by exception under the applicable statutory provisions.
                </Typography>
                <br />
                <Typography>
                    b. The provision of our services and content does not grant you any rights to use the services and content for commercial, entrepreneurial, commercial or advertising purposes, and does not constitute a grant or concession of rights to the content. In particular, you may not reproduce, distribute, publicly reproduce, publicly perform or make the content publicly accessible, unless this is expressly permitted as an exception under the applicable statutory provisions.
                </Typography>
                <br />
                <Typography>
                    c. It is not permitted to pass on your password or login details to another person or to use the login details of another person. The number of devices with which you can and may simultaneously use the content available via our services by means of streaming depends on the subscription tariff selected. It is not permitted to exceed the number of devices included in the booked tariff with which you may simultaneously use the content available via our services. 
                </Typography>
                <br />
                <Typography>
                    d. If your subscription ends, the content available through our services will no longer be available to you and you will lose access to paid services. 
                </Typography>
                <br />
                <Typography>
                    e. You may only access our services with the software or app provided by us or via our website www.rokk-app.com and may not manipulate or modify software or apps provided by us or our website and may not use a manipulated or modified version of our software or apps.
                </Typography>
                <br />
                <Typography>
                    f. You may not manipulate or circumvent the security technologies, authentication measures or anti-abuse technologies contained in our services or content or on our website www.rokk-app.com or in our software or apps, nor may you attempt to do so or introduce malware. It is also not permitted to circumvent the technologies used by us, our licensors or third parties to protect the content against unauthorized distribution. In particular, you may not circumvent any geographical restrictions in relation to access to certain services and functions or in relation to certain content.
                </Typography>
                <br />
                <Typography>
                    g. You may not manipulate or falsify any streaming/playback figures or related playback counters and may not falsify any ratings or reviews or similar. You may not do so either through individual misuse of our services or through automated processes such as the use of bots, scripts or special program codes. It is also forbidden to support third parties in doing so or to induce them to do so by providing consideration or compensation or other incentives. 
                </Typography>
                <br />
                <Typography>
                    h. Free trial subscriptions are only available to each person once. Therefore, if you have taken out a free trial subscription, you may not activate a free trial subscription again. In particular, you may not register more than once for a free trial subscription by providing false data or using different e-mail addresses. 
                </Typography>
                <br />
                <Typography>
                    i. If your free trial subscription ends (for example, by canceling the free subscription at the end of the free trial period) without booking a paid subscription, you will lose access to our services and content at the end of the free trial period.
                </Typography>
                <br />
                <Typography>
                    j. The sale, rental, sublicensing or leasing of our services or the content available through our services or any part thereof is expressly prohibited.
                </Typography>
                <br />
                <Typography>
                    k. We do not check user-generated content, comments or other postings without cause. You are responsible for your own user-generated content, comments and other postings. Therefore, please be careful when using our services and when posting comments and user-generated content and personal data and information. 
                </Typography>
                <br />
                <Typography>
                    l. You may not post user-generated or other content and information or engage in any other activity in connection with the use of our services (including the selection of your user name) to which the following applies:
                </Typography>

                <br />
                <ul>
                    <li>
                        <Typography>
                        	offensive, defamatory, youth-endangering, violence-glorifying, inhuman or pornographic content or language
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            Hate speech and calls for violence
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	untrue facts
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	other illegal content
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	Content that offends common decency
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	Acts that constitute infringements of intellectual property rights
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	Copyright infringements
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	Violations of personal rights
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	Disclosure of personal data of third parties
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	Malware and harmful content such as malware, Trojans or viruses
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	Advertising, spam and harassing content
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	Activities with a commercial background or commercial intentions, sales activities, sales promotions, competitions, prize draws, etc.
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	Links and references to third-party offers, products or services
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        	Activities that serve to disrupt, misuse or misappropriate our services
                        </Typography>
                    </li>
                </ul>

                <br />
                <Typography>
                    Any use of our services and content that violates the usage guidelines constitutes a material breach of contract and entitles us, depending on the severity and frequency of any violations, to deny you certain uses or functions and to temporarily or permanently deny you access to our services or to delete your user account. We are entitled to block your user account and terminate the contractual relationship, particularly in the event of significant and/or repeated violations.
                </Typography>
                
                                <br />
                <Typography sx={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                   16. Miscellaneous
                </Typography>
                <Typography>
                    Insofar as provisions or general terms and conditions have not become part of the contract in whole or in part or are invalid, the rest of the contract shall remain valid and the content of the contract shall be governed by the statutory provisions with regard to those provisions that have not become part of the contract or are invalid
                </Typography>



            </InfoContainer>

            </div>
    </ContactInformationContainer>
  )
}

export default TermsOfUse