import React, { useEffect, useRef } from 'react'

// Import State Context
import { useStateContext } from '../../context/StateContextAPI'

import { AppleIconSmall, AppleStoreCard, CompactLogo, GooglePlayCard, PlayStoreIconSmall, ScreensBG, WebsiteBanner } from '../../assets'
import { NewsletterCard, NewsletterForm, PlansContainer } from '../../components'

// Import CSS file
import "./home.page.css"

// Import Material UI Elements
import { Button, IconButton } from '@mui/material';
import { Pause, PlayArrow, VolumeOff, VolumeUpTwoTone } from '@mui/icons-material';
import { allStyleMusic, favouriteBand, hiResOfflineMode, importFunction } from '../../components/exports/newsletter-content-exports.component';
import { ButtonContainer } from './home.constans';
import styled from '@emotion/styled'
import LaunchTimer from './LaunchTimerSection'
import LaunchWeekBanner from './LaunchWeekBanner'


const WebsiteBannerImg = styled.img`
    width:100%;
`

const Home = () => {

    // Handling Promo Video Playback
    const [playOption, setPlayOption] = React.useState(false);
    const [mute, setMute] = React.useState(true);
    const videoRef = useRef<HTMLVideoElement>(null);
    const handlePlayback = () => {
        setPlayOption(!playOption);
        if (!playOption) videoRef?.current?.pause();
        else videoRef?.current?.play()
    }
    const handleSound = () => {
        setMute(!mute);
    }

    // Set the active page as Home for the Navigation bar
    const { setActivePage } = useStateContext();
    useEffect(() => {
        setActivePage(() => "home")
        window.scrollTo(0, 0);
    }, [setActivePage])


    const [isTimerVisible, setIsTimerVisible] = React.useState<boolean | null>(null);

    useEffect(() => {
        // Target time: September 2, 2024, 12:00 PM CET
        const targetTime = new Date(Date.UTC(2024, 8, 2, 10, 0, 0)); // September 2nd, 2024, 10:00 AM UTC
        const finalTime = new Date(Date.UTC(2024, 8, 16, 10, 0, 0));

        const checkTime = () => {
            const now = new Date(); // Current time in UTC
            if (now >= targetTime && now < finalTime) {
                setIsTimerVisible(true);
            }
            if (now >= finalTime) {
                // clearInterval(interval)
                setIsTimerVisible(false);
            }
        };

        const interval = setInterval(checkTime, 1000);

        return () => clearInterval(interval);
    }, []);

    // return the functional component
    return (
        <div className="homePage">
            
            {/* <WebsiteBannerImg src={WebsiteBanner} onClick={() => window.location.href="https://igg.me/at/rokkapp"}/> */}

            {/* Launch Countdown */}
            {/* {isTimerVisible && <LaunchTimer />} */}

            <LaunchWeekBanner />

            {/* Header section */}
            <div className="home-header-container">

                {/* Title: The First Streaming App.. */}
                <div className="head-text">
                    <div>
                        <span className="head-txt-off-white">THE FIRST</span>
                        <span className="head-txt-primary">STREAMING</span>
                    </div>
                    <span className="head-txt-off-white">APP DEDICATED TO</span>
                    <br />
                    <span className="head-txt-primary">ROCK & METAL!</span>
                </div>

                {/* Header Description.. */}

                <div className="head-desc-main">
                    <span>
                        Fair music streaming.
                    </span>
                    <span>
                        By the metal community, for the metal community
                    </span>
                </div>

                {/* Header section Buttons */}

                <ButtonContainer>
                    <Button
                        variant='contained'
                        sx={{
                            color: '#ffffff',
                            fontWeight: 'bold',
                            background: '#ff8303',
                            '&:hover': {
                                background: '#ff8303'
                            },
                            padding: '10px 20px'
                        }}
                        onClick={() => window.location.href="https://igg.me/at/rokkapp"}
                    >
                        FREE TRIAL MONTH
                    </Button>
                    <Button
                        variant='contained'
                        sx={{
                            color: '#ffffff',
                            background: '#1a1a1a',
                            '&:hover': {
                                background: '#1a1a1a'
                            },
                            padding: '10px'
                        }}
                    >
                        <img src={PlayStoreIconSmall} alt="PlayStore_Icon" />

                    </Button>

                    <Button
                        variant='contained'
                        sx={{
                            color: '#ffffff',
                            background: '#1a1a1a',
                            '&:hover': {
                                background: '#1a1a1a'
                            },
                            padding: '10px'
                        }}
                    >
                        <img src={AppleIconSmall} alt="AppleStore_Icon" />
                    </Button>

                </ButtonContainer>
            </div>

            {/* The Screen containing Promo Video */}

            <div className="screens-bg">
                <img src={ScreensBG} alt="rokk-app-screens" />
                <div className="video-wrapper">
                    <video
                        muted={mute ? true : false}
                        ref={videoRef}
                        loop
                        autoPlay
                        playsInline
                        className="promo-video"
                        onClick={handlePlayback}
                    >
                        <source src={"https://rokk.app/wp-content/uploads/2024/01/ROKK_Campaign_Launch.mp4"} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    {/* <iframe src="https://www.youtube-nocookie.com/embed/vUc4zf8Voso?si=SCPW0C707uSf5P8h&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
                </div>

                {/* Adding Buttons to handle pplayback */}
                <div className="play-pause-icon-video-playback">
                    {
                        playOption
                            ?
                            <IconButton style={{ color: '#ff8303', background: '#1a1a1a' }} aria-label="play button" size="large" onClick={handlePlayback}>
                                <PlayArrow fontSize='medium' />
                            </IconButton>
                            :
                            <IconButton style={{ color: '#ff8303', background: '#1a1a1a' }} aria-label="pause video" size="large" onClick={handlePlayback}>
                                <Pause fontSize='medium' />
                            </IconButton>

                    }
                </div>

                <div className="mute-unmute-btn">
                    {
                        mute
                            ?
                            <IconButton style={{ color: '#ff8303', background: '#1a1a1a' }} aria-label="play button" size="large" onClick={handleSound}>
                                <VolumeOff fontSize='medium' />
                            </IconButton>
                            :
                            <IconButton style={{ color: '#ff8303', background: '#1a1a1a' }} aria-label="pause video" size="large" onClick={handleSound}>
                                <VolumeUpTwoTone fontSize='medium' />
                            </IconButton>

                    }
                </div>
            </div>

            {/* Plans Section */}

            <div className="pricing-section">
                <div className='pricing-section-header' style={{ textAlign: 'center' }}>
                    <span className="head-txt-primary">PLANS & PRICING</span>
                </div>
                <div className="flex flex-dir-col gp-20">
                    <span style={{ textAlign: 'center' }}><span style={{ opacity: 0.5 }}>Save even more on</span> annual plans <span style={{ opacity: 0.5 }}>& combine them with a</span> Student or Family discount</span>
                    {/* <ToggleButtonItems itemCentered={true} /> */}
                </div>

                <PlansContainer />
            </div>

            <div className="newsletter-and-card-section flex flex-dir-col">
                <div className="newsletter-container">
                    <div className="newsletter-section-header flex flex-dir-col">
                        <span className="head-txt-off-white font-sz-small">SUBSCRIBE TO</span>
                        <span className="head-txt-off-white font-sz-small">OUR&nbsp;
                            <span className="head-txt-primary">ROKK</span>
                        </span>
                        <span className="head-txt-primary font-sz-small">NEWSLETTER</span>
                    </div>
                    <NewsletterForm />
                </div>
                <div className="flex jst-con-spc-btw flex-wrap gp-20" style={{ fontFamily: 'var(--title-font)', paddingRight: '84px' }}>
                    <div className='flex aln-items-cnt gp-10'>
                        <img src={CompactLogo} style={{ width: '25px' }} alt="rokk_logo_compact" />
                        <span className="font-sz-medium">WHY JOIN ROKK</span>
                    </div>
                    <div className='pricing-section-header'>
                        <span className="head-txt-off-white">THE MUSIC STREAMING APP FOR</span>
                        <span className="head-txt-primary">ROCK AND METAL.</span>
                    </div>
                </div>
                <div className="newsletter-cards-container">
                    <NewsletterCard obj={favouriteBand} />
                    <NewsletterCard obj={allStyleMusic} />
                    <NewsletterCard obj={hiResOfflineMode} />
                    <NewsletterCard obj={importFunction} />
                </div>
            </div>
            <div className="download-app-container">
                <div className="pricing-section-header" style={{ textAlign: 'center' }}>
                    <span className="head-txt-off-white">MUSIC ANYTIME,</span>
                    <span className="head-txt-off-white">ANYWHERE.</span>
                </div>

                <div style={{ textAlign: 'center' }}>
                    Music streaming made for
                    metal and rock fans.<div> <span style={{ color: 'var(--primary)' }}>Download the App</span>
                    </div>
                </div>
                <div className="download-btn">
                    <img src={GooglePlayCard} alt="google_play_download_card" />
                    <img src={AppleStoreCard} alt="apple_store_download_card" />
                </div>

            </div>
        </div >
    )
}

export default Home