import styled from "@emotion/styled"
import { Skeleton } from "@mui/material";

export const BandContainer = styled.div`
    padding: var(--global-padding);
    display: flex;
    column-gap: 50px;
    row-gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const UniqueCodeBox = styled.div`
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
    min-height: 150px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;

    @media (max-width: 600px) {
        min-height: 100px;
    }
`;


export const FormContainer = styled.div`
    flex: 1;
    max-width: 900px;
    margin: 20px 0;
`

export const Header = styled.div`
    font-size: 0.8em;
    padding: 0 0 30px 0;
`;

export const ArtistFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const ComponentTitle = styled.span`
    font-weight: 400;
    font-size: 24px;
    font-family: var(--title-font), sans-serif;
    line-height: 1em;
    letter-spacing: 1px;
`

export const Image = styled.img`
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 5px;
`

export const ArtistImage = styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover;
    object-position: top center;
    border-radius: 5px;
    // margin: 0 auto;
    // border-radius: 50%;
`

export const SkeletonUI = () => {
    return (
        <>
            <Skeleton variant="rounded" height={70} sx={{ background: 'rgba(255, 255, 255, 0.1)' }} />
            <Skeleton variant="rounded" height={70} sx={{ background: 'rgba(255, 255, 255, 0.1)' }} />
            <Skeleton variant="rounded" height={70} sx={{ background: 'rgba(255, 255, 255, 0.1)' }} />
            <Skeleton variant="rounded" height={70} sx={{ background: 'rgba(255, 255, 255, 0.1)' }} />
        </>
    )
}