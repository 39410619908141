import styled from '@emotion/styled';
import { Box, Divider, Step, StepLabel, Stepper } from '@mui/material';
import React, { useEffect } from 'react';
import { ArtistFormContent } from '../../components';
import { useStateContext } from '../../context/StateContextAPI';
import { useWindows } from '../../hooks/customHooks';
import useStepper from '../../hooks/useStepper';
import { steps } from '../../utils/constants';
import "./register.css";

// ... [Styled components remain unchanged]

const ArtistRegistrationContainer = styled.div`
    display: flex;
    color: #fff;
    gap: 20px;
    flex-wrap: wrap;
`;

const ProcessSection = styled.div`
    padding: var(--global-padding);
`;

const Header = styled.div`
    font-size: 0.8em;
    padding: 0 0 30px 0;
`;

const FormSection = styled.div`
    flex: 1;
    padding: var(--global-padding);
`;

const titles = [
    "BAND / ARTIST NAME",
    "BAND / ARTIST DETAILS",
    "BAND / ARTIST DETAILS",
    "AFFILIATE AGREEMENT TERMS AND CONDITIONS",
    "THANK YOU FOR REGISTERING",
    "Something went wrong ;)"
];

const VerticalLinearStepper = ({ activeStep }: { activeStep: number }) => {
    const { windowWidth } = useWindows();

    return (
        <Box sx={{ maxWidth: 400, padding: '30px 0' }}>
            <Stepper
                alternativeLabel={windowWidth < 700}
                activeStep={activeStep}
                orientation={windowWidth < 700 ? 'horizontal' : 'vertical'}
            >
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel>
                            <span style={{ color: index === activeStep ? 'white' : 'var(--light-gray)' }}>
                                {step.label}
                            </span>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

const ArtistRegistraion = () => {
    const { setActivePage } = useStateContext();
    const { currentStep, goToStep } = useStepper(0);

    useEffect(() => {
        setActivePage("");
        window.scrollTo(0, 0);
        localStorage.setItem('activeStep', String(currentStep));
    }, [currentStep]);

    return (
        <ArtistRegistrationContainer>
            <ProcessSection>
                {/* ... [Header remains unchanged] */}
                <Header>
                    <div className="head-text artist-page-head-text">
                        <span className="head-txt-off-white">BECOME A PART </span>
                        <br />
                        <span className="head-txt-off-white">OF THE ROKK </span>
                        <br />
                        <span className="head-txt-off-white">ARTIST FAMILY!</span>
                    </div>

                    <div>
                        <span>Sign up and become one of the first <span style={{ color: '#ff8303' }}>official Rokk <br />partner artists!</span> It’s free and only takes a few minutes.</span>
                    </div>
                </Header>
                <Divider style={{ backgroundColor: 'var(--light-gray)' }} />
                <VerticalLinearStepper activeStep={currentStep} />
            </ProcessSection>

            <FormSection>
                <br />
                <span className="head-text" style={{ fontSize: '24px' }}>
                    {titles[currentStep]}
                </span>
                <ArtistFormContent stepperCount={currentStep} setStepCount={goToStep} />
            </FormSection>
        </ArtistRegistrationContainer>
    );
}

export default ArtistRegistraion;
