import React from 'react'
import { ArtistFormContainer, ComponentTitle, Image, SkeletonUI } from '../exports/band.registration.styles'
import { ArtistData, MemberArtistData, useStateContext } from '../../context/StateContextAPI';
import _ from 'lodash';
import { Badge, Box, Button, Typography } from '@mui/material';
import { Close, KeyboardArrowRight } from '@mui/icons-material';
import { BandArtistRokk, MemberRokkArtist } from '../../assets';
import { artist_data } from '../../utils/constants';

const PartyRegisterStep1 = ({ activeStep, setActiveStep }: { activeStep: number, setActiveStep: (step: number) => void }) => {

    const { memberArtistData, setMemberArtistData, setArtistID, artistID, setRegistrationType, setArtistData } = useStateContext();

    React.useEffect(() => {
        setRegistrationType("Artist");
    }, [])
    const [partyName, setPartyName] = React.useState("");

    const [isMemberArtistSet, setIsMemberArtistSet] = React.useState(false);

    const [memberArtistFromAPI, setMemberArtistFromAPI] = React.useState<MemberArtistData[]>()

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setPartyName((prev: string) => {
            return event.target.value;
        });
    }

    const handleArtistSelect = (party: MemberArtistData) => {
        setMemberArtistData((prev: MemberArtistData) => ({
            ...prev,
            party_id: party.party_id,
            party_name: party.party_name,
            instrument: "",
            artist_link: party.artist_link,
            artist_name: party.artist_name
        }));
        setArtistID(party.artist_id);
        // console.log(artistData)
    }

    const resetArtist = () => {
        setMemberArtistData(undefined);
    }

    const ArtistSuggestions = () => {
        return (
            <>
                {
                    partyName.length > 0 && !memberArtistFromAPI ?
                        SkeletonUI()
                        :
                        null
                }

                {
                    memberArtistFromAPI && memberArtistFromAPI.length > 0 ?
                        memberArtistFromAPI.map((party: MemberArtistData, _idx: number) => {
                            return (
                                <Box
                                    key={_idx}
                                    style={{
                                        background: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleArtistSelect(party)}
                                >
                                    <div className="artist-data-main-section">
                                        <Image src={party.artist_link} onError={(e: any) => (e.target as HTMLImageElement).src = MemberRokkArtist} />
                                        <div>
                                            <Typography sx={{
                                                fontWeight: 500
                                            }}>
                                                {party.party_name}
                                            </Typography>
                                            <Typography sx={{
                                                color: 'var(--offWhite)',
                                                '@media screen and (max-width: 600px)': {
                                                    fontSize: '13px'
                                                }
                                            }}>
                                                (Band - {party.artist_name})
                                            </Typography>
                                        </div>

                                    </div>

                                    <KeyboardArrowRight sx={{ color: 'var(--light-gray)' }} />

                                </Box>
                            )
                        })
                        :
                        null
                }

                {
                    memberArtistFromAPI && memberArtistFromAPI.length < 1 ?
                        "No Data Found"
                        :
                        null
                }
            </>
        )
    }

    const ArtistDetails = () => {
        return (
            <Badge badgeContent={<Close sx={{ fontSize: 'small' }} />}
                color='primary'
                sx={{
                    width: '100%',
                    '& .MuiBadge-standard': {
                        background: '#ff8303',
                        padding: '2px 0'
                    }
                }}
                onClick={resetArtist}
            >
                <Box
                    width={'100%'}
                    style={{
                        background: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: '10px',
                        padding: '10px',
                        display: 'flex',
                        color: 'var(--white)',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flex: 1
                    }}
                >
                    <div className="artist-data-main-section">
                        <Image src={memberArtistData?.artist_link} onError={(e: any) => (e.target as HTMLImageElement).src = MemberRokkArtist} />
                        <div>
                            <Typography sx={{
                                fontWeight: 500
                            }}>
                                {memberArtistData?.party_name}
                            </Typography>
                            <Typography sx={{
                                color: 'var(--offWhite)',
                                '@media screen and (max-width: 600px)': {
                                    fontSize: '13px'
                                }
                            }}>
                                (Band - {memberArtistData?.artist_name})
                            </Typography>
                        </div>

                    </div>

                    <KeyboardArrowRight sx={{ color: 'var(--light-gray)' }} />

                </Box>
            </Badge>
        )
    }

    const debouncedFetchArtistData = React.useRef(_.debounce(async (searchName) => {
        if (searchName.length < 1) return;
        const options = {
            method: 'GET',
            headers: {
                accept: 'application/json'
            }
        }
        try {
            await fetch(`http://localhost:5009/artist/party?searchParty=${searchName}&limit=5`, options)
                .then(response => response.json())
                .then(res => {
                    if (res?.length > 0) {
                        setMemberArtistFromAPI(() => res);
                    }
                });
        } catch (err) {
            console.log('%cNo data found', 'background: #ffffff; padding: 2px 5px; border-radius: 2px; color: black; font-weight: bold;')
        }
    }, 300));

    React.useEffect(() => {
        if (partyName.length < 1) {
            setMemberArtistFromAPI(undefined);
        }
        debouncedFetchArtistData.current(partyName);
    }, [partyName]);

    React.useEffect(() => {
        const fetchArtistData = async () => {
            try {
                const options = {
                    method: 'GET',
                    headers: {
                        accept: 'application/json'
                    }
                }
                const data = await fetch(`http://localhost:5009/artist/BandSearch?artistID=${artistID}`, options);
                const res = await data.json();
                setArtistData(res[0])
            } catch (err) {
                console.log(err)
            }
        };

        if (artistID !== -1) { // Only fetch if artistID is set
            fetchArtistData();
        }
    }, [artistID]); // Only re-run the effect if artistID changes

    React.useEffect(() => {
        setIsMemberArtistSet(true);
    }, [setMemberArtistData, handleArtistSelect])

    React.useEffect(() => {
        sessionStorage.setItem("RokkArtistData", JSON.stringify(artist_data));
    }, [])

    const handleNextStep = () => {
        setActiveStep(activeStep + 1);
    }

    return (
        <ArtistFormContainer>
            <ComponentTitle>
                SEARCH YOURSELF
            </ComponentTitle>

            <label htmlFor="artist_name" className="label_items">Select Yourself*</label>
            <input type="search" id="artist_name" className="inp inp-artist-name" placeholder='Artist Name*' onChange={(e) => handleNameChange(e)} required />
            {isMemberArtistSet && memberArtistData && memberArtistData.party_id && memberArtistData?.party_id !== -1 &&
                <>
                    <Box
                        sx={{
                            background: 'rgba(255, 255, 255, 0.1)',
                            padding: '10px',
                            borderRadius: '10px',
                            cursor: 'pointer'
                        }}
                    >
                        <Typography sx={{ fontSize: '12px', fontWeight: 600, marginBottom: '10px', color: '#ff8303' }}>Selected Artist</Typography>
                        <ArtistDetails />
                    </Box>
                </>

            }
            <ArtistSuggestions />
            {isMemberArtistSet && memberArtistData && memberArtistData.party_id && memberArtistData?.party_id !== -1 &&
                <>
                    <Button
                        variant="contained"
                        style={{
                            background: '#ff8303',
                            color: '#000',
                            borderRadius: '30px',
                            height: '50px',
                            fontSize: '15px',
                            fontWeight: 600,
                            marginTop: '20px',
                            flex: 1
                        }}
                        onClick={handleNextStep}
                    >
                        CONTINUE
                    </Button>
                </>

            }

        </ArtistFormContainer>
    )
}

export default PartyRegisterStep1