import styled from "@emotion/styled";

const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const FlexBoxContainer = styled.span`
    color: #ffffff;
`

export {
    ButtonContainer,
    FlexBoxContainer
};