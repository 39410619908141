import React, { useEffect } from 'react'
import { useStateContext } from '../../context/StateContextAPI'

import faqData from "./faq.questions.json"

import "./faq.page.css"
import { ContactForm, NewsletterForm } from '../../components'
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled'
import { MainBackgroundVector } from '../../assets'

const FAQPageContainer = styled.div`
    width: 100%;
`

export const BackgroundImage = styled.img`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
`

const GeneralLayout = styled.div`
    display: flex;
    gap: 30px;
    padding: 0 var(--global-padding);
    flex-wrap: wrap;
    margin: 50px 0;
`
const FillerBox = styled.div`
    flex: 1;
    min-width: 300px;
    max-width: 450px;
    padding: 50px;
    box-sizing: border-box;
    @media screen and (max-width: 680px) {
        display: none;
    }
`
const HeaderText = styled.div`
    font-size: 4.5em;
    flex: 1;
    margin: 150px 0 50px 0;
    font-family: var(--title-font), sans-serif;
    line-height: 1em;
    letter-spacing: 1px;
    min-width: 300px; 
`

const FAQNavigatorLinks = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: sticky;
    top: 100px;
    gap: 10px;
    min-width: 300px;
    max-width: 450px;
    height: 600px;
    background: #000;
    background: linear-gradient(to bottom, rgba(0,0,0,1), #101010);
    box-sizing: border-box;
    padding: 50px;
    @media screen and (max-width: 680px) {
        display: none;
    }
`

const ContactsLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    min-width: 300px;
    max-width: 450px;
    background: #000;
    background: linear-gradient(to bottom, rgba(0,0,0,1), #101010);
    box-sizing: border-box;
    padding: 50px;
`

const FAQSection = styled.div`
    flex: 1;
    min-width: 300px;
`

const ContactSection = styled.div`
    flex: 1;
    min-width: 300px;
`

const FaqPage = () => {

    const { setActivePage } = useStateContext();

    useEffect(() => {
        setActivePage(() => "faq")
        window.scrollTo(0, 0);
    }, [setActivePage])


    const rokkUsersFAQs = faqData.rokkUsers;
    const rokkArtistsFAQs = faqData.rokkArtists;

    return (
        <>
        <FAQPageContainer>
            <BackgroundImage src={MainBackgroundVector} />
            <GeneralLayout>
                <FillerBox>
                    &nbsp;
                </FillerBox>
                <HeaderText id="rokk-users-faq">
                    <div>
                        <span className="head-txt-primary">FREQUENTLY ASKED</span>
                    </div>
                    <div>
                        <span className="head-txt-off-white">QUESTIONS</span>
                    </div>
                </HeaderText>
            </GeneralLayout>
            <GeneralLayout>
                <FAQNavigatorLinks>
                <Typography
                    sx={{
                        fontFamily: 'var(--head-text)',
                        fontSize: '24px',
                        textTransform: 'uppercase',
                        color: '#ffffff80'
                    }}
                >Most frequently asked questions and answers</Typography>
                <a href="#rokk-users-faq">
                    <Button
                    fullWidth
                        sx={{
                            color: '#ff8303',
                            height: '50px',
                            fontFamily: 'Poppins, sans-serif',
                            borderRadius: 0,
                            padding: '10px 20px',
                            borderColor: 'var(--primary)',
                            '&:hover': {
                                border: '1px solid #ff8303',
                                background: '#ff830320'
                            }
                        }}
                        variant='outlined'
                    >
                        ROKK Users
                    </Button>
                    </a>
                    <a href="#rokk-artists-faq">
                    <Button
                    fullWidth
                        sx={{
                            color: '#ffffff80',
                            height: '50px',
                            fontFamily: 'Poppins, sans-serif',
                            borderRadius: 0,
                            padding: '10px 20px',
                            border: '1px solid #ffffff80',
                            '&:hover': {
                                border: '1px solid #ff8303',
                                background: '#ff830320'
                            }
                        }}
                        variant='outlined'
                    >
                        ROKK Artists
                    </Button>
                    </a>
                </FAQNavigatorLinks>
                

            {/* FAQ Hero Section */}
            <FAQSection>
                <div>
                    <div className="faq-section-head-text">ROKK USERS</div>

                    <div className="rokk-app-faq-questions-container">
                        {rokkUsersFAQs.map((ques, _idx) => {
                            return (

                                <Accordion
                                    key={_idx}
                                    sx={{
                                        backgroundColor: '#1a1a1a',
                                        background: 'linear-gradient(215.36deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%)',
                                        borderRadius: '0',
                                        '&.Mui-expanded': {
                                            background: 'rgba(255, 255, 255, 0.15)'
                                        }
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ color: '#ffffff' }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{
                                            minHeight: '70px',
                                            color: '#ffffff',
                                            '&.Mui-expanded': {
                                                color: '#ff8303',
                                            },
                                        }}
                                    >
                                        <Typography sx={{fontFamily: 'Poppins, sans-serif', fontWeight: 500}}>{ques.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            color: '#ffffff'
                                        }}
                                    >
                                        {ques.answer.map((ans) => (<p>{ans}</p>))}
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                        <span id="rokk-artists-faq"></span>
                    </div>
                </div>
                
                <Divider sx={{
                    height: '0.1px',
                    background: '#ffffff20',
                    margin: '50px 0'
                }} />
                <div>
                    <div className="faq-section-head-text">ROKK ARTISTS</div>

                    <div className="rokk-app-faq-questions-container">
                        {rokkArtistsFAQs.map((ques, _idx) => {
                            return (
                                <Accordion
                                    key={_idx}
                                    sx={{
                                        backgroundColor: '#1a1a1a',
                                        background: 'linear-gradient(215.36deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%)',
                                        borderRadius: '0',
                                        '&.Mui-expanded': {
                                            background: 'rgba(255, 255, 255, 0.15)'
                                        }
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ color: '#ffffff' }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{
                                            minHeight: '70px',
                                            color: '#ffffff',
                                            '&.Mui-expanded': {
                                                color: '#ff8303',
                                            },
                                        }}
                                    >
                                        <Typography sx={{fontFamily: 'Poppins, sans-serif', fontWeight: 500}}>{ques.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            color: '#ffffff'
                                        }}
                                    >
                                        {ques.answer.map((ans) => (<p>{ans}</p>))}
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </div>

                </div>
            </FAQSection>
            </GeneralLayout>

            <GeneralLayout style={{margin: '100px 0'}}>
                <ContactsLeftContainer>
                    <Typography sx={{
                        fontSize: '48px',
                        fontFamily: 'var(--head-text), sans-serif',
                        color: 'var(--primary)',
                        lineHeight: '52px'
                    }}>
                        CONTACT <br />US
                    </Typography>
                    <Typography sx={{fontFamily: 'Poppins, sans-serif', fontSize: '16px', color: '#ffffff80', textTransform: 'uppercase'}}>
                    Is there anything else you need help with that we didn't answer in the F.A.Q.?
                    </Typography>
                </ContactsLeftContainer>
                <ContactSection>
                    <div className="faq-section-head-text"><span style={{color: '#fff'}}>DON'T HESITATE AND CONTACT</span> SUPPORT@ROKK.APP <span style={{color: '#fff'}}>WITH YOUR QUESTION.</span></div>
                    {/* Contact Form: */}
                    <ContactForm />

                </ContactSection>
            </GeneralLayout>


            <div style={{ padding: 'var(--global-padding)' }}>
                <div className="newsletter-container-pricing-page flex aln-items-cnt">
                    <div className="newsletter-section-header flex flex-dir-col">
                        <span className="head-txt-off-white font-sz-small">SUBSCRIBE TO</span>
                        <span className="head-txt-off-white font-sz-small">OUR&nbsp;
                            <span className="head-txt-primary">ROKK</span>
                        </span>
                        <span className="head-txt-primary font-sz-small">NEWSLETTER</span>
                    </div>
                    <NewsletterForm />
                </div>
            </div>
            </FAQPageContainer>
        </>
    )
}

export default FaqPage